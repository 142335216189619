export const customStyles = {

    valueContainer: (base) => ({
        ...base,
        top: -5,
        color:"black"
    }),
    indicatorSeparator: (base) => ({
        ...base,
        height: 12,
        margin: 4,
    }),
    control: (base) => ({
        ...base,
        height: 20,
        minHeight: 20,
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
        cursor: 'pointer',
    }),
    option: (styles, state) => ({
        ...styles,
        cursor: 'pointer',
        backgroundColor: "white",
        margin: 0,
        padding: "0px 6px",
        //   backgroundColor: state.isSelected ? "#0fac81" : "white",
        color: state.isSelected ? "#0fac81" : "black",
        "&:hover": {
            backgroundColor: "white",
            color: "#0fac81"
        }

    }),

};