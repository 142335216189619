import React, { useRef, useState } from "react";
import './image-upload.scss'
import pdf_img from "../../../../assets/images/PDF.png"

const ImageUpload = ({ purpose, title = "Add", multiple = true, limit = 5, storeImage, removeImage, accept = ".png, .jpg, .jpeg, .svg,.pdf" }) => {
    let inputFileRef = useRef(null)
    const [images, setImages] = useState([]);
    const onclickInputFile = () => {
        inputFileRef?.current?.click();
    }

    const onChangeInputFile = (e) => {
        if (!multiple && inputFileRef?.current?.files[0]?.name) {
            setImages([{ name: inputFileRef?.current?.files[0]?.name, url: URL.createObjectURL(inputFileRef?.current?.files[0]) }])
            storeImage(inputFileRef?.current?.files)
        }
        else if ((+inputFileRef?.current?.files?.length + +images?.length) <= limit) {
            let newImages = [...images];
            for (let i = 0; i < inputFileRef?.current?.files?.length; i++) {
                newImages.push({ name: inputFileRef?.current?.files[i]?.name, url: URL.createObjectURL(inputFileRef?.current?.files[i]) })
            }
            setImages([...newImages])
            storeImage(inputFileRef?.current?.files)
        }
        else if (multiple) {
            alert("You can not add more than 5 files/images")
        }
    }

    const onRemoveImage = (index) => {
        if (!multiple) {
            setImages([])
        }
        else {
            let newImages = [...images];
            newImages?.splice(index, 1)
            setImages([...newImages])
        }
        removeImage(index)
    }

    const returnTypeFun = (name) => {
        const type = name?.split(".")
        return type[type?.length - 1]
    }

    return (
        <div className="d-flex flex-wrap">
            <div className="upload-design " onClick={onclickInputFile}>
                <i className="fas fa-camera fa-2x text-secondary" />
                <p className="font-weight-bold"> {title}</p>
            </div>
            {!!images && images?.length > 0 && images?.map((imageList, imageListIndex) => {
                returnTypeFun(imageList?.name)
                return (
                    <div className="preview-image mb-2" key={'showExpenseImage' + imageListIndex}>
                        <div className="remove-image" onClick={() => onRemoveImage(imageListIndex)}>
                            X
                        </div>
                        {returnTypeFun(imageList?.name) === "pdf" ? <img className="preview-image-item" src={pdf_img} alt="" /> : <img className="preview-image-item" src={imageList?.url} alt="" />}
                    </div>
                )
            })}
            <input multiple={multiple} type="file" ref={inputFileRef} onChange={(e) => { onChangeInputFile(e.target.files) }} className="d-none" accept={accept} />
        </div>)
}

export default ImageUpload
