import {
  faExpandArrowsAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthInfo, setSelectedRole } from "../../store/slices/authSlice";
import './nav-bar.scss'
import ProfileName from "../common/Profile/profile-name";
import SearchSelect from "../common/updated-input/createble-search-select/search-select";

const AppNavBar = () => {
  const { user, userRoles, selectedRole } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(removeAuthInfo())
    localStorage.clear();
  };


  const handleSelect = (value) => {
    dispatch(setSelectedRole(JSON.parse(value)))
  }


  // useMemo(() => {
  //   if (userRoles?.length > 0) {
  //     dispatch(setSelectedRole(userRoles[0]))
  //   }
  // }, [userRoles])

  // console.log("selected location",user?.user?.name)

  return (
    <nav
      className="main-header navbar navbar-expand custom-nav-bar navbar-size main-content my-0 py-0"
      style={{ marginLeft: "70px" }}
    >

      {/* <h3>{(!!user && !!user?.hcp) ? `${user.hcp.name} Web Portal` : 'Web Portal'}</h3> */}
      <p className="font py-0 my-0" style={{fontSize:"14px",fontWeight:"700",color:"#12AB81"}}>{user?.user?.name}</p>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        {/* <li className="nav-item">
          <ProfileName></ProfileName>
        </li> */}
        <li className="nav-item my-1 py-0 mb-0" style={{ width: "600px" }}>
          <select
            style={{ width: "100%" }}
            onChange={(e) => {
              handleSelect(e.target.value)
            }}
            value={JSON.stringify(selectedRole)}
          >
            {
              userRoles?.length > 0 && userRoles?.map((data, index) => {
                return <option onClick={() => { handleSelect(data) }} key={index} value={JSON.stringify(data)}>{data?.location?.name},{selectedRole?.location?.organization?.name}</option>
              })
            }
          </select>{" "}
        </li>
        {/* <li className="nav-item dropdown">
          <button
            onClick={handleLogout}
            type="button"
            className="btn btn-link"
            style={{ color: "black" }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            Logout
          </button>
        </li> */}

        {/* <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default AppNavBar;
