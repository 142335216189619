import React, { useEffect, useMemo, useRef, useState } from 'react'
import './social-history.scss'
import { useDispatch, useSelector } from 'react-redux';
import { patientAddSubDocument, patientRemoveSubDocument, patientSocialHistoryAdd, patientUpdateSubDocument } from '../../../service/api/patient-api';
import { getSocialHabitList, resetSocialHistory } from '../../../store/slices/visitSlice';
import { LocalStorageService } from '../../../utils/local-storage';
import { post } from '../../../utils/fetchAPI';
import UniversalLoaderForComponent from "../../loader/universalLoaderForComponent"


const SocialHistory = ({ patientId, visitDetails, patientDetails, updateState }) => {
    let { socialHabitList } = useSelector(
        (state) => state.visit
    );
    const dispatch = useDispatch()
    const timeoutIdRef = useRef(null)
    const [patientSocialHistory, setPatientSocialHistory] = useState([])
    const [info, setInfo] = useState({})
    const [searchQuery, setSearchQuery] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (patientDetails?.socialHistories) {
            setPatientSocialHistory(patientDetails?.socialHistories)
        }
    }, [patientDetails?.socialHistories])


    const setInfoFun = (key, value, type, name = "") => {
        let temp = { ...info }
        if ((type === "check" && info.hasOwnProperty(key))) {
            onRemove(key)
            delete temp[key];
            setInfo(temp)
        }
        else {
            setInfo((prev) => ({ ...prev, [key]: value }));
            if ((info.hasOwnProperty(key) == false)) {
                onChangeSubmit(key, value, "add", name)
            }
            else {
                onUpdate(key, value)
            }
        }
    };

    const onChangeSubmit = async (key, value, action, name) => {
        if (action == "add") {
            await patientAddSubDocument("socialHistories", { socialHabit: key, name: name, note: value }, (visitDetails?.patient || patientId))
                .then((res) => {
                    if (res?.status === 200) {
                        updateState(res?.data)
                        // setPatientSocialHistory(res?.data?.patientDetails?.socialHistories)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const onRemove = async (key) => {
        await patientRemoveSubDocument("socialHistories", { socialHabit: key }, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res.status === 200) {
                    updateState(res?.data)
                    // setPatientSocialHistory(res?.data?.patientDetails?.socialHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }



    const onUpdate = async (key, value) => {
        const body = {
            payload: {
                "socialHistories.$.note": value
            },
            filterKey: "socialHistories.socialHabit",
            filterKeyValue: key
        }
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await patientUpdateSubDocument(body, (visitDetails?.patient || patientId))
                .then(res => {
                    if (res.status === 200) {
                        updateState(res?.data)
                        // setPatientSocialHistory(res?.data?.patientDetails?.socialHistories)
                    }
                }).catch((error) => {
                    console.log(error);
                });

        }, 500);

    }


    const addNew = async () => {
        setIsLoading(true)
        await post('/social-habit/create', { doctor: visitDetails?.doctor, name: searchQuery })
            .then((res) => {
                if (res.status == 200) {
                    dispatch(getSocialHabitList())
                    setSearchQuery(searchQuery)
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }


    let listOfSocialHistroy = useMemo(() => {

        let filterList = socialHabitList || []
        if (searchQuery && socialHabitList) {
            filterList = socialHabitList.filter(disease => disease?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
            return filterList
        }
        if (patientSocialHistory) {
            patientSocialHistory.map((data) => {
                setInfo((prev) => ({ ...prev, [data?.socialHabit]: data?.note }));
            })
        }
        return socialHabitList
    }, [socialHabitList, searchQuery, patientSocialHistory])


    return (
        <div className='social-history-section'>
            <div className='social-history-section-header d-flex justify-content-between border-bottom'>
                <div className='header'>
                    <p className='fw-bolder'>Social History</p>
                </div>
                <div className='social-history-search'>
                    <input
                        className=""
                        placeholder="Search"
                        type="text"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    ></input>
                </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                {listOfSocialHistroy?.map((habit, index) => {
                    return <div key={habit?._id} className='individual-social-habit py-1'>
                        <div className='d-flex' onChange={() => {
                            //  setInfoFun(habit?._id, "", "check", habit?.name) 
                        }}>
                            {/* checked={patientSurgeryList?.surgeries?.includes(surgery?._id) ? true : ""} */}
                            <input onChange={(e) => { setInfoFun(habit?._id, habit?.note, "check", habit?.name) }} checked={info.hasOwnProperty(habit?._id)} type="checkbox" id={"habit-" + habit?._id} name={"habit-" + habit?._id} />
                            <label className='ml-2 align-content-center justify-content-center my-auto' for={"habit-" + habit?._id}> {habit?.name}</label><br />
                        </div>
                        <div>
                            <textarea
                                className="form-control  w-100"
                                autoComplete="off"
                                type="text"
                                rows={2}
                                id={`${habit?._id}`}
                                placeholder={habit?.name?.slice(0, 20) + "..."}
                                value={info[habit?._id] || ""}
                                onChange={(e) => {
                                    setInfoFun(habit?._id, e.target.value, "input", habit?.name)
                                }}
                            />
                        </div>
                    </div>
                })}
                <div>
                    {listOfSocialHistroy?.length == 0 && !isLoading && <div className='d-flex mx-1'>
                        <p onClick={() => { addNew() }} className='px-2 mt-1' style={{ backgroundColor: "#14A67D", color: "white", cursor: "pointer", fontSize: "13px" }}>({searchQuery}) Add</p></div>}
                </div>
                {isLoading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
            </div>
        </div>
    )
}

export default SocialHistory
