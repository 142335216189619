import React from 'react'
import { useSelector } from 'react-redux';
import "./patient.scss"

const PatientPhysicalExam = () => {
    let { visits, isVisitLoading } = useSelector(
        (state) => state.visit
    );
    return (
        <div className='row no-gutters'>
            {
                visits?.length > 0 && visits?.map((visit, index) => {
                    let info = visit?.physicalExams
                    if (info.length > 0)
                        return (<div key={index} className='patient-physical-exam-section  mb-2 col-md-12 p-1'>
                            <p style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }} className='title p-0 m-0 px-1'>Appointment date: {visit?.appointmentDate?.slice(0, 10)}</p>
                            <div className='border row no-gutters'>
                                {info?.map((exam, index) => {
                                    return (
                                        <div key={index} className='col-md-6 border'>
                                            {<div className='p-1'>
                                                <p className='py-0 my-1' style={{ fontSize: "13px" }}>Check: <span style={{ fontWeight: "600" }}>{exam?.displayName}</span></p>
                                                <p style={{ fontSize: "13px" }} className='py-0 my-1'>Result: <span style={{ fontWeight: "600" }}>{exam?.type}</span></p>
                                                <p style={{ fontSize: "13px" }} className='py-0 my-1'>Symptoms: <span style={{ fontWeight: "600" }}>{exam?.desc}</span></p>
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>)
                })
            }

        </div>
    )
}

export default PatientPhysicalExam
