import React, { useMemo, useState } from "react";
import "./patient-search.scss";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {
  getPatients,
  resetAllPatients,
} from "../../../store/slices/patientSlice";
import { dateFormat } from "../../../utils/common-funtions/common";

const PatientSearch = ({
  setSelectedTabOption,
  setIsNewUser,
  setSinglePatientInfo,
  singlePatientInfo,
  selectedTabOption,
  height = 100,
  buttonName,
  isNewUser,
}) => {
  const dispatch = useDispatch();
  let { isLoading, allPatients, noPatientFound } = useSelector(
    (state) => state.patient
  );
  const { user } = useSelector((state) => state.auth);
  const [key, setKey] = useState("name")
  const [inputValue, setInputValue] = useState()
  const showPatientFun = (e) => {
    e.preventDefault();
    if (e.target.value?.length >= 2)
      dispatch(getPatients({ search: e.target.value, key: key, doctorId: user?.user?._id }));
    else if (e.target.value?.length <= 1) {
      dispatch(resetAllPatients());
      setSinglePatientInfo({});
      setIsNewUser(true);
    }
    setInputValue(e.target.value)
  };

  const setKeyFun = (value) => {
    setInputValue("")
    setKey(value)
  }

  useMemo(() => {
    if (isNewUser) {
      if (document.getElementById("patient-search"))
        document.getElementById("patient-search").value = "";
      dispatch(resetAllPatients());
    }
  }, [isNewUser]);

  return (
    <div className="patient-search-section-appointment" style={{ height: `${height}vh` }}>
      <p className="d-block px-2  border-bottom" style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Search in existing patients</p>
      <div className="d-flex p-1 pl-1 ">
        <small className="px-1" style={{ width: "30%" }}>Search by: </small>
        <select
          style={{ width: "70%" }}
          onChange={(e) => {
            setKeyFun(e.target.value);
          }}
        >
          <option value={'name'}>Name </option>
          <option value={'phone'}> Phone</option>
          <option value={'dob'}>Dob </option>
          <option value={'patientId'}>Patient ID </option>
        </select>{" "}
      </div>
      <div className="">
        <input
          onChange={(e) => showPatientFun(e)}
          placeholder={`Search by ${key}`}
          type={`${key == "dob" ? 'date' : "text"}`}
          id="patient-search"
          autoComplete="off"
          value={inputValue || ""}
          className="d-block search-input mr-0"
        />
      </div>
      <div className="search-result-section mx-auto">
        <table id="example2"
          className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}">
          <thead>
            {/* table heading */}
            <tr>
              <th className="pt-1">Name</th>
              <th>Phone</th>
              <th>DOB</th>
              <th>Gender</th>
            </tr>
          </thead>

          <tbody>
            {allPatients?.length >= 0 && !isLoading &&
              allPatients?.map((patient, index) => {
                return (
                  <tr
                    key={index}
                    className={`${patient?._id === singlePatientInfo?._id
                      ? "active-patient"
                      : ""
                      }`}
                    onClick={() => {
                      setIsNewUser(false);
                      setSinglePatientInfo(patient);
                    }}>
                    <td className="">{patient?.name}</td>
                    <td className="">{patient?.phone}</td>
                    <td className="">{dateFormat(patient?.dob?.slice(0, 10))}</td>
                    <td className="">{patient?.gender}</td>
                  </tr>
                );
              })}

            {allPatients?.length === 0 && noPatientFound && !isLoading && (
              <p className="no-patient-found">No patient found</p>
            )}
          </tbody>
          {isLoading && <p className="no-patient-found">Searching...</p>}
        </table>


      </div>
    </div>
  );
};

export default PatientSearch;
