import React, { useEffect, useState } from "react";
import "./individual-patient.scss";
import VerticalTab from "../../../components/common/tabs/vertical/vertical-tab";
import VitalSigns from "../../../components/prescription/vital-signs/vital-signs";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import Header from "../../../components/waiting-room/header/header";
import Complaint from "../../../components/prescription/complaint/complaint";
import { optionsForPatientDetails, optionsForWaiting } from "../../../utils/static-data/data";
import SurgicalHistories from "../../../components/prescription/sergical-histories/surgical-histories";
import SocialHistory from "../../../components/prescription/social-history/social-history";
import FamilyHistory from "../../../components/prescription/family-history/family-history";
import OtherHealthProblem from "../../../components/prescription/other-health-problem/other-health-problem";
import MainPrescription from "../../../components/prescription/main-prescription/main-prescription";
import PatientHistory from "../../../components/prescription/patient-history/patient-history";
import VisitHistory from "../../../components/prescription/visit-history/visit-history";
import LabReportFile from "../../../components/prescription/lab-reports-file/lab-report-file";
import AllergyHistory from "../../../components/prescription/allergy-history/allergy-history";
import PastMedicalHistory from "../../../components/prescription/past-medical-history/past-medical-history";
import PatientDemography from "../../../components/prescription/patient-demogrphy/patient-demography";
import { get } from "../../../utils/fetchAPI";
import Ot from "../../../components/prescription/ot/ot";
import { useDispatch, useSelector } from "react-redux";
import { getAllVisit } from "../../../store/slices/visitSlice";
import PatientVision from "../information/patient-vision";
import PatientComplaint from "../information/patient-complaint";
import PatientVitalSign from "../information/patient-vital-signs";
import PatientPhysicalExam from "../information/patient-physical-exam";


const IndividualPatientDetails = ({ patient }) => {
  const [selectedOption, setSelectedOption] = useState({
    id: 1,
    name: "Vital signs",
  });
  const params = useParams();
  const [patientId] = useState(params?.patientId);
  const [visitId] = useState(params?.visitId);
  const [patientDetails, setPatientDetails] = useState({});
  const [visitDetails, setVisitDetails] = useState({});
  const [selectedSubOption, setSelectedSubOption] = useState({
    parentId: "",
    subId: "",
  });
  const dispatch = useDispatch()

  useEffect(() => {
    if (patientId) {
      getPatientDetails(patientId)
    }
  }, [])
  function getPatientDetails(patientId) {
    get(`/patient/${patientId}`)
      .then((res) => {
        if (res?.status === 200) {
          setPatientDetails(res.data)
          dispatch(getAllVisit(res.data?._id))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  return (
    <div>
      <Header isContentHide={true} fromPatient={true} backBtnHide={true} patientId={patientId} backUrl={"/waiting-room"}></Header>
      <section
        className="individual-patient-section mt-0 pt-0 pt-2"
        style={{ marginLeft: "71px" }}
      >
        <div className="tab-option-section">
          <VerticalTab
            backUrl={"/waiting-room"}
            setSelectedSubOption={setSelectedSubOption}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            selectedSubOption={selectedSubOption}
            options={optionsForPatientDetails}
            tabTitle={"Option"}
          ></VerticalTab>
        </div>
        <div className="tab-main-section">
          {selectedOption?.id === 1 && <PatientDemography patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></PatientDemography>}
          {selectedOption?.id === 2 && <PatientComplaint></PatientComplaint>}
          {selectedOption?.id === 3 && <PatientVitalSign></PatientVitalSign>}
          {selectedOption?.id === 12 && <PatientPhysicalExam></PatientPhysicalExam>}
          {selectedOption?.id === 11 && <PatientVision ></PatientVision>}
          {selectedOption?.id === 4 && <LabReportFile patientDetails={patientDetails}></LabReportFile>}
          {selectedOption?.id === 5 && <VisitHistory patientId={patientId}></VisitHistory>}
          {selectedOption?.id === 6 && <PastMedicalHistory patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></PastMedicalHistory>}
          {selectedOption?.id === 7 && <FamilyHistory patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></FamilyHistory>}
          {selectedOption?.id === 8 && <SurgicalHistories patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></SurgicalHistories>}
          {selectedOption?.id === 9 && <AllergyHistory patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></AllergyHistory>}
          {selectedOption?.id === 10 && <SocialHistory patientId={patientId} patientDetails={patientDetails} updateState={setPatientDetails}></SocialHistory>}
          {selectedOption?.id === 14 && <Ot patientDetails={patientDetails} updateState={setPatientDetails} visitDetails={{}}></Ot>}
          {/* {selectedOption?.id === 3 && <OtherHealthProblem visitId={visitId} patientId={patientId}></OtherHealthProblem>} */}
        </div>
        <div className="individual-info-section">
          <PatientHistory patientId={patientId}></PatientHistory>
        </div>
      </section>
    </div>
  );
};

export default IndividualPatientDetails;
