import React, { useState } from 'react'
import NameSection from '../../components/template/name-section'
import "./template.scss"
import { useSelector } from 'react-redux';
import TemplatePrescription from '../../components/template/main-prescription/template-prescription';
const Template = () => {
    let { user } = useSelector((state) => state.auth);
    const [selectedTemplate, setSelectedTemplate] = useState({})
    return (
        <section className='template-section d-flex pt-3' style={{ marginLeft: "71px" }}>
            <div className='name-section'>
                <NameSection selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} doctorId={user?.user?._id}></NameSection>
            </div>
            <div className='prescription-section'>
                {selectedTemplate?._id && <TemplatePrescription selectedTemplate={selectedTemplate} doctorId={user?.user?._id}></TemplatePrescription>}
                {
                    !selectedTemplate?._id && <p>Please select a template from the list or create one</p>
                }
            </div>
        </section>
    )
}

export default Template

