import React, {useEffect, useMemo, useRef, useState} from 'react'
import './allergy-history.scss'
import {useDispatch, useSelector} from 'react-redux';
import {getPatientAllergyList} from '../../../store/slices/visitSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {patientAddSubDocument, patientRemoveSubDocument, patientUpdateSubDocument} from '../../../service/api/patient-api';

const AllergyHistory = ({patientId, visitDetails, patientDetails, updateState}) => {
    let {allergyList} = useSelector(
        (state) => state.visit
    );
    const [patientAllergyHistory, setPatientAllergyHistory] = useState([])
    let [allergyListWithType, setAllergyListWithType] = useState([])
    let [info, setInfo] = useState({})
    const timeoutIdRef = useRef(null)
    useEffect(() => {
        if (patientDetails?.allergyHistories) {
            setPatientAllergyHistory(patientDetails?.allergyHistories)
        }
    }, [])
    useMemo(() => {
        if (allergyList) {
            const groupedByType = {};
            allergyList.forEach(obj => {
                const type = obj.type;
                if (!groupedByType[type]) {
                    groupedByType[type] = [];
                }
                groupedByType[type].push(obj);
            });
            const resultArray = Object.entries(groupedByType).map(([type, objects]) => ({
                type,
                allergy: objects,
            }));
            setAllergyListWithType(resultArray)
        }

        if (patientAllergyHistory && allergyList) {
            let objects = {}
            patientAllergyHistory?.map((allergy) => {
                let Check = document?.getElementById(`allergy-${allergy?.allergy}`);
                if (Check) Check.checked = true
                objects = {
                    ...objects, [allergy?.allergy]: {
                        allergy: allergy?.allergy,
                        reaction: allergy?.reaction,
                        severity: allergy?.severity,
                        source: allergy?.source
                    }
                }
            })
            setInfo(objects)
        }

    }, [allergyList, patientAllergyHistory,])


    const handleAllergyFun = (parentKey, key, value, event, item, type) => {
        let Check = document?.getElementById(`allergy-${parentKey}`);
        let reaction = document?.getElementById(`reaction-${parentKey}`);
        let severity = document?.getElementById(`severity-${parentKey}`);
        let source = document?.getElementById(`source-${parentKey}`);
        let payload = {
            item: item,
            type: type,
            allergy: parentKey,
            reaction: reaction?.value || "",
            severity: severity?.value || "",
            source: source?.value || ""
        }
        let val = info[parentKey]
        setInfo({...info, [parentKey]: {...val, [key]: value}})
        if (key == "allergy") {
            if (event == true) {
                Check.checked = true
                onChangeAdd(key, payload)
            } else {
                delete info[parentKey]
                Check.checked = false
                reaction.value = ""
                severity.value = ""
                source.value = ""
                setInfo(info)
                onRemove(parentKey)
            }
        } else if (Check) {
            Check.checked = true
            let previousInfo = info
            if (previousInfo.hasOwnProperty(parentKey)) {
                onUpdate(parentKey, payload)
            } else {
                onChangeAdd(key, payload)
            }

        }
    }

    const onChangeAdd = async (key, payload) => {
        await patientAddSubDocument("allergyHistories", payload, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res?.status === 200) {
                    updateState(res?.data)
                    //setPatientAllergyHistory(res?.data?.patientDetails?.allergyHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const onRemove = async (key) => {
        await patientRemoveSubDocument("allergyHistories", {allergy: key}, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res.status === 200) {
                    updateState(res?.data)
                    //setPatientAllergyHistory(res?.data?.patientDetails?.allergyHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onUpdate = async (key, value) => {
        const body = {
            payload: {
                "allergyHistories.$.reaction": value?.reaction,
                "allergyHistories.$.severity": value?.severity,
                "allergyHistories.$.source": value?.source
            },
            filterKey: "allergyHistories.allergy",
            filterKeyValue: key
        }
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await patientUpdateSubDocument(body, (visitDetails?.patient || patientId))
                .then(res => {
                    if (res.status === 200) {
                        updateState(res?.data)
                        //setPatientAllergyHistory(res?.data?.patientDetails?.allergyHistories)
                    }
                }).catch((error) => {
                    console.log(error);
                });

        }, 500);

    }

    const contentHideFun = (id) => {
        const icon = document?.getElementById(`${id}-icon`)
        const table = document?.getElementById(`${id}-table`)
        if (icon.classList?.contains("rotate-180")) {
            icon.classList.remove("rotate-180");
        } else {
            icon.classList?.add("rotate-180");
        }
        if (table.classList?.contains("d-none")) {
            table.classList.remove("d-none");
        } else {
            table.classList?.add("d-none");
        }
    }
    return (
        <div className='allergy-history-section'>
            <div className='header'>
                {/* <p onClick={() => { handleSubmit() }} className={`button ${isSubmitting ? "disable" : ""}`}>Save
                    {isSubmitting && (
                        <span
                            className="spinner-border spinner-border-sm ml-1"
                            role="status"
                            aria-hidden="true"
                        ></span>)}
                </p> */}
                {/* <div className='d-flex ml-2 mr-1'>
                    <label className="switch">
                        <input id="family-history-switch" type="checkbox" onChange={() => { setOnlySelectedShow(!onlySelectedShow) }} />
                        <span className="slider round"></span>
                    </label>
                    <label className='mt-0  pt-0 ml-1' for="surgery-switch">Show selected family history</label>
                </div> */}
            </div>
            <div className='all-allergy'>
                {allergyListWithType?.map((allergy, index) => {
                    return (
                        <div key={index} className='type-data'>
                            <div className='d-flex justify-content-between title' onClick={() => {
                                contentHideFun(allergy?.type)
                            }}>
                                <p className=''>{allergy?.type.toUpperCase()}</p>
                                <FontAwesomeIcon id={`${allergy?.type}-icon`} size="sm" className="mt-1 mr-2" icon={faChevronUp}/>
                            </div>
                            <table className="table table-sm table-hover mt-0 pt-0">
                                <thead className='mt-0 pt-0'>
                                <tr className='mt-0 pt-0'>
                                    <th scope="col">Name</th>
                                    <th scope="col">Reaction</th>
                                    <th scope="col">Severity</th>
                                    <th scope="col">Source</th>
                                    {/* <th scope="col">Note</th> */}
                                </tr>
                                </thead>
                                <tbody id={`${allergy?.type}-table`}>
                                {
                                    allergy?.allergy?.map((data, index) => {
                                        return (
                                            <>
                                                {
                                                    <tr key={index} className={``} id={data?._id}>
                                                        <td className='align-middle' style={{cursor: "pointer"}}>
                                                            <div className='d-flex '>
                                                                <input onChange={(e) => handleAllergyFun(data?._id, "allergy", data?._id, e.target.checked, data?.item ,allergy?.type)} type="checkbox"
                                                                       id={"allergy-" + data?._id} name={"allergy-" + data?._id}/>
                                                                <label htmlFor={"allergy-" + data?._id} style={{cursor: "pointer"}}
                                                                       className='ml-2 align-content-center justify-content-center my-auto'> {data?.item}</label><br/>
                                                            </div>
                                                        </td>
                                                        <td className='align-middle'>  <textarea
                                                            className="form-control  w-100"
                                                            autoComplete="off"
                                                            type="text"
                                                            rows={1}
                                                            id={"reaction-" + data?._id}
                                                            placeholder="History of allergies"
                                                            value={info[data?._id]?.reaction || ""}
                                                            onChange={(e) => handleAllergyFun(data?._id, "reaction", e.target.value,"", data?.item ,allergy?.type)}
                                                        /></td>
                                                        <td className='align-middle'><select
                                                            id={"severity-" + data?._id}
                                                            value={info[data?._id]?.severity || ""}
                                                            onChange={(e) => handleAllergyFun(data?._id, "severity", e.target.value, "", data?.item ,allergy?.type)}
                                                        >
                                                            <option value={""}>Select</option>
                                                            <option value={"mild"}>Mild</option>
                                                            <option value={"moderate"}>Moderate</option>
                                                            <option value={"severe"}>Severe</option>
                                                        </select></td>
                                                        <td className='align-middle'><select
                                                            id={"source-" + data?._id}
                                                            value={info[data?._id]?.source || ""}
                                                            onChange={(e) => handleAllergyFun(data?._id, "source", e.target.value,"", data?.item ,allergy?.type)}
                                                        >
                                                            <option value={""}>Select</option>
                                                            <option value={"referral doctors"}>Referral doctors</option>
                                                            <option value={"patient self-reported"}>Patient self-reported</option>
                                                            <option value={"from Transition of Care"}>From Transition of Care</option>

                                                        </select></td>
                                                    </tr>
                                                }
                                            </>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default AllergyHistory
