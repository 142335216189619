import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getWithParams, post } from "../../utils/fetchAPI";

const initialState = {
    medicineList: [],
    isNoMedicine: false,
    isMedicineListLoading: false,
    doctorMedicineList: [],
    isDoctorMedicineListLoading: false,
    isLoading: false,
    isOperationLoading: false,
    error: null,
    labTestList: [],
    isLabTestListLoading: false,
    adviceList: [],
    isAdviceListLoading: false,
    allFiles: [],
};

export const getAllMedicineList = createAsyncThunk(
    "prescription/getAllMedicineList",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getWithParams(`/medicine-list?key=${payload?.key}&search=${payload?.search}&medicinePriority=${payload?.medicinePriority}&manufacturerName=${payload?.manufacturerName}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getDoctorMedicineList = createAsyncThunk(
    "prescription/getDoctorMedicineList",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await get(`/doctor/frequent-medicine-list/${payload?.doctorId}?search=${payload?.search}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllLabTests = createAsyncThunk(
    "prescription/getAllLabTests",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getWithParams(`/lab-test-list/${payload?.doctorId}?search=${payload?.search}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllAdvices = createAsyncThunk(
    "prescription/getAllAdvices",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getWithParams(`/doctor/advice-list/${payload?.doctorId}?search=${payload?.search}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllFiles = createAsyncThunk(
    "prescription/getAllFiles",
    async (patientId, { rejectWithValue }) => {
        try {
            const response = await getWithParams(`patient/file-history/${patientId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const prescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {

    },
    extraReducers: {
        [getAllMedicineList.pending]: (state) => {
            state.isMed = true;
            state.error = null;
            state.isMedicineListLoading = true;
            state.medicineList = [];
            state.isNoMedicine = false
        },
        [getAllMedicineList.fulfilled]: (state, action) => {

            state.medicineList = action.payload;
            if (action.payload.length == 0)
                state.isNoMedicine = true
            state.isMedicineListLoading = false;
            state.error = null;
        },
        [getAllMedicineList.rejected]: (state, action) => {
            state.isMedicineListLoading = false;
            state.error = action.payload?.message;
        },


        [getDoctorMedicineList.pending]: (state) => {
            state.isMed = true;
            state.error = null;
            state.isDoctorMedicineListLoading = true;
        },
        [getDoctorMedicineList.fulfilled]: (state, action) => {

            state.doctorMedicineList = action.payload;
            state.isDoctorMedicineListLoading = false;
            state.error = null;
        },
        [getDoctorMedicineList.rejected]: (state, action) => {
            state.isDoctorMedicineListLoading = false;
            state.error = action.payload?.message;
        },
        [getAllLabTests.pending]: (state) => {
            state.isMed = true;
            state.error = null;
            state.isLabTestListLoading = true;
        },
        [getAllLabTests.fulfilled]: (state, action) => {

            state.labTestList = action.payload;
            state.isLabTestListLoading = false;
            state.error = null;
        },
        [getAllLabTests.rejected]: (state, action) => {
            state.isLabTestListLoading = false;
            state.error = action.payload?.message;
        },

        [getAllAdvices.pending]: (state) => {
            state.isMed = true;
            state.error = null;
            state.isAdviceListLoading = true;
        },
        [getAllAdvices.fulfilled]: (state, action) => {

            state.adviceList = action.payload;
            state.isAdviceListLoading = false;
            state.error = null;
        },
        [getAllAdvices.rejected]: (state, action) => {
            state.isLabTestListLoading = false;
            state.error = action.payload?.message;
        },


        [getAllFiles.pending]: (state) => {
            state.isMed = true;
            state.error = null;
            state.isLoading = true;
        },
        [getAllFiles.fulfilled]: (state, action) => {

            state.allFiles = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        [getAllFiles.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload?.message;
        },



    },
});

export default prescriptionSlice.reducer;
