import React, { useEffect, useState } from 'react'
import './physical-exam.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { post, put } from '../../../utils/fetchAPI';

const PhysicalExamAdd = ({ setPhysicalExamAdd, setDoctorPhysicalExamList, visitDetails, doctorPhysicalExamList, updatePhysicalExam }) => {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitValue, setSubmitValue] = useState({})
  useEffect(() => {
    if (updatePhysicalExam?.name) {
      setSubmitValue({ name: updatePhysicalExam?.name, displayName: updatePhysicalExam?.displayName, defaultNormalDesc: updatePhysicalExam?.defaultNormalDesc })
    }
  }, [])

  const handleTemplateCreate = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    if (updatePhysicalExam?.name) {
      await put(`physical-exam/update/${updatePhysicalExam?._id}`, submitValue)
        .then((res) => {
          if (res?.status === 200) { 
            let checkIndex = doctorPhysicalExamList?.findIndex(obj => obj?._id === updatePhysicalExam?._id);
            if (checkIndex) {
              let updatedList = [...doctorPhysicalExamList.slice(0, checkIndex), res?.data, ...doctorPhysicalExamList.slice(checkIndex + 1)];
              setDoctorPhysicalExamList(updatedList)
            }
            setPhysicalExamAdd(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false)
        })
        .finally(() => {
          setIsSubmitting(false)
        }
        );
    } else {
      await post(`/physical-exam/create`, { ...submitValue, doctor: visitDetails?.doctor })
        .then((res) => {
          if (res?.status === 200) {
            setDoctorPhysicalExamList([...doctorPhysicalExamList, res?.data])
            setPhysicalExamAdd(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false)
        })
        .finally(() => {
          setIsSubmitting(false)
        }
        );
    }
  }
  return (
    <div className='new-physical-exam-section p-0 m-0'>
      <div className='p-0 m-0 form-title d-flex justify-content-between'>
        <p className='p-0 m-0 px-1'>{updatePhysicalExam?.name ? "Update physical exam" : "New physical exam create form"}</p>
        <p style={{ cursor: "pointer" }} onClick={(e) => { setPhysicalExamAdd(false) }} className='p-0 m-0 pl-5 pr-2'> <FontAwesomeIcon className="mx-1 my-0 text-danger" icon={faClose} style={{ cursor: "pointer", color: "#13AC81" }} /> </p>
      </div>
      <form
        onSubmit={(e) => handleTemplateCreate(e)}
        action=""
        id=""
        className="custom-form"
        encType="multipart/form-data"
      >
        <div className=" my-0 py-0 px-2 mb-1">
          <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '30%' }}>
            Name:
          </label>
          <input
            className="form-control"
            autoComplete="off"
            type="text"
            id="name"
            name="phone"
            placeholder="Enter template name"
            value={submitValue?.name || ""}
            onChange={(e) => { setSubmitValue({ ...submitValue, name: e.target.value }) }}
          />
        </div>
        <div className=" my-0 py-0 px-2 mb-1">
          <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '30%' }}>
            Display Name:
          </label>
          <input
            className="form-control"
            autoComplete="off"
            type="text"
            id="displayName"
            name="displayName"
            placeholder="Enter template name"
            value={submitValue?.displayName || ""}
            onChange={(e) => { setSubmitValue({ ...submitValue, displayName: e.target.value }) }}
          />
        </div>
        <div className=" my-0 py-0 px-2 mb-1">
          <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '100%' }}>
            Default Normal Description:
          </label>
          <textarea
            autoComplete="off"
            id={`defaultNormalDesc`}
            className={`w-100`}
            // data-multi-title={"check"}
            value={submitValue?.defaultNormalDesc || ""}
            placeholder={"Enter normal description"}
            onChange={(e) => { setSubmitValue({ ...submitValue, defaultNormalDesc: e.target.value }) }}
            rows={7}
          ></textarea>
        </div>
        <div className='text-center'>
          <button disabled={isSubmitting} className='btn btn-sm px-3 submit-btn py-0 mx-2' type="submit">{!isSubmitting ? (updatePhysicalExam?.name ? "Update" : "Create") : ""}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default PhysicalExamAdd
