import React from "react";
import "./profile.scss";
import { useSelector } from "react-redux";
import { getName } from "../../../utils/common-funtions/common";

const ProfileName = () => {
  const user = useSelector((state) => state.auth.user); 
  return (
    <div className="profile-name-component">
      <p
        className={`${
          getName(user?.user?.name)?.length < 2
            ? "single-letter-profile-name"
            : "double-letter-profile-name"
        }`}
      >
        {getName(user?.user?.name)}
      </p>
    </div>
  );
};

export default ProfileName;
