import React, { useEffect, useState } from "react";
import "./vital-signs.scss";
import VitalSignForm from "./vital-signs-form/vital-sign-form";
import CustomModal from "../../common/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { getPatientVitalSign } from "../../../store/slices/visitSlice";
import { useParams } from "react-router-dom";

const VitalSigns = ({ visitDetails = {}, setVisitDetails }) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    let { isLoading, vitalSigns, noVitalSign, latestVitalSign } = useSelector(
        (state) => state.visit
    );
    useEffect(() => {
        refreshVitalSign()
    }, []);

    const refreshVitalSign = () => {
        dispatch(getPatientVitalSign(visitDetails?.patient));
    };

    const handleKeyDown = (event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
            setShow(true)
        }
        if (event.keyCode == 27) {
            event.preventDefault();
            setShow(false)
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    return (
        <div className="vital-sign-details-section">
            {visitDetails && <div className="vital-sign-details-header d-flex justify-content-between">
                <p
                    className="custom-common-button"
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    Add vital sign
                </p>
                <p className="title ">Vital Signs</p>
            </div>}
            {/* modal */}
            <div className="mx-2 details-card">
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Ht(ft/inch)</th>
                            <th scope="col">Wt(kg)</th>
                            <th scope="col">Temp(F)</th>
                            <th scope="col">BMI</th>
                            <th scope="col">BP(mmHg)</th>
                            <th scope="col">Hr(/min)</th>
                            <th scope="col">SPO2(%)</th>
                            <th scope="col">RR(/min)</th>
                            {<th scope="col">Note</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {noVitalSign ? (
                            <p className="mx-2">No previous history available</p>
                        ) : (
                            vitalSigns?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.vitals?.date?.slice(0, 10)}</td>
                                        <td>{item.vitals?.height}</td>
                                        <td>{item.vitals?.weight}</td>
                                        <td>{item.vitals?.temperature}</td>
                                        <td>{item.vitals?.bmi}</td>
                                        <td>{item.vitals?.bp}</td>
                                        <td>{item.vitals?.hr}</td>
                                        <td>{item.vitals?.spo2}</td>
                                        <td>{item.vitals?.rr}</td>
                                        {<td>{item.vitals?.note}</td>}
                                    </tr>
                                );
                            })
                        )}
                        {isLoading && <tr>
                            <td className="mx-2">loading...</td>
                        </tr>}
                    </tbody>
                </table>
            </div>

            <CustomModal
                customClass={"custom-modal"}
                setShow={setShow}
                size="lg"
                show={show}
                isHeaderHide={true}
            >
                <VitalSignForm setVisitDetails={setVisitDetails} visitDetails={visitDetails} updateState={refreshVitalSign}
                    setShow={setShow} previousVitals={vitalSigns?.length > 0 ? vitalSigns[0] : {}}></VitalSignForm>
            </CustomModal>
        </div>
    );
};

export default VitalSigns;

// {!isLoading && !noVitalSign ? (
//   <div className="mx-2 details-card">
//     {vitalSigns?.vitalSign?.map((vital,index) => {
//       return <div key={index} className={`${index!==0?"border-bottom ":""} d-flex row`}>
//         <div className="details-card-header col-12 border d-flex">
//           <p className="py-2 my-0 text-secondary">{index===0?"This is the most recent information:":"Information entry date: "}</p>
//           <p className={`py-2 pl-1 my-0 ${index===0?"common-font-color":""}`}>{vital?.createdAt?.slice(0,10)}</p>
//         </div>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">weight:{" "}</span>{vital?.weight} kg</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">Height:{" "}</span> {vital?.height} ft</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">BMI:{" "}</span> {vital?.bmi} ct</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">Temperature:{" "}</span> {vital?.temperature} f</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">Blood pressure:{" "}</span> {vital?.bp} mmHg</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">Heart rate:{" "}</span> {vital?.hr} /min</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">SPO2:{" "}</span> {vital?.spo2} mmHg</p>
//         <p className="col-md-3 py-1 my-0 "><span className="text-secondary">Respiratory rate:{" "}</span> {vital?.rr} mmHg</p>
//         <p className="col-md-12 py-1 my-0 "><span className="text-secondary">Additional note:{" "}</span>{vital?.note}</p>
//       </div>;
//     })}
//   </div>
// ) : (
//   ""
// )}
