import { post } from "../../utils/fetchAPI";

export const addLabTest = async (payload) => {
    const res = await post(`/lab-test/create`, payload);
    return res;
};

export const addDoctorAdvice = async (payload) => {
    const res = await post(`/doctor/advice/create `, payload);
    return res;
};

// doctor/advice/create 