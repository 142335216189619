import React from 'react'
import { useSelector } from 'react-redux';
import "./patient.scss"

const PatientVitalSign = () => {
    let { visits, isVisitLoading } = useSelector(
        (state) => state.visit
    );
    return (
        <div>
            <table className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Ht(ft/inch)</th>
                        <th scope="col">Wt(kg)</th>
                        <th scope="col">Temp(F)</th>
                        <th scope="col">BMI</th>
                        <th scope="col">BP(mmHg)</th>
                        <th scope="col">Hr(/min)</th>
                        <th scope="col">SPO2(%)</th>
                        <th scope="col">RR(/min)</th>
                        {<th scope="col">Note</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        visits?.length > 0 && visits?.map((visit, index) => {
                            let vital = visit?.vitals
                            if (vital)
                                return (
                                    <tr key={index} style={{ fontSize: "13px" }}>
                                        <td>{vital?.date?.slice(0, 10)}</td>
                                        <td>{vital?.height}</td>
                                        <td>{vital?.weight}</td>
                                        <td>{vital?.temperature}</td>
                                        <td>{vital?.bmi}</td>
                                        <td>{vital?.bp}</td>
                                        <td>{vital?.hr}</td>
                                        <td>{vital?.spo2}</td>
                                        <td>{vital?.rr}</td>
                                        {<td>{vital?.note}</td>}
                                    </tr>
                                );
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default PatientVitalSign
