export const isBrowser = typeof window !== "undefined";
export const isServer = false;

export const LocalStorageService = {
    set: (key, value) =>
        localStorage?.setItem(key, JSON.stringify(value)),
    get: (key) => {
        let res
        if (isBrowser) res = localStorage?.getItem(key);
        if (res === undefined || res === null) return null;
        return JSON.parse(res);
    },
    delete: (key) => localStorage?.removeItem(key),
    clear: () => localStorage?.clear(),
};