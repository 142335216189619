import React, { useEffect, useMemo, useRef, useState } from 'react'
import PrescriptionBody from './prescriptionBody';
import "./prescription-print.scss"
import PrescriptionHeader from './prescriptionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import PrescriptionFooter from './prescriptionFooter';
import { dateFormat } from '../../../utils/common-funtions/common';
import { get, post } from '../../../utils/fetchAPI';

const PublicPrescriptionPrint = ({ visitId }) => {
    const ref = useRef()
    const [visitDetails, setVisitDetails] = useState([])
    const [patientDetails, setPatientDetails] = useState({})
    const [isHeader, setIsHeader] = useState(true)
    const [isFooter, setIsFooter] = useState(true)
    const [doctorDetails, setDoctorDetails] = useState([])

    useEffect(() => {
        if (visitId) {
            getVisitDetails(visitId)
        }
    }, []);

    async function getPatientDetails(patientId) {
        await get(`/patient/${patientId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientDetails(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(true)
                    setIsFooter(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getVisitDetails = async (visitId) => {
        await get(`/visit/${visitId}`)
            .then((res) => {
                if (res.status === 200) {
                    setVisitDetails(res?.data)
                }
            })
            .catch((error) => {
            })
            .finally(() => { });
    }

    useMemo(() => {
        if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
        if (visitDetails?.patient) {
            getPatientDetails(visitDetails?.patient)
        }
    }, [visitDetails])

    return (
        <>
            <div className="mt-0 pt-0 d-flex justify-content-between p-2 display-hide">
                <div className='d-flex '>
                    <ReactToPrint
                        trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print / Save <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                        content={() => ref.current}
                        onBeforePrint={() => document.title = dateFormat(patientDetails?.updatedAt?.slice(0, 10)) + "-" + patientDetails?.name + "-" + patientDetails?.patientId
                        }
                        onAfterPrint={() => document.title = "Shafa-Emr"}
                    />
                </div>
                {/* <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p> */}
            </div>
            <div className='display-hide-lg p-2'>
                <div className=''>
                    <p style={{ frontSize: "14px", fontWeight: "600" }}><span className='text-secondary'>Patient Name:</span> {patientDetails?.name}</p>
                    {/* <p style={{ frontSize: "14px", fontWeight: "600" }}><span className='text-secondary'>Patient Phone: </span> {patientDetails?.phone}</p> */}
                    <p className='text-success' style={{ frontSize: "14px", fontWeight: "600" }}>Get you prescription from bellow</p>
                    <ReactToPrint
                        trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print / Save Prescription <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                        content={() => ref.current}
                        onBeforePrint={() => document.title = dateFormat(patientDetails?.updatedAt?.slice(0, 10)) + "-" + patientDetails?.name + "-" + patientDetails?.patientId
                        }
                        onAfterPrint={() => document.title = "Shafa-Emr"}
                    />
                </div>
            </div>
            <div className='display-hide' style={{ height: "90vh", overflowY: "scroll" }}>
                <div ref={ref} className='full-prescription' style={{ height: "90vh" }}>
                    <PrescriptionHeader isHeader={isHeader} info={patientDetails} drDetails={doctorDetails || []}></PrescriptionHeader>
                    <PrescriptionBody drDetails={doctorDetails || []} patientDetails={patientDetails} info={visitDetails}></PrescriptionBody>
                    <PrescriptionFooter isFooter={isFooter} drDetails={doctorDetails || []}></PrescriptionFooter>
                </div>
            </div></>
    )
}

export default PublicPrescriptionPrint
