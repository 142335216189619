import { get, post, put } from "../../utils/fetchAPI";

export const patientCreate = async (data) => {
  const res = await post("/patient/create", data);
  return res;
};

export const patientOtherHealthProblemAdd = async (payload, patientId) => {
  const res = await post(`/patient/add-other-health-problems/${patientId}`, payload);
  return res;
};


export const patientSurgeriesAdd = async (payload, patientId) => {
  const res = await post(`/patient/manage-surgical-history/${patientId}`, payload);
  return res;
};

export const patientSocialHistoryAdd = async (payload, patientId) => {
  const res = await post(`/patient/manage-social-history/${patientId}`, payload);
  return res;
};
export const patientPastMedicalHistoryAdd = async (payload, patientId) => {
  const res = await post(`/patient/manage-past-medical-history/${patientId}`, payload);
  return res;
};

export const patientFamilyHistoryAdd = async (payload, patientId) => {
  const res = await post(`/patient/manage-family-history/${patientId}`, payload);
  return res;
};


export const patientAllergyHistoryAdd = async (payload, patientId) => {
  const res = await post(`/patient/manage-allergy-history/${patientId}`, payload);
  return res;
};

export const patientLabTestAdd = async (payload, prescriptionId) => {
  const res = await post(`/patient/manage-prescribed-lab-tests/${prescriptionId}`, payload);
  return res;
};

export const patientAdviceAdd = async (payload, prescriptionId) => {
  const res = await post(`/patient/manage-prescribed-advices/${prescriptionId}`, payload);
  return res;
};

// export const patientAddSubDocument = async (objKey, payload, patientId) => {
//   const res = await put(`/patient/add-sub-document/${patientId}`, { [objKey]: payload });
//   return res;
// };
//
// export const patientUpdateSubDocument = async (objKey, payload, patientId) => {
//   payload = { ...payload, key: objKey }
//   const res = await put(`/patient/update-sub-document/${patientId}`, payload);
//   return res;
// };
//
// export const patientRemoveSubDocument = async (objKey, payload, patientId) => {
//   const res = await put(`/patient/remove-sub-document/${patientId}`, { [objKey]: payload });
//   return res;
// };


export const medicineUpdate = async (payload, prescriptionId) => {
  const res = await put(`patient/update-prescribed-medicine/${prescriptionId}`, payload);
  return res;
};

export const patientHistory = async (patientId) => {
  const res = await get(`patient/medical-history/${patientId}`);
  return res;
};

export const patientFileUpload = async (payload) => {
  const res = await post(`patient/upload`, payload);
  return res;
};


export const patientAddSubDocument = async (objKey, payload, patientId) => {
  const res = await put(`/patient/add-sub-document/${patientId}`, { [objKey]: payload });
  return res;
};

export const patientUpdateSubDocument = async (payload, patientId) => {
  const res = await put(`/patient/update-sub-document/${patientId}`, payload);
  return res;
};

export const patientRemoveSubDocument = async (objKey, payload, patientId) => {
  const res = await put(`/patient/remove-sub-document/${patientId}`, { [objKey]: payload });
  return res;
};
