import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{height:'100vh'}}>
      <h1 className='text-center'>404 page not found</h1>
    </div>
  )
}

export default PageNotFound