import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import "./staff-create-form.scss";
import { appointmentCreate, staffCreate } from "../../service/api/appointment-api";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getLocations, getOrganizations } from "../../store/slices/locationSlice";
import SelectWithLocalSearch from "../common/updated-input/select-with-local-search/select-with-local-search";

const StaffCreateForm = ({
  selectedPatientInfo,
  isNewUser,
  setSinglePatientInfo,
  setIsNewUser,
  refresh,
  setShow,
}) => {
  const [info, setInfo] = useState({type:'staff'});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [date] = useState(new Date().toJSON());
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [organizationList, setOrganizationList] = useState([])

  let { isLoading, allOrganizations, noLocationFound, organizationOptions } = useSelector(
    (state) => state.location
  );


  const customStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      minHeight: 20,
      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: "white",
      // backgroundColor: state.isSelected ? "#0fac81" : "white",
      color: state.isSelected ? "#0fac81" : "black",
      "&:hover": {
        backgroundColor: "white",
        color: "#0fac81"
      }

    }),

  };


  useEffect(() => {
    dispatch(getOrganizations({}));
  }, []);

  useMemo(() => {
    if (allOrganizations) {
      let orgArr = []
      allOrganizations?.map((organization) => {
        orgArr.push(organization?.organization)
      })
      setOrganizationList(orgArr)
    }
  }, [allOrganizations])
  // console.log(organizationList, 'organizationList')
  const onSubmit = async (values, { resetForm }) => {
    values = info;
    await staffCreate(values)
      .then((res) => {
        if (res.status === 200) {
          refresh()
          setIsSubmitting(false);
          setShow(false);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  };
  const setInfoFun = (key, value) => {
    let temp = { ...info };
    setInfo({ ...temp, [key]: value });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        gender: "",
        password: "",
        dob: "",
        address: "",
        organization: ""
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <div className="staff-create-form-section">
          <Form
            action=""
            id=""
            className="custom-form input-section position-relative"
            encType="multipart/form-data"
          >
            <div className="row  ">
              <div className="col-6 mb-0 pb-0 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  required
                  className="form-control input"
                  autoComplete="off"
                  type="text"
                  id="name"
                  name="name"
                  value={info?.name || ""}
                  placeholder="Enter Name"
                  //   defaultValue={info?.name}
                  onChange={(e) => {
                    setInfoFun("name", e.target.value);
                  }}
                />
                <br />
              </div>
              <div className="col-6 mb-0 pb-0 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Phone Number{" "}
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  pattern="(^(01){1}[3-9]{1}\d{8})$"
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  value={info.phone || ""}
                  onChange={(e) => {
                    setInfoFun("phone", e.target.value);
                  }}
                />
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>

              <div className="col-6 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  value={info?.email || ""}
                  onChange={(e) => {
                    setInfoFun("email", e.target.value);
                  }}
                />
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>
              <div className="col-6 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Password
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="password"
                  id="email"
                  name="email"
                  placeholder="Enter Password"
                  value={info?.password || ""}
                  onChange={(e) => {
                    setInfoFun("password", e.target.value);
                  }}
                />
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>

              <div className="col-6 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Organization
                </label>
                <SelectWithLocalSearch required={true} currentValue={""} name="location" handleFormValues={(organization) => {
                  setInfoFun("organization", organization?._id);
                  // setSelectedLocation(location)
                }} isCreatable={false} options={organizationList} placeHolder='Select location'></SelectWithLocalSearch>
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>
              <div className="col-3 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Role<span className="text-danger">*</span>
                </label>
                <select
                  style={{ width: "100%" }}
                  required
                  onChange={(e) => {
                    setInfoFun("type", e.target.value);
                  }}
                >
                  <option value={'staff'}>Staff </option>
                  <option value={'assistant'}> Assistant</option>
                </select>
                <br />
              </div>
              <div className="col-3 individual-input-section">
                <div className="">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Gender<span className="text-danger">*</span>
                  </label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{ fontSize: "20px" }}
                  >
                    <label className="mx-3 m-0 p-0">
                      <input
                        checked={
                          info?.gender?.toLowerCase() === "male" ? true : false
                        }
                        type="radio"
                        name="gender"
                        value="Male"
                        onClick={() => {
                          setInfoFun("gender", "Male");
                        }}
                      />
                      <span className="ml-1">Male</span>
                    </label>
                    <label className="m-0 p-0">
                      <input
                        checked={
                          info?.gender?.toLowerCase() === "female"
                            ? true
                            : false
                        }
                        type="radio"
                        name="gender"
                        value="Female"
                        onClick={() => {
                          setInfoFun("gender", "Female");
                        }}
                      />
                      <span className="ml-1">Female</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-6 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Address
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="text"
                  id="email"
                  name="address"
                  placeholder="Enter Address"
                  value={info?.address || ""}
                  onChange={(e) => {
                    setInfoFun("address", e.target.value);
                  }}
                />
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>

              <div className="col-5 individual-input-section">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Date of birth<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="date"
                  id="dob"
                  name="dob"
                  placeholder="Enter appointmentDate"
                  value={info?.dob?.slice(0, 10) || ""}
                  onChange={(e) => {
                    setInfoFun("dob", e.target.value);
                  }}
                />
                <br />
              </div>


            </div>
            <div className="d-flex justify-content-center pt-4">
              <button
                type="submit"
                className="btn success"
                onClick={() => { }}
              >
                Create
                {isSubmitting && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>

              <button
                className="btn cancel ml-3"
                onClick={() => { setShow(false) }}
              >
                Close
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default StaffCreateForm;
