import React from 'react'
import { useSelector } from 'react-redux';
import "./patient.scss"

const PatientVision = () => {
    let { visits, isVisitLoading } = useSelector(
        (state) => state.visit
    );
    return (
        <div>
            {
                visits?.length > 0 && visits?.map((visit, index) => {
                    let info = visit?.eyeExamination
                    if (info)
                        return (<div className='patient-vision-section border mb-2'>
                            <p style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }} className='title p-0 m-0 px-1'>Appointment date: {visit?.appointmentDate?.slice(0, 10)}</p>
                            {
                                <div className='mr-2' >
                                    {/* <p className='mx-1 mb-0 ' style={{ fontSize: "13px", fontWeight: "600" }}>Date:{info?.updatedAt?.slice(0, 10)}</p> */}
                                    <div className="spectacle p-1 vision-history ">
                                        {(info?.aided?.left || info?.aided?.right) && <div className='pb-0 mb-0'>
                                            <p className='py-0 my-0'>Visual Acuity: Aided</p>
                                            <p>Right Eye: {info?.aided?.left || ""}  Left Eye: {info?.aided?.right || ""}</p>
                                        </div>}
                                        {(info?.unaided?.left || info?.unaided?.right) && <div className='pb-0 mb-0'>
                                            <p className='py-0 my-0'>Visual Acuity: Unaided</p>
                                            <p>Right Eye: {info?.unaided?.left}  Left Eye: {info?.unaided?.right}</p>
                                        </div>}
                                        {(info?.pinHole?.left || info?.pinHole?.right) && <div className='pb-0 mb-0'>
                                            <p className='py-0 my-0'>Visual Acuity: Pin Hole</p>
                                            <p>Right Eye: {info?.pinHole?.left || ""}  Left Eye: {info?.pinHole?.right || ""}</p>
                                        </div>}

                                        {
                                            info?.diagnosis && Object.keys(info?.diagnosis).map((exam, index) => (
                                                <div key={index} className='d-flex py-9 my-0'>
                                                    <p className='py-0 my-0 pr-1'>{exam?.toUpperCase()}:</p>

                                                    {Object.keys(info?.diagnosis[exam]).map((side, sideIndex) => (
                                                        <p key={sideIndex} className='pr-2  py-9 my-0'>
                                                            {side == "left" ? "OS" : "OD"}: {info?.diagnosis[exam][side]}
                                                        </p>
                                                    ))}

                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className='row g-1'>
                                        <div className='col-md-6 p-3'>
                                            <p className='p-1 mt-1 m-0 title border  text-center'>Spectacle (Distance)</p>
                                            <table
                                                id="example2"
                                                className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Eye</th>
                                                        <th> Sphere</th>
                                                        <th>Cylinder</th>
                                                        <th>Axis</th>
                                                        <th>Acuity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ fontSize: "13px" }}>
                                                        <td className="">Right </td>
                                                        <td className="">{info?.distance?.right?.sphere}</td>
                                                        <td className="">{info?.distance?.right?.cylinder}</td>
                                                        <td className="">{info?.distance?.right?.axis}</td>
                                                        <td className="">{info?.distance?.right?.acuity}</td>
                                                    </tr>
                                                    <tr style={{ fontSize: "13px" }}>
                                                        <td className="">Left </td>
                                                        <td className="">{info ? info?.distance?.left?.sphere : ""}</td>
                                                        <td className="">{info ? info?.distance?.left?.cylinder : ""}</td>
                                                        <td className="">{info ? info?.distance?.left?.axis : ""}</td>
                                                        <td className="">{info ? info?.distance?.left?.acuity : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6 mt-3 p-1">
                                            <p className='p-1 m-0 title border text-center'>Near</p>
                                            <table
                                                id="example2"
                                                className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                                            >
                                                <thead>
                                                    <tr >
                                                        <th>Eye</th>
                                                        <th> Addition</th>
                                                        <th>Acuity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ fontSize: "13px" }}>
                                                        <td className="">Right </td>
                                                        <td className="">{info?.near?.right?.addition}</td>
                                                        <td className="">{info?.near?.right?.acuity}</td>
                                                    </tr>
                                                    <tr style={{ fontSize: "13px" }}>
                                                        <td className="">Left </td>
                                                        <td className="">{info ? info?.near?.left?.addition : ""}</td>
                                                        <td className="">{info ? info?.near?.left?.acuity : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className='mx-1'>
                                        <p style={{ fontSize: "13px" }} className='py-0 my-0'>IPD: {info ? info?.ipd : ""}</p>
                                        <p style={{ fontSize: "13px" }}>Glasses: {info?.glasses}</p>
                                    </div>
                                </div>}
                        </div>)
                })
            }

        </div>
    )
}

export default PatientVision
