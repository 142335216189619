import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/slices/authSlice";
import "./login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Slider from "../../components/login/slider";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidate, setIsValidate] = useState(true);
  let { isLoading } = useSelector((state) => state.auth);
  const handleLogin = (e) => {
    e.preventDefault();
    if (email.length !== 0 && password.length !== 0) {
      dispatch(login({ email, password }))
        .unwrap()
        .then((res) => {
          if (res?.user && res?.token) {
            navigate("/");
          }
          else {
            setFormError("Server not responding");
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setFormError(rejectedValueOrSerializedError?.errors[0]?.msg);
        });
    } else setIsValidate(false);
  };

  const showPassword = () => {
    var pass = document.getElementById("pass-field");
    if (pass.type === "password") {
      setIsShow(true);
      pass.type = "text";
    } else {
      setIsShow(false);
      pass.type = "password";
    }
  };

  return (
    <div className="">
      <div className="row login-container">
        <div className="col-md-7 col-12 d-sm-none d-md-block slider-container ">
          <Slider />
        </div>
        <div className=" col-md-5 col-12 login-form">
          <div className="login-form-container">
            {/*<div className="login-logo">*/}
            {/*  <a href="#">*/}
            {/*    <b>Shafa </b> <img src={logo} alt="shafa-care" width={40}></img>{" "}*/}
            {/*    <b>care </b>*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/* /.login-logo */}
            <div className=" ">
              <div className="">
                <div className="mb-3">
                  <h5 className="text-left mb-0 pb-0">
                    <b>Sign in</b>
                  </h5>
                  <small className="text-left">
                    Shafa Care redefines the delivery system of primary
                    healthcare in Bangladesh
                  </small>
                </div>
                {/* showerror message */}
                {/* {!!authError ? (
                  <span className="text-danger">{authError}</span>
                ) : null} */}
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Email</p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setIsValidate(true);
                          setFormError("");
                        }}
                        //   value={email}
                        placeholder="Enter your email"
                      />
                    </div>
                    {email.length === 0 && !isValidate ? (
                      <small className="text-danger">
                        Please enter your email
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    <p className="text-left label pb-1 mb-1">Password</p>
                    <div className="mb-3 position-relative">
                      <input
                        autoComplete="off"
                        type="password"
                        className="form-control"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setIsValidate(true);
                          setFormError("");
                        }}
                        id="pass-field"
                        placeholder="Enter your password"
                      />
                      <span className="eye-icon p-0 m-0" onClick={showPassword}>
                        <FontAwesomeIcon
                          icon={faEye}
                          fade={isShow}
                          className="text-secondary"
                        />
                      </span>
                      {(password?.length === 0) & !isValidate ? (
                        <small className="text-danger">
                          Please enter your password
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row ">
                    {/* /.col */}
                    <div className="col-md-12 ">
                      {isLoading ? (
                        <button
                          className="btn submit-button w-100  "
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn submit-button w-100 "
                        >
                          Sign In
                        </button>
                      )}
                      {formError?.length !== 0 ? (
                        <small className="text-center mx-auto text-danger">
                          {formError}
                        </small>
                      ) : (
                        ""
                      )}
                      {/* <p className="text-center mt-2">Don't have an account, <span onClick={() => { navigate('/registration') }} style={{ color: "#0EAC81", cursor: "pointer", fontWeight: "600" }}>Sign Up</span></p> */}
                    </div>

                    {/* /.col */}
                  </div>
                </form>
              </div>
              <div className="text-center login-form-footer">
                <small className="">
                  Copyright © 2014-2021 <strong>Shafa Care.</strong> All rights
                  reserved.
                </small>
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
