import { put, post, get } from "../../utils/fetchAPI";

export const createVisit = async (appointmentId) => {
    return post(`/visit/${appointmentId}`);
};

export const visitUpdate = async (payload, visitId) => {
    const res = await put(`/visit/${visitId}`, payload);
    return res;
};

export const carePlanTemplates = async (doctorId) => {
    const res = await get(`doctor/care-plan/list/${doctorId}`);
    return res;
};

export const setCarePlanTemplate = async (payload) => {
    const res = await post(`visit/add-care-plan-template/${payload?.visitId}`, { templateId: payload?.templateId });
    return res;
};