import React, { useEffect, useMemo, useState } from 'react'
import "./sms-portal-component.scss"
import { get, put } from '../../utils/fetchAPI';
import { showErrorToast } from '../../utils/toaster-alert';

const PatientSection = ({ phoneNumbers, setPhoneNumbers,selectAll, setSelectAll }) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [patientList, setPatientList] = useState([])
    const [searchBy, setSearchBy] = useState("name")
 
    useEffect(() => {
        getPatientList()
    }, [])

    const getPatientList = async (search = searchBy, query = searchQuery) => {
        await get(`patient/list?per_page=200&current_page=1&key=${search}&search=${query}`)
            .then((res) => {
                if (res.status === 200) {
                    setPatientList(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const makeNumberArray = (number) => {
        const phoneSet = new Set(phoneNumbers);
        if (phoneSet.has("88"+number)) {
            phoneSet.delete("88"+number);
        } else {
            if (number?.length == 11)
                phoneSet.add("88"+number);
        }
        setPhoneNumbers([...phoneSet]);
    }

   

    return (
        <div className='patient-name-section'>
            <div className='row'>
                <div className='col-md-9'>
                    <select
                        style={{ width: "100%", outline: "none", fontSize: "14px" }}
                        onChange={(e) => {
                            setSearchBy(e.target.value)
                        }}
                        value={searchBy}
                    >
                        <option value={''}>Search by </option>
                        <option value={'name'}>Name </option>
                        <option value={'phone'}> Phone</option>
                    </select>{" "}
                </div>
                <div className='col-md-3'>
                    <div className='d-flex mr-4 ml-3'>
                        <p style={{ fontSize: "14px", fontWeight: "600" }} className='my-0 py-0 mr-2'>All</p>
                        <input
                            type='checkbox'
                            className=''
                            id={``}
                            value={selectAll}
                            checked={selectAll}
                            onChange={(e) => {
                                setSelectAll(!selectAll)
                                setPhoneNumbers([])
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-9'>
                    <input className='search-bar' onChange={(e) => {
                        setSearchQuery(e.target.value)
                        setSelectAll(false)
                    }} placeholder='Search Patient'></input>
                </div>
                <div>
                    <button onClick={() => { getPatientList() }} style={{ fontSize: "13px" }} className='btn btn-success py-0 my-0'>Search</button>
                </div>
            </div>
            <div className='border-top  name-section-list'>
                {patientList?.patients?.map((patient, index) => {
                    return (
                        <div className='individual-name d-flex justify-content-between px-3' style={{ cursor: "pointer" }} onClick={(e) => {
                            if (patient?.phone)
                                makeNumberArray(patient?.phone)
                            else showErrorToast("no number found")
                        }}>
                            <div className='py-1'>
                                <p className='my-0'>{patient?.name}</p>
                                <p className='my-0'>{patient?.phone}</p>
                            </div>
                            <input
                                type='checkbox'
                                className='me-2'
                                disabled={selectAll}
                                id={`patient-${patient?.id}`}
                                value={patient?.phone}
                                checked={phoneNumbers?.includes("88"+patient?.phone)}
                                onChange={(e) => {
                                    makeNumberArray(e.target.value)
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PatientSection
