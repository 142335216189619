import React, { useEffect, useMemo, useState } from 'react'

// import { createTemplate, deleteTemplate } from '../../service/api/template';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, put } from '../../../utils/fetchAPI';
import UniversalLoaderForComponent from '../../loader/universalLoaderForComponent';


const NameSectionCarePlan = ({isLoading=false, setOptionFun, deleteTemplate, setAddNew, doctorId, setSelectedTemplate, selectedTemplate, list }) => {
   
    return (
        <div className='template-name'>
            <button onClick={() => {
                setSelectedTemplate({ title: "", details: "" })
                setAddNew(true)
            }} className='btn w-100 py-0' style={{ borderRadius: "4px" }}>Add new
            </button>
            <input onChange={(e) => {
                setOptionFun(e)
            }} placeholder='search template'></input>
            <label>Templates names</label>
            <div className='names' style={{display:"relative"}}>
                {list?.map((template, index) => {
                    return (
                        <div
                            className={`individual-name d-flex justify-content-between mt-0 pt-0 ${selectedTemplate?._id == template?._id ? "active-template" : ""}`}>
                            <p onClick={() => {
                                setAddNew(false)
                                setSelectedTemplate(template)
                            }} key={index}
                                className='p-0 m-1  w-75 name'>{template?.title}</p>
                            <p className='p-0 m-1 pr-2 delete'>
                                <FontAwesomeIcon style={{ fontSize: "10px" }} onClick={() => {
                                    deleteTemplate(template?._id)
                                }} icon={faTrash}></FontAwesomeIcon>
                            </p>
                        </div>
                    )
                })}
                {isLoading&&<div style={{position:"absolute",left:"5%"}}><UniversalLoaderForComponent></UniversalLoaderForComponent></div>}
                {list?.templates?.length == 0 && <p className='individual-name'>Not available</p>}
            </div>

        </div>
    )
}

export default NameSectionCarePlan
