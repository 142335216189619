import React from 'react'
import './prescription-print.scss'
import { dateFormat } from '../../../utils/common-funtions/common'

const PrescriptionHeader = ({ info, isHeader, drDetails }) => {
    return (
        <div className='prescription-header' style={{ background: drDetails?.config?.headerBackgroundColor || "white", height: drDetails?.config?.prescriptionHeaderHeight ? drDetails?.config?.prescriptionHeaderHeight + "px" : "240px" }}>
            {((isHeader == true || isHeader == false) ? isHeader : true) && <div className='mt-2 pt-4 pb-2'>
                <div dangerouslySetInnerHTML={{ __html: drDetails?.config?.prescriptionHeader }} />
            </div>}
        </div>
    )
}

export default PrescriptionHeader


