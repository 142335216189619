import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getWithParams, post } from "../../utils/fetchAPI";

const initialState = {
  vitalSigns: [],
  latestVitalSign: {},
  complaints: [],
  otherProblems: [],
  latestComplaint: {},
  latestOtherProblem: {},
  visitDetails: {},
  patientId: "",
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noVitalSign: false,
  noComplaint: false,
  noOtherHealthProblem: false,
  surgeryList: [],
  patientSurgeryList: [],
  patientSocialHistory: {},
  patientFamilyHistory: {},
  patientPastMedicalHistory: {},
  socialHabitList: [],
  pastMedicalList: [],
  diseaseList: [],
  visits: [],
  isVisitLoading: false,
  allergyList: [],
  patientAllergyHistory: {},

};

// export const getVisitDetails = createAsyncThunk(
//   "visit/getVisitDetails",
//   async (visitId, { rejectWithValue }) => {
//     try {
//       const response = await get(`/visit/details/${visitId}`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const getPatientVitalSign = createAsyncThunk(
  "visit/getPatientVitalSign",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/vitals/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientComplaints = createAsyncThunk(
  "visit/getPatientComplaints ",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`/patient/complaints-list/${payload?.patientId}`, { visitId: payload?.visitId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOtherHealthProblems = createAsyncThunk(
  "visit/getOtherHealthProblems",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/other-health-problems-list/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getSurgeryList = createAsyncThunk(
  "visit/getSurgeryList ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`/surgery-list`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientSurgeryList = createAsyncThunk(
  "visit/getPatientSurgeryList ",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await post(`/patient/manage-surgical-history/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientSocialHistoryList = createAsyncThunk(
  "visit/getPatientSocialHistoryList",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/social-history/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientFamilyHistoryList = createAsyncThunk(
  "visit/getPatientFamilyHistoryList",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/family-history/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientPastMedicalHIstoryList = createAsyncThunk(
  "visit/getPatientPastMedicalHIstoryList",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/past-medical-history/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSocialHabitList = createAsyncThunk(
  "visit/getSocialHabitList ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`/social-habit-list`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllergyList = createAsyncThunk(
  "visit/getAllergyList ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`/allergy-list`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientAllergyList = createAsyncThunk(
  "visit/getPatientAllergyList ",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/allergy-history/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//getMedicalHistoryList

export const getMedicalHistoryList = createAsyncThunk(
  "visit/getMedicalHistoryList ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`/medical-disease-list`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDiseaseList = createAsyncThunk(
  "visit/getDiseaseList ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`/disease-list`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllVisit = createAsyncThunk(
  "prescription/getAllVisit",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`/visits/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const visitSlice = createSlice({
  name: "visit",
  initialState,
  reducers: {
    resetVisitDetails: (state, action) => {
      state.isLoading = false;
      state.isOperationLoading = false;
      state.visitDetails = {}
      state.error = null;
    },
    resetSocialHistory: (state, action) => {
      state.isLoading = false;
      state.isOperationLoading = false;
      state.patientSocialHistory = {}
      state.error = null;
    },
    resetPastMedicalHistory: (state, action) => {
      state.isLoading = false;
      state.isOperationLoading = false;
      state.patientPastMedicalHistory = {}
      state.error = null;
    },
    resetFamilyHistory: (state, action) => {
      state.isLoading = false;
      state.isOperationLoading = false;
      state.patientFamilyHistory = {}
      state.error = null;
    },

  },
  extraReducers: {

    // [getVisitDetails.pending]: (state) => {
    //   state.isLoading = true;
    //   state.error = null;
    //   state.visitDetails={};
    //   state.patientId=""
    // },
    // [getVisitDetails.fulfilled]: (state, action) => {
    //   state.isLoading = false;
    //   state.visitDetails = action.payload;
    //   state.patientId=action.payload.patient;
    //   state.error = null;
    // },
    // [getVisitDetails.rejected]: (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.payload?.message;
    // },

    [getPatientVitalSign.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.noVitalSign = false;
      state.latestVitalSign = {};
    },
    [getPatientVitalSign.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.vitalSigns = action.payload;
      if (action.payload.vitalSign?.length === 0) {
        state.noVitalSign = true;
      }
      if (action.payload.vitalSign?.length > 0) {
        state.latestVitalSign = action?.payload?.vitalSign[0];
      }
      state.error = null;
    },
    [getPatientVitalSign.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getPatientComplaints.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.noComplaint = false;
      state.latestComplaint = {};
    },
    [getPatientComplaints.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.complaints = action.payload;
      if (action.payload.complaints?.length === 0) {
        state.noComplaint = true;
      }
      state.latestComplaint = action?.payload?.complaint;
      state.error = null;
    },
    [getPatientComplaints.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },
    // other health problem 
    [getOtherHealthProblems.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.noComplaint = false;
      state.latestOtherProblem = {};
      state.otherProblems = []
    },
    [getOtherHealthProblems.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.otherProblems = action.payload;
      if (action.payload.otherHealthProblem?.length === 0) {
        state.noOtherHealthProblem = true;
      }
      if (action.payload.otherHealthProblem?.length > 0) {
        state.latestOtherProblem = action?.payload?.otherHealthProblem[0];
      }
      state.error = null;
    },
    [getOtherHealthProblems.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },
    //full surgery list  
    [getSurgeryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSurgeryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.surgeryList = action.payload;
      state.error = null;
    },
    [getSurgeryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getAllergyList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAllergyList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allergyList = action.payload;
      state.error = null;
    },
    [getAllergyList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    //individual patient surgery list 
    [getPatientSurgeryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPatientSurgeryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.patientSurgeryList = action.payload;
      state.error = null;
    },
    [getPatientSurgeryList.rejected]: (state, action) => {
      state.isLoading = false;

      state.error = action.payload?.message;
    },

    //social habits
    [getSocialHabitList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSocialHabitList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialHabitList = action.payload;
      state.error = null;
    },
    [getSocialHabitList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },



    [getPatientSocialHistoryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPatientSocialHistoryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.patientSocialHistory = action.payload;
      state.error = null;
    },
    [getPatientSocialHistoryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },


    [getMedicalHistoryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getMedicalHistoryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pastMedicalList = action.payload;
      state.error = null;
    },
    [getMedicalHistoryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getPatientPastMedicalHIstoryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPatientPastMedicalHIstoryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.patientPastMedicalHistory = action.payload;
      state.error = null;
    },
    [getPatientPastMedicalHIstoryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    //disease / family history
    [getDiseaseList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getDiseaseList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.diseaseList = action.payload;
      state.error = null;
    },
    [getDiseaseList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getPatientFamilyHistoryList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPatientFamilyHistoryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.patientFamilyHistory = action.payload;
      state.error = null;
    },
    [getPatientFamilyHistoryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },

    [getAllVisit.pending]: (state) => {
      state.error = null;
      state.isVisitLoading = true;
    },
    [getAllVisit.fulfilled]: (state, action) => {
      state.visits = action.payload;
      state.isVisitLoading = false;
      state.error = null;
    },
    [getAllVisit.rejected]: (state, action) => {
      state.isVisitLoading = false;
      state.error = action.payload?.message;
    },

    [getPatientAllergyList.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [getPatientAllergyList.fulfilled]: (state, action) => {

      state.patientAllergyHistory = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    [getPatientAllergyList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },
  },
});

export default visitSlice.reducer;
export const { resetVisitDetails, resetSocialHistory, resetPastMedicalHistory, resetFamilyHistory } = visitSlice.actions;