import React, { useState } from 'react'
import "./file-upload.scss"
import ImageUpload from '../../../common/updated-input/image-upload/ImageUpload';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { patientFileUpload } from '../../../../service/api/patient-api';
import { useDispatch } from 'react-redux';
import { getAllFiles } from '../../../../store/slices/prescriptionSlice';

const FileUpload = ({ setUploadModalShow, patientId }) => {
    const currentDate = new Date().toJSON();
    const [info, setInfo] = useState({ type: "Lab", date: currentDate?.slice(0, 10) })
    const [formData, setFormData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()

    const onStoreImage = (data) => {

        let newFormData = [ ...formData,...data ];
        setFormData(newFormData)
    }
    const handleRemoveExpenseImage = (index) => {
        let newFormData = [...formData];
        newFormData?.splice(index, 1)
        setFormData([...newFormData])
    }
    const handleFormSubmit = (e) => {
        e.preventDefault()
        let formBody = new FormData();
        formBody.append('patientId', patientId);
        formBody.append('type', info.type);
        formBody.append('date', info.date);
        formBody.append('description', info.description);

        if (formData?.length > 0) {
            formData?.map(image => {
                formBody.append('files', image)
            })
        }
     
        setIsSubmitting(true)
        patientFileUpload(formBody).then(res => {
            if (res?.status == 200) {
                dispatch(getAllFiles(patientId))
                setUploadModalShow(false)

            }
        })
            .catch(err => {
                console.log(err)
            })
            .finally(() => setIsSubmitting(false))

    }


    // patientFileUpload
    return (
        <div onSubmit={handleFormSubmit} className='file-upload p-2'>
            <form
                action=""
                id=""
                className="custom-form input-section"
                encType="multipart/form-data"
            >
                <div className="row">

                    <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Type<span className="text-danger">*</span>
                        </label>
                        <select
                            className='d-block'
                            onChange={(e) => {
                                setInfo({ ...info, type: e.target.value })
                            }}
                        >
                            <option value={"Lab"}>Lab Report / Imaging </option>
                            <option value={"Prescription"}>Prescription </option>
                        </select>
                    </div>
                    <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Description<span className="text-danger">*</span>
                        </label>
                        <textarea
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="text"
                            id="name"
                            name="description"
                            value={info?.description || ""}
                            placeholder="Enter Name"
                            //   defaultValue={info?.name}
                            onChange={(e) => {
                                // setInfoFun("name", e.target.value);
                                setInfo({ ...info, description: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Date<span className="text-danger">*</span>
                        </label>
                        <input
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="name"
                            name="date"
                            value={info?.date || ""}
                            placeholder="Enter Name"
                            //   defaultValue={info?.name}
                            onChange={(e) => {
                                // setInfoFun("name", e.target.value);
                                setInfo({ ...info, date: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Image / Pdf<span className="text-danger">*</span>
                        </label>
                        <ImageUpload storeImage={onStoreImage} removeImage={handleRemoveExpenseImage} limit={5}></ImageUpload>
                    </div>



                    <div className="col-12  d-flex text-center mt-3 justify-content-center">
                        <button
                            type="submit"
                            className="btn submit-button  w-25"
                            onClick={() => { }}
                        >
                            Upload
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                        <p onClick={() => setUploadModalShow(false)} className='cancel-button'>Cancel</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FileUpload
