import React, { useEffect, useState } from 'react'
import './appointment-filter.scss'
import filter from "../../assets/images/filter.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const AppointmentWaitingFilter = ({ filterStatusFun, setStatus, status, width, currentDate, filterDateFun, setFilterDate, filterDate }) => {
    const utcDate = new Date();
    let bdDate = utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
    bdDate = bdDate.split('/')
    let date = `${bdDate[2]}-${bdDate[0]?.length == 1 ? ("0" + bdDate[0]) : bdDate[0]}-${bdDate[1]?.length == 1 ? ("0" + bdDate[1]) : bdDate[1]}`
    return (
        <div className='filter-section' style={{ width: width }}>
            {/* <p className='text-center heading m-0'><img className='mr-1' src={filter}></img> Filters</p> */}
            {/* <hr className='p-0 m-0 mb'></hr> */}
            <div className='px-2 date-filter border py-2 pt-2Create new appointment'>
                <p className='p-0 m-0 title text-center'>Date Filter {((filterDate?.startDate?.slice(0, 10) != date?.slice(0, 10)) || (filterDate?.endDate?.slice(0, 10) != date?.slice(0, 10))) && <FontAwesomeIcon onClick={() => filterDateFun('current-date', date?.slice(0, 10), date?.slice(0, 10))} size='xs' className='ml-1 rest' icon={faArrowsRotate} />}</p>
                <hr className='m-0 p-0 mb-2'></hr>

                <div onClick={() => {
                    if (!currentDate) filterDateFun('current-date', date?.slice(0, 10), date?.slice(0, 10))
                }}>
                    <input checked={((filterDate?.startDate?.slice(0, 10) == date?.slice(0, 10)) && (filterDate?.endDate?.slice(0, 10) == date?.slice(0, 10))) ? true : false} id='today' name="today" type='checkbox'></input>
                    <label htmlFor='today'>Todays Appointment</label>
                </div>

                <div className='row pr-1'>
                    <span className='col-4'><small>From:</small></span> <input value={filterDate?.startDate || ""} onChange={(e) => {
                        setFilterDate({ startDate: e.target.value?.slice(0, 10), endDate: filterDate?.endDate })
                        if (filterDate?.endDate) filterDateFun('between-date', e.target.value?.slice(0, 10), filterDate?.endDate)
                    }} className='col-8 pe-2 mb-2' type='date'></input>
                    <span className='col-4'><small>To</small></span> <input min={filterDate?.startDate?.slice(0, 10)} value={filterDate?.endDate || ""} onChange={(e) => {
                        setFilterDate({ startDate: filterDate?.startDate, endDate: e.target.value?.slice(0, 10) })
                        if (filterDate?.startDate) filterDateFun('between-date', filterDate?.startDate, e.target.value?.slice(0, 10))
                    }} className='col-8' type='date'></input>
                </div>
            </div>
            <div className='px-2 date-filter border py-2'>
                <p className='p-0 m-0 title text-center'>Status Filter {status && <FontAwesomeIcon onClick={() => filterStatusFun('reSet')} size='xs' className='ml-1 rest' icon={faArrowsRotate} />}</p>
                <hr className='m-0 p-0 mb-2'></hr>
                <div className='' onChange={() => {
                    setStatus("present")
                    if (status != "present") filterStatusFun("present")
                    else if (status == "present") filterStatusFun('clear')
                }
                }>
                    <input checked={status == "present" ? true : false} id='present' name="present" type='checkbox'></input>
                    <label htmlFor='present'>Present</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("pending")
                    if (status != "pending") filterStatusFun("pending")
                    else if (status == "pending") filterStatusFun('clear')

                }}>
                    <input checked={status == "pending" ? true : false} id='Pending' name="Pending" type='checkbox'></input>
                    <label htmlFor='Pending'>Pending</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("completed")
                    if (status != "completed") filterStatusFun("completed")
                    else if (status == "completed") filterStatusFun('clear')
                }}>
                    <input checked={status == "completed" ? true : false} id='Completed' name="Completed" type='checkbox'></input>
                    <label htmlFor='Completed'>Completed</label>
                </div>
                <div className='' onChange={() => {
                    setStatus("canceled")
                    if (status != "canceled") filterStatusFun("canceled")
                    else if (status == "canceled") filterStatusFun('clear')
                }}>
                    <input checked={status == "canceled" ? true : false} id='Canceled' name="Canceled" type='checkbox'></input>
                    <label htmlFor='Canceled'>Canceled</label>
                </div>
            </div>
        </div>
    )
}
export default AppointmentWaitingFilter

