
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './diagnosis.scss'
import { put } from '../../../../utils/fetchAPI';
const DiagnosisTemplate = ({ doctorId = "1", templateId }) => {
    let { templateDetails } = useSelector((state) => state.template);
    const [info, setInfo] = useState({});
    useDispatch();
    const timeoutIdRef = useRef(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const setInfoFun = (key, value) => {
        let temp = { ...info, [key]: value };
        setInfo(temp);
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1000);
    };

    const onSubmit = async (temp) => {

        const payLoad = {
            provisionalDx: temp.provisionalDx || "",
            differentialDx: temp.differentialDx || ""
        }
        // setIsSubmitting(true)
        await put(`doctor/template/${templateId}`, payLoad)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    // prescriptionRefresh()
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.log(error);
            });

    };

    useMemo(() => {
        setInfo(
            {
                provisionalDx: templateDetails?.provisionalDx || "",
                differentialDx: templateDetails?.differentialDx || "",
            }
        )
    }, [templateDetails])


    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {

            event.preventDefault();
            // let values = {}
            // values.reasonForVisit = info.reasonForVisit;
            // values.historyOfPresetIllness = info.historyOfPresetIllness;
            // values.patient = patientId
            // saveFun(values)
            onSubmit()

        };
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);

    //  const setInfoFun = (key, value, type, id) => {
    //     let temp = { ...info };
    //     setInfo({ ...temp, [key]: value });
    //     clearTimeout(timeoutIdRef.current);
    //     timeoutIdRef.current = setTimeout(() => { upDateMedicineFun(value, type, id) }, 700);
    // };
    return (
        <div className='template-diagnosis'>
            <div className="">
                <form
                    onSubmit={(event) => onSubmit(event)}
                    action=""
                    id=""
                    className="custom-form input-section position-relative"
                    encType="multipart/form-data"
                >
                    <div className="row  ">
                        <div className="col-6 col-md-12  mb-0 pb-0 ">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Provisional Diagnosis
                            </label>
                            <div className="d-flex">
                                {" "}
                                <textarea
                                    className="form-control  w-100"
                                    autoComplete="off"
                                    type="text"
                                    rows={3}
                                    placeholder="Provisional Diagnosis"
                                    value={info.provisionalDx || ""}
                                    onChange={(e) => {
                                        setInfoFun("provisionalDx", e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-md-12  mb-0 pb-0 ">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Differential Diagnosis
                            </label>
                            <div className="d-flex">
                                {" "}
                                <textarea
                                    className="form-control  w-100"
                                    autoComplete="off"
                                    type="text"
                                    rows={3}
                                    placeholder="Differential Diagnosis"
                                    value={info.differentialDx || ""}
                                    onChange={(e) => {
                                        setInfoFun("differentialDx", e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className="col-12  text-center mt-3 d-flex">
                            <button
                                type="submit"
                                className="btn submit-button  mx-auto w-25"
                            >
                                Save
                                {isSubmitting && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </button>
                        </div> */}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DiagnosisTemplate
