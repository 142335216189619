import React, { useEffect, useState, useRef } from 'react'
import './vision.scss'
import { Toast } from '../../../utils/SwalUti'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import ReactToPrint from 'react-to-print';
import VisionPrint from "../vision-components/vision-print"
import PrescriptionHeader from '../prescription-print/prescriptionHeader'
import { visitUpdate } from "../../../service/api/visit";
import { get } from '../../../utils/fetchAPI'


const Vision = ({ visitDetails, updateState }) => {
    const [info, setInfo] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [doctorDetails, setDoctorDetails] = useState([])
    const [isHeader, setIsHeader] = useState(true)
    let [user] = useState(JSON.parse(localStorage.getItem("user")))
    const ref = useRef()
    const [infoShow, setInfoShow] = useState({
        distance: {
            left: {
                sphere: "",
                cylinder: "",
                axis: "",
                acuity: ""

            },
            right: {
                sphere: "",
                cylinder: "",
                axis: "",
                acuity: ""
            }
        },
        near: {
            left: {
                addition: "",
                acuity: ""
            },
            right: {
                addition: "",
                acuity: ""
            }
        },
        ipd: ""
    })

    const mainVision = [
        {
            name: "Unaided",
            value: "unaided",
            id: 1
        },
        {
            name: "Aided",
            value: "aided",
            id: 2
        },
        {
            name: "Pin Hole",
            value: "pinHole",
            id: 3
        },
    ]

    const otherVision = [
        {
            name: "Conjunctiva",
            value: "conjunctiva",
            id: 1
        },
        {
            name: "Cornea",
            value: "cornea",
            id: 2
        },
        {
            name: "A/C",
            value: "ac",
            id: 3
        },
        {
            name: "Iris",
            value: "iris",
            id: 4
        },
        {
            name: "Pupil",
            value: "pupil",
            id: 5
        },
        {
            name: "Lens",
            value: "lens",
            id: 6
        },
        {
            name: "IOP",
            value: "iop",
            id: 7
        },
        {
            name: "CD Ratio",
            value: "cdRatio",
            id: 8
        },
        {
            name: "Fundus",
            value: "fundus",
            id: 9
        },
        {
            name: "Ocular Motil",
            value: "ocularMotil",
            id: 10
        },
        {
            name: "Eye Lio",
            value: "eyeLid",
            id: 11
        },
        {
            name: "CCT",
            value: "cct",
            id: 12
        },
        {
            name: "Gonioscopy",
            value: "gonioscopy",
            id: 13
        },

    ]

    useEffect(() => {
        if (visitDetails.eyeExamination)
            setInfoShow(visitDetails.eyeExamination)

        if (user?.user?.type?.toLowerCase() === 'doctor') {
            setDoctorDetails(user?.user)
            setIsHeader(user?.user.details?.config?.prescriptionHeaderInPrint)
        }
        else if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
    }, [])


    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const setVisionInfoFun = (key, value, root) => {
        setInfo({ ...info, [`eyeExamination.${root}.${key}`]: value })
        setInfoShow({ ...infoShow, [root]: { ...infoShow[root], [key]: value } })
    }

    const setOtherVisionInfoFun = (key, value, root) => {
        setInfo({ ...info, [`eyeExamination.diagnosis.${root}.${key}`]: value })

        setInfoShow({
            ...infoShow,
            diagnosis: {
                ...infoShow.diagnosis,
                [root]: { ...(infoShow?.diagnosis?.hasOwnProperty(root) ? infoShow?.diagnosis[root] : {}), [key]: value }
            }
        })
    }

    const setDistanceInfoFun = (key, value, root, parent) => {
        setInfo({ ...info, [`eyeExamination.${parent}.${root}.${key}`]: value })
        let child = {}
        if (infoShow?.hasOwnProperty(parent)) {
            if (infoShow[parent]?.hasOwnProperty(root))
                child = infoShow[parent][root] || {}
        }

        setInfoShow({ ...infoShow, [parent]: { ...(infoShow[parent] || {}), [root]: { ...(child || {}), [key]: value } } })
    }
    const setNearInfoFun = (key, value, root, parent) => {
        setInfo({ ...info, [`eyeExamination.${parent}.${root}.${key}`]: value })

        let child = {}
        if (infoShow?.hasOwnProperty(parent)) {
            if (infoShow[parent]?.hasOwnProperty(root))
                child = infoShow[parent][root] || {}
        }

        setInfoShow({ ...infoShow, [parent]: { ...(infoShow[parent] || {}), [root]: { ...(child || {}), [key]: value } } })
    }

    const onSubmitFun = (e) => {
        e.preventDefault()
        setIsSubmitting(true);

        visitUpdate(info, visitDetails._id).then((res) => {
            if (res.status === 200) {
                updateState(res?.data)
                setIsSubmitting(false);
                Toast.fire({
                    icon: '',
                    title: "Vision data saved successfully"
                })
            }
        }).catch((error) => {
            setIsSubmitting(false);
        });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            onSubmitFun(event)
        }
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);

    const glasses = [
        "Unifocal",
        "Varifocal/Progressive",
        "Reading",
        "Photocromatic",
        "Plastic",
        "Bifocal",
        "Constant Use",
        "Half Segment",
        "White",
        "Two Separate",
        "Optics Require"
    ]

    return (
        <div className='vision-section'>

            <form
                onSubmit={(e) => {
                    onSubmitFun(e)
                }}
                action=""
                id=""
                className="custom-form input-section"
                encType="multipart/form-data"
            >
                <div className='row no-gutters'>
                    <div className="col-5 vision p-1">
                        <p className='p-0 m-0 title'>Vision</p>
                        <table
                            id="example2"
                            className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                        >
                            <thead>
                                <tr>
                                    <th className="">Right Eye</th>
                                    <th className=""></th>
                                    <th className="">Left Eye</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mainVision?.map((value, index) => {
                                        return (
                                            <tr key={"Vision-" + value?.id}>
                                                <td className=""><input onChange={(e) => {
                                                    setVisionInfoFun("right", e.target.value, value?.value)
                                                }} value={infoShow?.[value?.value]?.right || ""} type='text'></input></td>
                                                <td className="text-center" style={{ color: "#13AC81" }}>{value?.name}</td>
                                                <td className=""><input onChange={(e) => {
                                                    setVisionInfoFun("left", e.target.value, value?.value)
                                                }} value={infoShow[value?.value]?.left || ""} type='text'></input></td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="col-7 spectacle p-1">
                        <p className='p-0 m-0 title'>Spectacle (Distance)</p>
                        <table
                            id="example2"
                            className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                        >
                            <thead>
                                <tr>
                                    <th>Eye</th>
                                    <th>Sphere</th>
                                    <th>Cylinder</th>
                                    <th>Axis</th>
                                    <th>Acuity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="">Right</td>
                                    <td className="">
                                        <input value={infoShow?.distance?.right?.sphere || ""}
                                            onChange={(e) => {
                                                setDistanceInfoFun("sphere", e.target.value, "right", "distance")
                                            }} type='text'>
                                        </input>
                                    </td>
                                    <td className="">
                                        <input value={infoShow?.distance?.right?.cylinder || ""}
                                            onChange={(e) => {
                                                setDistanceInfoFun("cylinder", e.target.value, "right", "distance")
                                            }} type='text'>
                                        </input>
                                    </td>
                                    <td className="">
                                        <input value={infoShow?.distance?.right?.axis || ""} onChange={(e) => {
                                            setDistanceInfoFun("axis", e.target.value, "right", "distance")
                                        }} type='text'>
                                        </input>
                                    </td>
                                    <td className="">
                                        <input value={infoShow?.distance?.right?.acuity || ""}
                                            onChange={(e) => {
                                                setDistanceInfoFun("acuity", e.target.value, "right", "distance")
                                            }} type='text'>

                                        </input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="">Left</td>
                                    <td className=""><input value={infoShow?.distance?.left?.sphere || ""}
                                        onChange={(e) => {
                                            setDistanceInfoFun("sphere", e.target.value, "left", "distance")
                                        }} type='text'></input></td>
                                    <td className="">
                                        <input value={infoShow?.distance?.left?.cylinder || ""}
                                            onChange={(e) => {
                                                setDistanceInfoFun("cylinder", e.target.value, "left", "distance")
                                            }} type='text'>
                                        </input>
                                    </td>
                                    <td className=""><input value={infoShow?.distance?.left?.axis || ""} onChange={(e) => {
                                        setDistanceInfoFun("axis", e.target.value, "left", "distance")
                                    }} type='text'></input></td>
                                    <td className=""><input value={infoShow?.distance?.left?.acuity || ""}
                                        onChange={(e) => {
                                            setDistanceInfoFun("acuity", e.target.value, "left", "distance")
                                        }} type='text'></input></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-5 vision p-1">
                        {/* <p className='p-0 m-0 title'>Vision</p> */}
                        <table
                            id="example2"
                            className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                        >
                            <thead>
                                <tr>
                                    <th className="">Right Eye</th>
                                    <th className=""></th>
                                    <th className="">Left Eye</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    otherVision?.map((value, index) => {
                                        return (
                                            <tr key={value?.id}>
                                                <td className=""><input onChange={(e) => {
                                                    setOtherVisionInfoFun("right", e.target.value, value?.value)
                                                }}
                                                    value={infoShow?.diagnosis?.hasOwnProperty(value?.value) ? infoShow?.diagnosis[value?.value]?.right : ""}
                                                    type='text'></input></td>
                                                <td className="text-center" style={{ color: "#13AC81" }}>{value?.name}</td>
                                                <td className=""><input onChange={(e) => {
                                                    setOtherVisionInfoFun("left", e.target.value, value?.value)
                                                }}
                                                    value={infoShow?.diagnosis?.hasOwnProperty(value?.value) ? infoShow?.diagnosis[value?.value]?.left : ""}
                                                    type='text'></input></td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>

                    <div className="col-7 spectacle p-1">
                        <p className='p-0 m-0 title'>Near</p>
                        <table
                            id="example2"
                            className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                        >
                            <thead>
                                <tr>
                                    <th>Eye</th>
                                    <th>Addition</th>
                                    <th>Acuity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="">Right</td>
                                    <td className=""><input value={infoShow?.near?.right?.addition || ""} onChange={(e) => {
                                        setNearInfoFun("addition", e.target.value, "right", "near")
                                    }} type='text'></input></td>
                                    <td className=""><input value={infoShow?.near?.right?.acuity || ""} onChange={(e) => {
                                        setNearInfoFun("acuity", e.target.value, "right", "near")
                                    }} type='text'></input></td>
                                </tr>
                                <tr>
                                    <td className="">Left</td>
                                    <td className=""><input value={infoShow?.near?.left?.addition || ""} onChange={(e) => {
                                        setNearInfoFun("addition", e.target.value, "left", "near")
                                    }} type='text'></input></td>
                                    <td className=""><input value={infoShow?.near?.left?.acuity || ""} onChange={(e) => {
                                        setNearInfoFun("acuity", e.target.value, "left", "near")
                                    }} type='text'></input></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className=' no-gutters mt-5 pb-2 glasses border '>
                            <p className='title'>Glasses</p>
                            <div className='row m-1'>
                                <div className="col-6">
                                    {
                                        glasses.map((value, index) => {
                                            if (index < 6)
                                                return (
                                                    <div className='d-flex' onClick={() => {
                                                        setInfo({ ...info, "eyeExamination.glasses": value })
                                                        setInfoShow({ ...infoShow, glasses: value })
                                                    }}>
                                                        <input checked={infoShow?.glasses == value ? true : false}
                                                            id={"glass" + index}
                                                            type='checkBox' onChange={() => { }}></input>
                                                        <label className='ml-1'
                                                            htmlFor={"glass" + index}>{value}</label>
                                                    </div>
                                                )
                                            else
                                                return ""
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        glasses.map((value, index) => {
                                            if (index > 5)
                                                return (
                                                    <div className='d-flex' onClick={() => {
                                                        setInfo({ ...info, "eyeExamination.glasses": value })
                                                        setInfoShow({ ...infoShow, glasses: value })
                                                    }}>
                                                        <input checked={infoShow?.glasses == value ? true : false}
                                                            id={"glass" + index}
                                                            type='checkBox' onChange={() => { }}></input>
                                                        <label className='ml-1'
                                                            htmlFor={"glass" + index}>{value}</label>
                                                    </div>
                                                )
                                            else
                                                return ""
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className="col-6">
                                <div className=''>
                                    <label className='mb-0 d-block' htmlFor='ipd'>IPD:</label>
                                    <input placeholder='mm/mm' value={infoShow?.ipd || ""}
                                        style={{ width: "200px", fontSize: "14px" }}
                                        onChange={(e) => {
                                            setInfo({ ...info, "eyeExamination.ipd": e.target.value })
                                            setInfoShow({ ...infoShow, ipd: e.target.value })
                                        }} id="ipd" type='text'></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className=''>
                                    <label className='mb-0 d-block' htmlFor='glass-name'>Glass:</label>
                                    <input value={infoShow?.glasses || ""}
                                        style={{ width: "200px", fontSize: "14px" }}
                                        onChange={(e) => {
                                            setInfo({ ...info, "eyeExamination.glasses": e.target.value })
                                            setInfoShow({ ...infoShow, glasses: e.target.value })
                                        }} id="glass-name" type='text'></input>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="  text-center mt-3 ">

                    <div className='mx-auto w-50 d-inline'>
                        <ReactToPrint
                            trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{
                                cursor: "pointer",
                                background: "#13AC81",
                                color: "white"
                            }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                            content={() => ref.current}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn submit-button d-inline mx-auto w-25"
                        onClick={() => {
                        }}
                        style={{ height: "25px" }}
                    >
                        Save
                        {isSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>

            </form>

            <div className='d-none'>
                <div ref={ref} className='full-prescription'>
                    <PrescriptionHeader
                        isHeader={isHeader}
                        info={{}} drDetails={doctorDetails || []}></PrescriptionHeader>
                    <VisionPrint visitDetails={visitDetails} info={infoShow}></VisionPrint>
                    {/* <h1>Hello</h1> */}
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default Vision
