import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const PublicRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const location = useLocation()
  return (
    !!isLoggedIn && location?.pathname != "/registration" ? <Navigate to="/" replace={true}></Navigate> : <Outlet />
  )
}
export default PublicRoute
