import React from 'react'
import './uniLoader.scss'

const UniversalLoaderForComponent = () => {
  return (
    <div className='text-center loader-component  d-flex justify-content-center align-items-center'>
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )

}

export default UniversalLoaderForComponent
