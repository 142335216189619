import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./select-with-local-search.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const SelectWithLocalSearch = ({ currentValue = '', uniqKey = '1', reset = false, isDisabled = false, label, name, options, is_mandatory, handleFormValues, placeholder, required = false }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const ref = useRef([]);
  const [show, setShow] = useState(false)
  const [value, setValue] = useState("")
  

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        show &&
        ref.current &&
        !ref.current[uniqKey].contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show])

  useMemo(() => {
    if (reset) {
      setSearchQuery('')
      setValue('')
    }
  }, [reset])

  const newOptions = useMemo(() => {
    if (searchQuery) {
      let filterList = options.filter(option => option?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
      return filterList
    }
    if (searchQuery?.length == 0) setValue('')
    return options
  }, [options, searchQuery])

  return (
    <div className='select-with-local-search' ref={(el) =>
      (ref.current[uniqKey] = el)
    }>
      {label && <label htmlFor={name} className={`d-block ${is_mandatory == 1 ? "required" : ""}`}>{label} </label>}
      <div onClick={() => {
        setShow(!show)
      }}>
        <input id={`${name}-1`} disabled={isDisabled} autoComplete='off' value={searchQuery || value || currentValue} name={name} type='text' placeholder={placeholder} required={required||false} onChange={(event) => {
          setSearchQuery(event.target.value)
        }} />
        <FontAwesomeIcon size='xs' icon={faAngleDown} className={`fa-solid fa-angle-down mt-1 my-auto ${label ? "icon-pos" : "with-out-level-icon-pos"}  `} />
      </div>
      {
        show &&
        <div className='option-section ' id={`${name}-options`}>
          {<div>
            {newOptions?.length > 0 ? newOptions?.map((option, index) => {
              return (
                <div onClick={() => {
                  setShow(!show)
                  setSearchQuery("")
                  setValue(option?.name)
                  handleFormValues(option)
                }} className='single-option ml-1' key={index}>
                  <p>{option?.name}</p>
                </div>)
            }) : <div className='single-option' >
              <p>No option found</p>
            </div>}
          </div>}
        </div>
      }

    </div>
  )
}

export default SelectWithLocalSearch
