import {del, delWithBody, post, put} from "../../utils/fetchAPI";


export const createTemplate = async (payload) => {
    const res = await post(`/doctor/template`, payload);
    return res;
};

export const templateAddSubDocument = async (docKey, payload, templateId) => {
    const res = await put(`/doctor/template/add-sub-document/${templateId}`, {[docKey]: payload});
    return res;
};

export const templateUpdateSubDocument = async (docKey, payload, templateId) => {
    payload = {...payload, key:docKey}
    const res = await put(`/doctor/template/update-sub-document/${templateId}`, payload);
    return res;
};

export const templateRemoveSubDocument = async (docKey, payload, templateId) => {
    const res = await put(`/doctor/template/remove-sub-document/${templateId}`, {[docKey]: payload});
    return res;
};

export const deleteTemplate = async (templateId, payload) => {
    const res = await delWithBody(`doctor/template/${templateId}`, payload);
    return res;
};