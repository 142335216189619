import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/slices/authSlice";
import "./registration.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Slider from "../../components/login/slider";
import { post } from "../../utils/fetchAPI";
import { Toast } from "../../utils/SwalUti";

const Registration = () => {
  const [registrationInfo, setRegistrationInfo] = useState()
  const [formError, setFormError] = useState("");
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidate, setIsValidate] = useState(true);
  let { isLoading } = useSelector((state) => state.auth);
  const handleLogin = async (e) => {
    e.preventDefault();
    // await post(`user/register`, registrationInfo)
    //   .then((res) => {
    //     if (res?.status == 200 || res?.status == 201) {
    //       Toast.fire({
    //         icon: '',
    //         title: "Successfully registered"
    //       })
    //       navigate("/login");
    //     }
    //   })
    //   .catch((rejectedValueOrSerializedError) => {

    //   });
  };

  const showPassword = () => {
    var pass = document.getElementById("pass-field");
    if (pass.type === "password") {
      setIsShow(true);
      pass.type = "text";
    } else {
      setIsShow(false);
      pass.type = "password";
    }
  };

  return (
    <div className="">
      <div className="row registration-container">
        <div className="col-md-7 col-12 d-sm-none d-md-block slider-container ">
          <Slider />
        </div>
        <div className=" col-md-5 col-12 login-form">
          <div className="login-form-container">
            {/*<div className="login-logo">*/}
            {/*  <a href="#">*/}
            {/*    <b>Shafa </b> <img src={logo} alt="shafa-care" width={40}></img>{" "}*/}
            {/*    <b>care </b>*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/* /.login-logo */}
            <div className=" ">
              <div className="">
                <div className="mb-3">
                  <h4 className="text-left mb-0 pb-0">
                    <b><span style={{ fontFamily: "Royce" }}>shafa-EHR</span></b>
                  </h4>
                  <small className="text-left">
                    Precision in Practice, Excellence in Care: Embrace Comprehensive EHR Advancement
                  </small>
                </div>
                {/* showerror message */}
                {/* {!!authError ? (
                  <span className="text-danger">{authError}</span>
                ) : null} */}
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Name <span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        required
                        type="text"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, name: e.target.value });
                        }}
                        value={registrationInfo?.name || ""}
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Email <span className="text-danger">*</span> <small>(Remember this for sign in)</small></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="email"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, email: e.target.value });
                        }}
                        value={registrationInfo?.email || ""}
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Phone <span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        pattern="(^(01){1}[3-9]{1}\d{8})$"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, phone: e.target.value });
                        }}
                        value={registrationInfo?.phone || ""}
                        placeholder="Enter your phone"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">BMDC Registration Number <span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, ['details.bmdcRegNo']: e.target.value });
                        }}
                        placeholder="BMDC Registration Number"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Gender <span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <select
                        required
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, gender: e.target.value });
                        }}
                      >
                        <option value={''}>Select</option>
                        <option value={'male'}>Male</option>
                        <option value={'female'}>Female</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <p className="text-left label pb-1 mb-1">Password <span className="text-danger">*</span> <small>(At least 6 characters)</small></p>
                    <div className="mb-3 position-relative">
                      <input
                        autoComplete="off"
                        type="password"
                        className="form-control"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, password: e.target.value });
                        }}
                        value={registrationInfo?.password || ""}
                        id="pass-field"
                        placeholder="Enter your password"
                      />
                      <span className="eye-icon p-0 m-0" onClick={showPassword}>
                        <FontAwesomeIcon
                          icon={faEye}
                          fade={isShow}
                          className="text-secondary"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Chamber name <span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, ['chamberName']: e.target.value });
                        }}
                        placeholder="Chamber name"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="text-left label pb-1 mb-1">Chamber address<span className="text-danger">*</span></p>
                    <div className="input-group  form-input">
                      <input
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setRegistrationInfo({ ...registrationInfo, ['chamberAddress']: e.target.value });
                        }}
                        placeholder="Chamber address"
                      />
                    </div>
                  </div>
                  <div className="row ">
                    {/* /.col */}
                    <div className="col-md-12 ">
                      {isLoading ? (
                        <button
                          className="btn submit-button w-100  "
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn submit-button w-100 "
                        >
                          Register
                        </button>
                      )}
                      {formError?.length !== 0 ? (
                        <small className="text-center mx-auto text-danger">
                          {formError}
                        </small>
                      ) : (
                        ""
                      )}
                      <p className="text-center mt-2">Already have an account, <span onClick={() => { navigate('/login') }} style={{ color: "#0EAC81", cursor: "pointer", fontWeight: "600" }}>Sign In</span></p>
                    </div>

                    {/* /.col */}
                  </div>
                </form>
              </div>
              <div className="text-center login-form-footer">
                <small className="">
                  Copyright © 2014-2021 <strong>Shafa Care.</strong> All rights
                  reserved.
                </small>
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
