import React from 'react'
import "./ot-note.scss"

const OtNotePreview = ({ previewInfo, visitDetails, patientDetails, fontSize = "15px", className = "ot-preview" }) => {

    return (
        <div className={className} >
            {/* <div style={{ backgroundColor: "#0FAC81", color: "white", fontSize: "14px", fontWeight: "600" }} className='py-0 my-0 px-1'>Ot note</div> */}
            <div className='details' >
                <div className='d-flex info-set'>
                    <p className='title'>Name :</p>
                    <p className='value'>{visitDetails?.patientName || patientDetails?.name}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Gender :</p>
                    <p className='value'>{visitDetails?.patientGender || patientDetails?.gender}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Date of birth :</p>
                    <p className='value'>{visitDetails?.patientDob?.slice(0, 10) || patientDetails?.dob?.slice(0, 10)}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Date of Admission :</p>
                    <p className='value'>{previewInfo?.dateOfAdmission?.slice(0, 10)}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Date of Operation :</p>
                    <p className='value'>{previewInfo?.dateOfOperation?.slice(0, 10)}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Date of Discharge :</p>
                    <p className='value'>{previewInfo?.dateOfDischarge?.slice(0, 10)}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Diagnosis :</p>
                    <p className='value'>{previewInfo?.diagnosis}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Operations Notes :</p>
                    <p className='value'>{previewInfo?.operationsNotes}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Result at Discharge :</p>
                    <p className='value'>{previewInfo?.resultAtDischarge}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>IOL That Implanted  :</p>
                    <p className='value'>{previewInfo?.IOLThatImplanted}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Surgeon :</p>
                    <p className='value'>{previewInfo?.surgeon}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Assistant Surgeon :</p>
                    <p className='value'>{previewInfo?.assistantSurgeon}</p>
                </div>
                <div className='d-flex info-set'>
                    <p className='title'>Assistants :</p>
                    <p className='value'>{previewInfo?.assistants}</p>
                </div>

            </div>
        </div>
    )
}

export default OtNotePreview
