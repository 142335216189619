import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import "./appointment-create-form.scss";
import { appointmentCreate } from "../../service/api/appointment-api";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getLocations } from "../../store/slices/locationSlice";
import SelectWithLocalSearch from "../common/updated-input/select-with-local-search/select-with-local-search";
import { post, put } from "../../utils/fetchAPI";
import { Toast } from "../../utils/SwalUti";

const AppointmentDetailsShow = ({
  selectedAppointmentInfo,
  appointmentUpdatedValue,
  setAppointmentUpdatedValue,
  reset,
  setActionModal,
  setSelectedAppointment,
}) => {
  const [info, setInfo] = useState({ appointmentDate: new Date().toJSON() });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState({})


  const maritalStatusOptions = [
    {
      id: 1,
      name: "Married",
      value: "married"
    },
    {
      id: 2,
      name: "Unmarried",
      value: "unmarried"
    },
  ]
  
  useMemo(() => {
    if (selectedAppointmentInfo?.type) {
      setAppointmentUpdatedValue({
        type: selectedAppointmentInfo?.type || "",
        status: selectedAppointmentInfo?.status || ""
      })
    }
  }, [selectedAppointmentInfo?.type])



  const onSubmit = async (values, { resetForm }) => {
    values = appointmentUpdatedValue
    setIsSubmitting(true);
    await put(`appointment/update/${selectedAppointmentInfo?._id}`, values)
      .then((res) => {
        if (res.status === 200) {
          updatePatient(info, selectedAppointmentInfo?.patientId)
          setIsSubmitting(false);
          reset()
          setActionModal(false)
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  };
  const setInfoFun = (key, value) => {
    let temp = { ...info };
    setInfo({ ...temp, [key]: value });
  };

  const updatePatient = async (values, patientId) => {
    await post(`/patient/update/${patientId}`, values)
      .then((res) => {
        if (res.status === 200) {

          Toast.fire({
            icon: '',
            title: "Updated successfully"
          })
        }
      })
      .catch((error) => {
      });
  }


  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        gender: "",
        dob: "",
        bloodGroup: "",
        maritalStatus: ""
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <div className="appointment-create-form-section pb-0">
          <Form
            action=""
            id=""
            className="custom-form input-section position-relative"
            encType="multipart/form-data"
          >
            <div className="row mt-0 pt-0">
              <p className="mx-2 mt-0 pt-0 ml-2 border-bottom" style={{ fontSize: "14px", fontWeight: "600" }}>Appointment information</p>
              <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                  Name :
                </label>
                <input
                  required
                  className="form-control input py-0 my-0"
                  autoComplete="off"
                  type="text"
                  id="name"
                  name="name"
                  value={selectedAppointmentInfo?.patientName || ""}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setInfoFun("name", e.target.value);
                    setSelectedAppointment({ ...selectedAppointmentInfo, patientName: e.target.value })
                    setAppointmentUpdatedValue({ ...appointmentUpdatedValue, patientName: e.target.value })
                  }}
                />
                <br />
              </div>
              <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                  Patient Id :
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="string"
                  id="age"
                  name="age"
                  disabled
                  placeholder="Patient id"
                  value={selectedAppointmentInfo?.patient?.patientId || ""}
                  onChange={(e) => {
                    // setInfoFun("patientId", e.target.value);
                  }}
                />
              </div>
              <div className="col-12  d-flex my-0 py-0 mx-1 mb-1" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                  Phone Number:
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  pattern="(^(01){1}[3-9]{1}\d{8})$"
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  disabled
                  value={selectedAppointmentInfo.patientPhone || ""}
                  onChange={(e) => {
                    setInfoFun("phone", e.target.value);
                    setSelectedAppointment({ ...selectedAppointmentInfo, patientPhone: e.target.value })
                    setAppointmentUpdatedValue({ ...appointmentUpdatedValue, patientPhone: e.target.value })
                  }}
                />
                <br />
                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
              </div>


              <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                  Date of birth:
                </label>
                <input
                  className="form-control input"
                  autoComplete="off"
                  type="date"
                  id="dob"
                  name="dob"

                  placeholder="Enter appointmentDate"
                  value={selectedAppointmentInfo?.patientDob?.slice(0, 10) || ""}
                  onChange={(e) => {
                    setInfoFun("dob", e.target.value);
                    setSelectedAppointment({ ...selectedAppointmentInfo, patientDob: e.target.value })
                    setAppointmentUpdatedValue({ ...appointmentUpdatedValue, patientDob: e.target.value })
                  }}
                />
                <br />
              </div>
              <div className="col-12 mb-1" style={{ height: '30px' }}>
                <div className="mx-1 d-flex">
                  <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '29%' }}>
                    Gender:
                  </label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{ fontSize: "20px" }}
                    className="d-flex px-0 mx-0"
                  >
                    <label className=" d-flex mr-3">
                      <input
                        checked={
                          selectedAppointmentInfo?.patientGender?.toLowerCase() === "male" ? true : false
                        }

                        type="radio"
                        name="gender"
                        value="Male"
                        onClick={() => {
                          setInfoFun("gender", "Male");
                          setSelectedAppointment({ ...selectedAppointmentInfo, patientGender: 'Male' })
                          setAppointmentUpdatedValue({ ...appointmentUpdatedValue, patientGender: "Male" })
                        }}
                      />
                      <span className="ml-2 mt-1">Male</span>
                    </label>
                    <label className="m-0 p-0 d-flex">
                      <input
                        checked={
                          selectedAppointmentInfo?.patientGender?.toLowerCase() === "female"
                            ? true
                            : false
                        }
                        type="radio"
                        name="gender"
                        value="Female"
                        onClick={() => {
                          setInfoFun("gender", "Female");
                          setSelectedAppointment({ ...selectedAppointmentInfo, patientGender: 'Female' })
                          setAppointmentUpdatedValue({ ...appointmentUpdatedValue, patientGender: "Female" })
                        }}
                      />
                      <span className="ml-2 mt-1">Female</span>
                    </label>
                  </div>
                </div>
              </div>

              {user?.user?.type?.toLowerCase() === "staff" && <div className="col-12 mb-0 pb-0 d-flex mx-1 mb-2" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                  Doctor:
                </label>
                <div className="w-100" key={2}>
                  <input className="w-100" disabled type="text" value={selectedAppointmentInfo?.doctorName}></input>
                  {/* <SelectWithLocalSearch required={true} currentValue={selectedAppointmentInfo?.doctorName} isDisabled={selectedLocation?.doctors?.length ? false : true} name="doctor" handleFormValues={(doctor) => {
                    setInfoFun("doctorId", doctor?._id);
                  }} isCreatable={false} options={selectedLocation?.doctors || []} placeHolder='Select doctor'></SelectWithLocalSearch> */}
                </div>
                <br />
              </div>}
              {/* <div className="col-12 mx-1 d-flex mb-2" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                  Appointment Date:
                </label>
                <input
                  required
                  className="form-control input"
                  autoComplete="off"
                  type="date"
                  id="appointmentDate"
                  name="appointmentDate"
                  placeholder="Enter appointmentDate"
                  value={info?.appointmentDate?.slice(0, 10)}
                  onChange={(e) => {
                    setInfoFun("appointmentDate", e.target.value);
                  }}
                />
                <br />
              </div> */}
              <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                  Status :
                </label>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setAppointmentUpdatedValue({ ...appointmentUpdatedValue, "status": e.target.value })
                  }}
                  value={appointmentUpdatedValue?.status || ""}
                >
                  <option value={'present'}>Present </option>
                  <option value={'pending'}> Pending</option>
                  <option value={'canceled'}>Canceled </option>
                </select>{" "}
                <br />
              </div>
              <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                  Appointment date :
                </label>
                <input
                  required
                  className="form-control input py-0 my-0"
                  autoComplete="off"
                  type="date"
                  value={appointmentUpdatedValue?.date?.slice(0, 10) || selectedAppointmentInfo?.date?.slice(0, 10) || ""}
                  placeholder="Enter Name"
                  //   defaultValue={info?.name}
                  onChange={(e) => {
                    setAppointmentUpdatedValue({ ...appointmentUpdatedValue, "date": e.target.value })
                  }}
                />
                <br />
              </div>
              <div className="col-12 " style={{ height: '30px' }}>
                <div className="d-flex mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '26%' }}>
                    Appointment type:
                  </label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    style={{ fontSize: "20px" }}
                    className="d-flex"
                  >
                    <label className="mx-3 m-0 p-0 d-flex">
                      <input
                        checked={
                          appointmentUpdatedValue?.type?.toLowerCase() == "new"
                            ? true
                            : false
                        }
                        type="radio"
                        name="appointmentType"
                        value="new"
                        onClick={() => {
                          setAppointmentUpdatedValue({ ...appointmentUpdatedValue, "type": "new" })
                        }}
                      />
                      <span className="ml-2 mt-1">New</span>
                    </label>
                    <label className="m-0 p-0 ml-3 d-flex">
                      <input
                        checked={
                          appointmentUpdatedValue?.type?.toLowerCase() === "old"
                            ? true
                            : false
                        }
                        type="radio"
                        name="appointmentType"
                        value="old"
                        onClick={() => {
                          setAppointmentUpdatedValue({ ...appointmentUpdatedValue, "type": "old" })
                        }}
                      />
                      <span className="ml-2 mt-1">Old</span>
                    </label>
                    <label className="m-0 p-0 ml-4 d-flex">
                      <input
                        checked={
                          appointmentUpdatedValue?.type?.toLowerCase() === "followup"
                            ? true
                            : false
                        }
                        type="radio"
                        name="appointmentType"
                        value="followUp"
                        onClick={() => {
                          setAppointmentUpdatedValue({ ...appointmentUpdatedValue, "type": "followUp" })
                        }}
                      />
                      <span className="ml-2 mt-1">Follow Up</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12  text-center mt-3 d-flex">
                <button
                  type="submit"
                  className="btn submit-button submit-button-position mx-auto w-25"
                  onClick={() => { }}
                >
                  Save
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default AppointmentDetailsShow;
