import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWithParams } from "../../utils/fetchAPI";

const initialState = {
  allStaffs: [],

  pagination: {
    currentPage: 1,
  },
 
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noStaffFound: false,
};
export const getStaffs = createAsyncThunk(
  "staff/getStaffs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`staff/list`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetAllStaffs: (state, action) => {
      state.allStaffs = [];
      state.pagination = {
        current_page: 1,
      };
      state.isLoading = false;
      state.isOperationLoading = false;
      state.error = null;
      state.noStaffFound = false;
    },
  },
  extraReducers: {
    [getStaffs.pending]: (state, action) => {
      // if (action?.meta?.arg?.current_page===1) state.isLoading = true;
      state.isLoading = true;
      state.error = null;
      state.noStaffFound = false;
    },
    [getStaffs.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allStaffs = action.payload;
      if (action.payload?.staffs?.length === 0) {
        state.noStaffFound = true;
      }
      let { allStaffs, ...rest } = action.payload;
      state.pagination = rest;
      state.error = null;
    },
    [getStaffs.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    },

  },
});

export default staffSlice.reducer;
export const { resetAllStaffs } = staffSlice.actions;
