import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWithParams } from "../../utils/fetchAPI";

const initialState = {
  allAppointments: [],
  waitingList: [],
  pagination: {
    currentPage: 1,
  },
  waitingListPagination: {
    currentPage: 1,
  },
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noAppointmentFound: false,
};
export const getAppointments = createAsyncThunk(
  "appointment/getAppointments",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`appointment/list`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getWaitingList = createAsyncThunk(
  "appointment/getWaitingList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWithParams(
        `appointment/waiting-room/list`,
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    resetAllAppointments: (state, action) => {
      state.allAppointments = [];
      state.pagination = {
        current_page: 1,
      };
      state.isLoading = false;
      state.isOperationLoading = false;
      state.error = null;
      state.noAppointmentFound = false;
    },
    resetWaitingList: (state, action) => {
      state.waitingList = [];
      state.pagination = {
        current_page: 1,
      };
      state.isLoading = false;
      state.isOperationLoading = false;
      state.error = null;
      state.noAppointmentFound = false;
    },
  },
  extraReducers: {
    [getAppointments.pending]: (state, action) => {
      // if (action?.meta?.arg?.current_page===1) state.isLoading = true;
      state.isLoading = true;
      state.error = null;
      state.noAppointmentFound = false;
    },
    [getAppointments.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allAppointments = action.payload;
      if (action.payload?.appointments?.length === 0) {
        state.noAppointmentFound = true;
      }
      let { allAppointments, ...rest } = action.payload;
      state.pagination = rest;
      state.error = null;
    },
    [getAppointments.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    },

    // waiting list
    [getWaitingList.pending]: (state, action) => {
      // if (action?.meta?.arg?.current_page===1) state.isLoading = true;
      state.isLoading = true;
      state.error = null;
      state.noAppointmentFound = false;
    },
    [getWaitingList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.waitingList = action.payload;
      if (action.payload?.appointments?.length === 0) {
        state.noAppointmentFound = true;
      }
      let { waitingList, ...rest } = action.payload;
      state.waitingListPagination= rest;
      state.error = null;
    },
    [getWaitingList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    },
  },
});

export default appointmentSlice.reducer;
export const { resetAllAppointments } = appointmentSlice.actions;
