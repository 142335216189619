import React from 'react'
import { useSelector } from 'react-redux';
import "./patient.scss"

const PatientComplaint = () => {
    let { visits, isVisitLoading } = useSelector(
        (state) => state.visit
    );
    return (
        <div>
            {
                visits?.length > 0 && visits?.map((visit, index) => {
                    let info = visit?.eyeExamination
                    if (info)
                        return (<div className='patient--complaint-section border mb-2'>
                            <p style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }} className='title p-0 m-0 px-1'>Appointment date: {visit?.appointmentDate?.slice(0, 10)}</p>
                            {<div>
                                <h1>Complaint</h1>
                            </div>}
                        </div>)
                })
            }

        </div>
    )
}

export default PatientComplaint
