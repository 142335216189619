import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PublicRoute from "./public-route";
import PrivateRoute from "./private-route";
import Login from "../pages/user-login/login";
import Home from "../pages/home/home";
import Appointment from "../pages/appointment/appointment";
import PageNotFound from "../pages/page-not-found/page-not-found";
import WaitingRoom from "../pages/waiting-room/waiting-room";
import Profile from "../pages/profile/profile";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import AppointedIndividualPatient from "../pages/appointment/appointed-individual-patient/appointed-indivual-patient";
import Template from "../pages/template/template";
import IndividualPatientDetails from "../pages/patient/individual-patient-details/individual-patient";
import Staff from "../pages/staff/staff";
import PatientPrescription from "../pages/patient-prescription/patient-prescription";
import Registration from "../pages/user-login/registration";
import { SmsPortal } from "../pages/sms-portal/sms-portal";


const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/registration" element={<Registration />}></Route>
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/appointment/:visitId" element={<AppointedIndividualPatient />} />
          <Route path="/waiting-room" element={<WaitingRoom />} />
          <Route path="/waiting-room/:visitId" element={<AppointedIndividualPatient />} />
          {/*<Route path="/waiting-room/:visitId" element={<IndividualPatient />} />*/}
          <Route path="/patient/details/:patientId" element={<IndividualPatientDetails />} />
          <Route path="/template" element={<Template />} />
          <Route path="/setting" element={<Appointment />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/my-profile" element={<Profile />} />
          <Route path="/*" element={<SmsPortal/>} />
          <Route path="/*" element={<PageNotFound />} />
          SmsPortal
          {/* <Route path="/prescription/:patientId/:visitId" element={<PatientPrescription/>} /> */}
        </Route>
        <Route path="/prescription/:visitId" element={<PatientPrescription />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
