import React from "react";
import Modal from "react-bootstrap/Modal";
import "./custom-modal.scss";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const CustomModal = ({
  children,
  show,
  setShow,
  size = "md",
  customClass,
  autoClose = false,
  isHeaderHide = false
}) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        if (autoClose) setShow(false);
      }}
      centered
      dialogClassName="modal-900w"
      aria-labelledby="example-custom-modal-styling-title"
      size={size}
      className={`${customClass} `}
    >
      <Modal.Body className="custom-body default p-0 m-0" >
        {!isHeaderHide ? <div style={{background:"#15AA81",borderRadius:"3px 3px 0px 0px"}} className="d-flex justify-content-end ml-auto mt-0 pt-0 px-1" onClick={() => setShow(false)}>
          <small style={{color:"white",fontWeight:"700",cursor:"pointer"}} className="close-button" >
            Close
          </small>
        </div> : ""}
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
