import { del, post, put } from "../../utils/fetchAPI";


export const appointmentCreate = async (payload) => {
  const res = await post("appointment/create", payload);
  return res;
};

export const staffCreate = async (payload) => {
  const res = await post("staff/create", payload);
  return res;
};

export const appointmentStatusChange = async (id, payload) => {
  const res = await put(`appointment/update/status/${id}`, payload);
  return res;
};

export const createVisit = async (appointmentId) => {
  const res = await post(`appointment/create-visit/${appointmentId}`);
  return res;
};


export const deleteAppointment = async (appointmentId) => {
  const res = await del(`appointment/remove/${appointmentId}`);
  return res;
};

export const deleteStaff = async (staffId) => {
  const res = await del(`staff/remove/${staffId}`);
  return res;
};
  // staff/remove/:staffId
