import React, { useState } from "react";
import "./patient.scss";
import HorizontalTab from "../../components/common/tabs/horizontal-tab/horizontal-tab";
import PatientCreateForm from "../../components/patient/form/patient-create-form";
import PatientSearch from "../../components/common/patient-search/patient-serach";
import FormInfo from "../../components/patient/form/form-info";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAllPatients } from "../../store/slices/patientSlice";


const Patient = ({ setShow }) => {
  const [selectedTabOption, setSelectedTabOption] = useState(1);
  const [isNewUser, setIsNewUser] = useState(true);
  const [singlePatientInfo, setSinglePatientInfo] = useState({});
  const dispatch = useDispatch();
  const patientInfoTabOption = [
    {
      name: !isNewUser ? "Patient Home" : "Registration form",
      id: 1,
    },
    {
      name: "Visit History",
      id: 2,
    },
    {
      name: "Medical History",
      id: 3,
    },
    {
      name: "Payment History",
      id: 4,
    },
  ];
  const navigate = useNavigate()
  const navigateToPatientDetails = (info) => {
    resetSearch()
    setShow(false)
    navigate(`/patient/details/${info?._id}`)
  }

  const resetSearch = () => {
    dispatch(resetAllPatients());
  }

 

  return (
    <div
      className="main-content  patient-container p-2"
      id="parent-div"
    >
      <div className="">
        <div className=" border">
          <PatientSearch
            setSelectedTabOption={setSelectedTabOption}
            setIsNewUser={setIsNewUser}
            setSinglePatientInfo={setSinglePatientInfo}
            singlePatientInfo={singlePatientInfo}
            selectedTabOption={selectedTabOption}
            height={37}
          ></PatientSearch>
        </div>
        <div className="text-center">
          <button onClick={() => { navigateToPatientDetails(singlePatientInfo) }} className="btn mr-2" disabled={!singlePatientInfo?.name ? true : false}>View Patient</button>
          <button onClick={() => {
            resetSearch()
            setShow(false)
          }} className="btn">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Patient;
