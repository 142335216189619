import { post, put } from "../../utils/fetchAPI";

export const mergeTemplateToPrescription = async (payload, visitId) => {
  const res = await post(`/visit/add-template/${visitId}`, payload);
  return res;
};


export const prescriptionAddSubDocument = async (objKey, payload, visitId) => {
  const res = await put(`/visit/add-sub-document/${visitId}`, { [objKey]: payload });
  return res;
};


export const prescriptionAddSubDocumentArray = async (objKey, payload, visitId) => {
  const res = await put(`/visit/add-sub-documents/${visitId}`, { data: payload, documentKey: objKey });
  return res;
};

export const prescriptionUpdateSubDocument = async (objKey, payload, visitId) => {
  payload = { ...payload, key: objKey }
  const res = await put(`/visit/update-sub-document/${visitId}`, payload);
  return res;
};

export const prescriptionRemoveSubDocument = async (objKey, payload, visitId) => {
  const res = await put(`/visit/remove-sub-document/${visitId}`, { [objKey]: payload });
  return res;
};


