import React from "react";
import slider_1 from "../../assets/images/login-slider/dashboard-rounded-corner-screen-shoot-1000.png";
import slider_2 from "../../assets/images/login-slider/intro-screen-1000.png";
import Carousel from "react-bootstrap/Carousel";
const Slider = () => {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <div className="carousel-img">
            <img className="d-block  " src={slider_1} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3 className="carousel-font"><span style={{ fontFamily: "Royce" }}>shafa-EHR</span> Comprehensive EHR</h3>
            <p className="carousel-font">
              Seamless Care, Comprehensive Solutions: Transforming Healthcare with Our EHR Excellence.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-img">
            <img className="d-block  " src={slider_2} alt="First slide" />
          </div>

          <Carousel.Caption>
            <h3 className="carousel-font"> Empowering Healthcare Excellence</h3>
            <p className="carousel-font">
              Innovate, Integrate, Illuminate: Comprehensive EHR Leading the Healthcare Revolution.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item>
          <div className="carousel-img">
            <img className="d-block  " src={slider_1} alt="First slide" />
          </div>

          <Carousel.Caption>
            <h3 className="carousel-font">Third slide label</h3>
            <p className="carousel-font">
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
};

export default Slider;
