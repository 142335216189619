import React from 'react'
import './vision-print.scss'

const VisionHistory = ({ info = {}, maxWidth = "310px" }) => {
    return (
        <div className='mr-2' style={{ maxWidth: maxWidth }}>
            {/* <p className='mx-1 mb-0 ' style={{ fontSize: "13px", fontWeight: "600" }}>Date:{info?.updatedAt?.slice(0, 10)}</p> */}
            <div className="spectacle p-1 vision-history ">
                {(info?.aided?.left || info?.aided?.right) && <div className='pb-0 mb-0'>
                    <p className='py-0 my-0'>Visual Acuity: Aided</p>
                    <p>Right Eye: {info?.aided?.left || ""}  Left Eye: {info?.aided?.right || ""}</p>
                </div>}
                {(info?.unaided?.left || info?.unaided?.right) && <div className='pb-0 mb-0'>
                    <p className='py-0 my-0'>Visual Acuity: Unaided</p>
                    <p>Right Eye: {info?.unaided?.left}  Left Eye: {info?.unaided?.right}</p>
                </div>}
                {(info?.pinHole?.left || info?.pinHole?.right) && <div className='pb-0 mb-0'>
                    <p className='py-0 my-0'>Visual Acuity: Pin Hole</p>
                    <p>Right Eye: {info?.pinHole?.left || ""}  Left Eye: {info?.pinHole?.right || ""}</p>
                </div>}

                {
                    info?.diagnosis && Object.keys(info?.diagnosis).map((exam, index) => (
                        <div key={index} className='d-flex py-9 my-0'>
                            <p className='py-0 my-0 pr-1'>{exam?.toUpperCase()}:</p>

                            {Object.keys(info?.diagnosis[exam]).map((side, sideIndex) => (
                                <p key={sideIndex} className='pr-2  py-9 my-0'>
                                    {side == "left" ? "OS" : "OD"}: {info?.diagnosis[exam][side]}
                                </p>
                            ))}

                        </div>
                    ))
                }

                <p className='p-1 mt-1 m-0 title border  text-center'>Spectacle (Distance)</p>
                <table
                    id="example2"
                    className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                >
                    <thead>
                        <tr>
                            <th>Eye</th>
                            <th> Sphere</th>
                            <th>Cylinder</th>
                            <th>Axis</th>
                            <th>Acuity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="">Right </td>
                            <td className="">{info ? info?.distance?.right?.sphere : ""}</td>
                            <td className="">{info ? info?.distance?.right?.cylinder : ""}</td>
                            <td className="">{info ? info?.distance?.right?.axis : ""}</td>
                            <td className="">{info ? info?.distance?.right?.acuity : ""}</td>
                        </tr>
                        <tr>
                            <td className="">Left </td>
                            <td className="">{info ? info?.distance?.left?.sphere : ""}</td>
                            <td className="">{info ? info?.distance?.left?.cylinder : ""}</td>
                            <td className="">{info ? info?.distance?.left?.axis : ""}</td>
                            <td className="">{info ? info?.distance?.left?.acuity : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="spectacle vision m-1">
                <p className='p-1 m-0 title border text-center'>Near</p>
                <table
                    id="example2"
                    className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}"
                >
                    <thead>
                        <tr>
                            <th>Eye</th>
                            <th> Addition</th>
                            <th>Acuity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="">Right </td>
                            <td className="">{info ? info?.near?.right?.addition : ""}</td>
                            <td className="">{info ? info?.near?.right?.acuity : ""}</td>
                        </tr>
                        <tr>
                            <td className="">Left </td>
                            <td className="">{info ? info?.near?.left?.addition : ""}</td>
                            <td className="">{info ? info?.near?.left?.acuity : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mx-1'>
                <p style={{ fontSize: "13px" }} className='py-0 my-0'>IPD: {info ? info?.ipd : ""}</p>
                <p style={{ fontSize: "13px" }}>Glasses: {info ? info?.glasses : ""}</p>
            </div>
        </div>
    )
}

export default VisionHistory
