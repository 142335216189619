import React, { useState } from 'react'
import './physical-exam.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { put } from '../../../utils/fetchAPI';

const TemplateAdd = ({ setNewTemplate, setTemplateShow, doctorPhysicalExamList, visitDetails, templateList, setDoctorPhysicalExamList, setSelectedPhysicalExam, setSelectedTemplates }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitValue, setSubmitValue] = useState({})
    const handleTemplateCreate = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        await put(`/physical-exam/add-template/${templateList?._id}`, { templates: submitValue })
            .then((res) => {
                if (res?.status === 200) {
                    setSelectedPhysicalExam(res?.data)
                    let checkIndex = doctorPhysicalExamList?.findIndex(obj => obj?._id === res?.data?._id);
                    if (checkIndex != -1) {
                        let updatedList = [
                            ...doctorPhysicalExamList.slice(0, checkIndex), // elements before the found index
                            res?.data, // your new element
                            ...doctorPhysicalExamList.slice(checkIndex + 1), // elements after the found index
                        ];
                        setDoctorPhysicalExamList(updatedList)
                    }
                    setNewTemplate(false)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsSubmitting(false)
            })
            .finally(() => {
                setIsSubmitting(false)
            }
            );
    }

    return (
        <div className='new-template-section p-0 m-0'>
            <div className='p-0 m-0 form-title d-flex justify-content-between'>
                <p onClick={(e) => { setNewTemplate(false) }} className='p-0 m-0'> <FontAwesomeIcon className="mx-1 my-0" icon={faArrowCircleLeft} style={{ cursor: "pointer", color: "#13AC81" }} /> Back</p>
            </div>
            <form
                onSubmit={(e) => handleTemplateCreate(e)}
                action=""
                id=""
                className="custom-form"
                encType="multipart/form-data"
            >
                <div className=" my-0 py-0 px-2 mb-1">
                    <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '30%' }}>
                        Name:
                    </label>
                    <input
                        className="form-control"
                        autoComplete="off"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter template name"
                        value={submitValue?.name || ""}
                        onChange={(e) => { setSubmitValue({ ...submitValue, name: e.target.value }) }}
                    />
                </div>
                <div className=" my-0 py-0 px-2 mb-1">
                    <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '30%' }}>
                        Description:
                    </label>
                    <textarea
                        autoComplete="off"
                        id={`desc`}
                        className={`w-100`}
                        // data-multi-title={"check"}
                        placeholder={"Enter description"}
                        value={submitValue?.desc || ""}
                        onChange={(e) => { setSubmitValue({ ...submitValue, desc: e.target.value }) }}
                        rows={7}
                    ></textarea>
                </div>
                <div className='text-center'>
                    <button disabled={isSubmitting} className='btn btn-sm px-3 submit-btn py-0 mx-2' type="submit">{!isSubmitting && "Create"}
                        {isSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TemplateAdd
