import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PublicPrescriptionPrint from '../../components/prescription/prescription-print/public-prescription-print';

const PatientPrescription = () => {
    const params = useParams();
    const [visitId] = useState(params?.visitId || "");
    const printRef = useRef()
    return (
        <div className='container'>
            <div className="mt-0 pt-0 d-flex justify-content-between mb-1">
                {/* <ReactToPrint
                    trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                    content={() => printRef.current}
                /> */}
                {/* <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p> */}
            </div>
            <div ref={printRef} className='border' style={{height:"100vh"}}>
                <PublicPrescriptionPrint visitId={visitId}></PublicPrescriptionPrint>
            </div>
        </div>
    )
}

export default PatientPrescription
