import React, { useEffect, useRef, useState } from "react";
import "./vertical-tab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const VerticalTab = ({
  backUrl,
  options,
  tabTitle,
  selectedOption,
  setSelectedOption,
  selectedSubOption,
  setSelectedSubOption,
}) => {

  const navigate = useNavigate();
  const setOptionFun = (option) => {
    setSelectedOption(option);
    setSelectedSubOption({ parentId: "", subId: "" });
    const element = document?.getElementById(`sub-${option?.id}`);
    const titleIcon = document.getElementById(`title-icon-${option?.id}`);
    if (element && option?.sub) {
      if (element.classList.contains("d-none")) {
        element.classList.remove("d-none");
        element.classList.add("d-block");
        titleIcon.classList.add("rotate-180");
      } else {
        element.classList.add("d-none");
        element.classList.remove("d-block");
        titleIcon.classList.remove("rotate-180");
      }
    }
  };


  return (
    <div className="vertical-tab-section">
      {/* <div className="d-flex align-content-center justify-content-center">
        {backUrl ? (
          <div className="text-center">
            <Link to={backUrl}>
              <FontAwesomeIcon
                size="sm"
                className=""
                style={{marginTop:"5px",color:"#0fac81"}}
                icon={faChevronCircleLeft}
              />
            </Link>
          </div>
        ) : (
          ""
        )}
        <p className="text-center pt-3 pl-2">
          {tabTitle ? tabTitle : "Tab options"}
        </p>
      </div> */}
      {options?.map((option, index) => {
        return (
          <div key={index}>
            {index == 0 && < p className="my-0 py-0 px-2" style={{fontWeight:"700",background:"#12AB81",color:"white",fontSize:"13px"}}>Prescription</p>}
            <div
              className={`vertical-tab-options ${selectedOption?.id === option?.id ? "active-option" : ""
                }`}
            >
              <p
                className={`d-flex justify-content-between px-2 m-0 `}
                onClick={() => {
                  let params = new URLSearchParams(window.location.search);
                  params.set('id', option?.id);
                  window.history.replaceState(null, '', `?${params.toString()}`);
                  setOptionFun(option)
                }}
              >
                <span>{option.name}</span>{" "}
                {!option?.sub?.length && selectedOption?.id === option?.id ? (
                  <FontAwesomeIcon
                    id={`title-icon-${option?.id}`}
                    size="xs"
                    className="mt-2"
                    icon={faChevronRight}
                  />
                ) : (
                  ""
                )}
                {option?.sub?.length ? (
                  <FontAwesomeIcon
                    id={`title-icon-${option?.id}`}
                    size="xs"
                    className="mt-2"
                    icon={faChevronDown}
                  />
                ) : (
                  ""
                )}
              </p>
            </div>
            {index == 6 && < p className="my-0 py-0 px-2 mt-2" style={{fontWeight:"700",background:"#12AB81",color:"white",fontSize:"13px"}}>Others</p>}
            <div className="d-none m-0 p-0" id={`sub-${option?.id}`}>
              {option?.sub?.map((subOption, i) => {
                return (
                  <div
                    className={`sub-options border-bottom d-flex justify-content-between border-1 m-0 p-0 ${selectedSubOption?.parentId == option?.id &&
                      selectedSubOption?.subId === subOption?.id
                      ? "active-option"
                      : ""
                      }`}
                    key={i}
                    onClick={() => {
                      setSelectedSubOption({
                        parentId: option?.id,
                        subId: subOption?.id,
                      });
                      setSelectedOption(option);
                    }}
                  >
                    <small className="pr-2 pl-3 mb-0 pb-0">
                      {subOption?.name}
                    </small>
                    {selectedSubOption?.parentId == option?.id &&
                      selectedSubOption?.subId === subOption?.id ? (
                      <FontAwesomeIcon
                        id={`title-icon-${option?.id}`}
                        size="xs"
                        className="mt-1 px-2"
                        icon={faChevronRight}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div >
  );
};

export default VerticalTab;
