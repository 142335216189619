import React from 'react';
import './dashboard-component.scss'
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = ({ barChartData }) => {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const d = new Date();
    let currentMonth = month[d.getMonth()];
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Daily appointments',
            },
        },
        height:"100px"
    };



    const data = {
        labels: barChartData?.dates,
        datasets: [
            // {
            //   label: 'Dataset 1',
            //   data: [1, 23, 4, 5, 6, 7, 8,1, 23, 4, 5, 6, 7, 8],
            //   backgroundColor: 'rgba(255, 99, 132, 0.5)',
            // },
            {
                label: "Daily appointment count ( " + currentMonth + " )",
                data: barChartData?.values,
                backgroundColor: '#0fac81',
            },
        ],
    };
    return (

        <Bar style={{ height: "400px" }} options={options} data={data} />

    );
};

export default BarChart;
