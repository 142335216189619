import React, { useEffect, useRef, useState } from 'react'
import "./search-select.scss"
const SearchSelect = ({ uniqKey, borderColor = 'rgba(73, 175, 47, 0.21)', placeHolder = "search", options, setOptionFun, handleSubmit, isSearching }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const ref = useRef([]);
  const [show, setShow] = useState(false)
  const passSearchQuery = ((event) => {
    setOptionFun(event)
  })

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        show &&
        ref.current &&
        !ref.current[uniqKey].contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show])
  return (
    <div className='search-select' ref={(el) => (ref.current[uniqKey] = el)}  >
      <input onClick={() => setShow(true)} style={{ borderColor: borderColor }} value={searchQuery || ""} type='text' placeholder={placeHolder} onChange={(event) => {
        setSearchQuery(event.target.value)
        passSearchQuery(event)
      }} />
      <div className='option-section'>
        {show && options?.length > 0 && <div className={show && options?.length > 0 && "fixed-height"}>
          {options ? options?.map((option, index) => {
            return (<div className='single-option border-bottom' key={index} onClick={() => {
              setShow(!show)
              setSearchQuery('')
              handleSubmit(option)

            }}>
              <p className='text-wrap'>{option?.title || option?.name || option?.details}</p>
            </div>)
          }) : ""}
        </div>}
        {/* {isSearching && <p className='px-1'>Searching...</p>} */}
        {show && options?.length === 0 && searchQuery.length > 1 ? <div className='d-flex single-option'>
          <p>No option found</p>
        </div> : ""}
      </div>
    </div >
  )
}

export default SearchSelect
