import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SelectWithLocalSearch from '../common/updated-input/select-with-local-search/select-with-local-search';
import './user-profile.scss'
import { removeAuthInfo } from '../../store/slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import ImageUpload from '../common/updated-input/image-upload/ImageUpload';
const EducationalInfo = ({ selectedOption, setInfoFun, setInfo, info, handleSubmit, setSignatureImg, signatureImg, backgroundImage, setBackgroundImage }) => {
    const { user } = useSelector((state) => state.auth);
    let dispatch = useDispatch()
    const [signUrl, setSignUrl] = useState("")
    const [backGround, setBackGround] = useState("")

    const maritalStatusOptions = [
        {
            id: 1,
            name: "Married",
            value: "married"
        },
        {
            id: 2,
            name: "Unmarried",
            value: "unmarried"
        },
    ]
    const onSubmit = (event) => {
        event.preventDefault()
        handleSubmit()
    }
    const handleLogout = () => {
        dispatch(removeAuthInfo())
        localStorage.clear();
    };

    const onStoreImage = (data) => {
        setSignatureImg(data)
    }
    const handleRemoveSignImage = (index) => {
        setSignatureImg([])
    }
    const onStoreBgImage = (data) => {
        setBackgroundImage(data)
    }
    const handleRemoveBgImage = (index) => {
        setBackgroundImage([])
    }

    const viewSignature = async (url) => {
        let imageUrl = await getImageAsFile(process.env.REACT_APP_FILE_BASE_URL + '/' + encodeURIComponent(url))
        setSignUrl(imageUrl)
    }
    const viewBackGroundImage = async (url) => {
        let imageUrl = await getImageAsFile(process.env.REACT_APP_FILE_BASE_URL + '/' + encodeURIComponent(url))
        setBackGround(imageUrl)
    }

    async function getImageAsFile(imageUrl) {
        try {
            let token = JSON.parse(localStorage.getItem("user"))?.token;
            const response = await fetch(imageUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const buffer = await response.arrayBuffer();
            const type = response.headers.get('Content-Type');
            const blob = new Blob([buffer], { type });
            return URL.createObjectURL(blob)
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    useMemo(() => {
        if (info?.details?.signature) {
            viewSignature(info?.details?.signature)
        }
        if (info?.details?.backgroundImage) {
            viewBackGroundImage(info?.details?.backgroundImage)
        }
    }, [info?.details])

    return (
        <div className='row no-gutters px-2 mr-2 basic-info'>

            <form
                onSubmit={(event) => onSubmit(event)}
                action=""
                id=""
                className=""
            >
                <div className="col-12  d-flex mb-4">
                    <button
                        type="submit"
                        className="btn save-button submit-button-position ml-auto  w-25"
                        onClick={() => { handleLogout() }}
                    >
                        <FontAwesomeIcon
                            className=""
                            size="1x"
                            icon={faSignOut}
                        /> Sign out

                    </button>
                </div>

                <div className="row mt-0 pt-0 no-gutters">
                    <p className={`mt-0 pt-0  border-bottom `} style={{ fontSize: "15px", fontWeight: "600" }}>Details</p>
                    <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            BMDC Registration No:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="BMDC Registration No"
                            value={info?.details?.bmdcRegNo || info?.details?.bmdc_reg_no || ""}
                            onChange={(e) => {
                                setInfoFun("bmdcRegNo", e.target.value, "details");
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage>  */}
                    </div>
                    <div className="col-12 my-0 mx-1 pb-0   d-flex mb-3" >
                        <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                            Education:
                        </label>
                        <textarea
                            autoComplete="off"
                            type="text"
                            placeholder="Education"
                            value={info?.details?.education || ""}
                            onChange={(e) => {
                                setInfoFun("education", e.target.value, "details");
                            }}
                            rows={2}
                        ></textarea>
                        <br />
                    </div>
                    <div className="col-12 my-0 mx-1 pb-0   d-flex mb-3" >
                        <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                            Degree:
                        </label>
                        <textarea
                            autoComplete="off"
                            type="text"
                            placeholder="Degree"
                            value={info?.details?.degree || ""}
                            onChange={(e) => {
                                setInfoFun("degree", e.target.value, "details");
                            }}
                            rows={2}
                        ></textarea>
                        <br />
                    </div>


                    <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Additional Speciality :
                        </label>
                        <textarea
                            autoComplete="off"
                            type="text"
                            placeholder="Additional Speciality "
                            value={info?.details?.additionalSpeciality || ""}
                            onChange={(e) => {
                                setInfoFun("additionalSpeciality", e.target.value, "details");
                            }}
                            rows={2}
                        ></textarea>
                    </div>
                    <div className="col-12 d-flex my-0 py-0 mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Practicing Since:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone Number"
                            value={info?.details?.practicingSince?.slice(0, 10) || ""}
                            onChange={(e) => {
                                setInfoFun("practicingSince", e.target.value, "details");
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>


                    <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Per Day Limit:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="number"
                            id="email"
                            name="email"
                            placeholder="Per day limit"
                            value={info?.details?.perDayLimit || ""}
                            onChange={(e) => {
                                setInfoFun("perDayLimit", e.target.value, "details");
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>

                    <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Sms Balance:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="number"
                            id="email"
                            name="email"
                            disabled
                            placeholder="Balance"
                            value={info?.details?.smsBalance || ""}
                            onChange={(e) => {
                                setInfoFun("smsBalance", e.target.value, "details");
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>

                    <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Fee:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="number"
                            id="email"
                            name="email"
                            placeholder="Fee"
                            value={info?.details?.fee || ""}
                            onChange={(e) => {
                                setInfoFun("fee", e.target.value, "details");
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>
                    <div className="col-12">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Signature
                        </label>
                        <ImageUpload title="Update" multiple={false} accept={".png, .jpg, .jpeg, .svg"} storeImage={onStoreImage} removeImage={handleRemoveSignImage} limit={1}></ImageUpload>
                        {signatureImg.length == 0 && info?.details?.signature && <img className='preview-image-info my-2' src={signUrl}></img>}
                    </div>

                    <div className="col-12">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Prescription background image
                        </label>
                        <ImageUpload title="Update" multiple={false} accept={".png, .jpg, .jpeg, .svg"} storeImage={onStoreBgImage} removeImage={handleRemoveBgImage} limit={1}></ImageUpload>
                        {backgroundImage.length == 0 && info?.details?.backgroundImage && <img className='preview-image-info my-2' src={backGround}></img>}
                    </div>

                </div>
            </form>
        </div>
    )
}

export default EducationalInfo
