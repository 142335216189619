import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import SelectWithLocalSearch from '../common/updated-input/select-with-local-search/select-with-local-search';
import './user-profile.scss'

const UserInfo = ({ selectedOption, setInfoFun, setInfo, info, handleSubmit,isSubmitting }) => {
    const { user } = useSelector((state) => state.auth);
    const maritalStatusOptions = [
        {
            id: 1,
            name: "Married",
            value: "married"
        },
        {
            id: 2,
            name: "Unmarried",
            value: "unmarried"
        },
    ]
    const onSubmit = (event) => {
        event.preventDefault()
        handleSubmit()
    }

    return (
        <div className='row no-gutters px-2 mr-2 basic-info'>

            <form
                onSubmit={(event) => onSubmit(event)}
                action=""
                id=""
                className=""
            >
                <div className="d-flex mb-1">
                    <button
                        type="submit"
                        className="btn save-button submit-button-position  w-25"
                        onClick={() => { }}
                    >
                        Save
                        {isSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>
                <div className="row mt-0 pt-0 no-gutters">
                    <p className={`mt-0 pt-0  border-bottom `} style={{ fontSize: "15px", fontWeight: "600" }}>Basic Information</p>
                    <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                            Name<span className="text-danger">*</span> :
                        </label>
                        <input
                            required
                            className="form-control input py-0 my-0"
                            autoComplete="off"
                            type="text"
                            id="name"
                            name="name"
                            value={info?.name || ""}
                            placeholder="Enter Name"
                            //   defaultValue={info?.name}
                            onChange={(e) => {
                                setInfoFun("name", e.target.value);
                            }}
                        />
                        <br />
                    </div>
                    <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Email :
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="email"
                            id="age"
                            name="age"
                            placeholder="Email"
                            value={info?.email || ""}
                            onChange={(e) => {
                                setInfoFun("email", e.target.value);
                            }}
                        />
                    </div>
                    <div className="col-12  d-flex my-0 py-0 mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Phone Number:
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            pattern="(^(01){1}[3-9]{1}\d{8})$"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone Number"
                            value={info?.phone || ""}
                            onChange={(e) => {
                                setInfoFun("phone", e.target.value);
                            }}
                        />
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>

                    <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Date of birth<span className="text-danger">*</span>:
                        </label>
                        <input
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="dob"
                            name="dob"
                            placeholder="Enter appointmentDate"
                            value={info?.dob?.slice(0, 10) || ""}
                            onChange={(e) => {
                                setInfoFun("dob", e.target.value);
                            }}
                        />
                        <br />
                    </div>

                    {/* <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Blood Group
                        </label>
                        <input
                            className="form-control input"
                            autoComplete="off"
                            type="string"
                            id="age"
                            name="age"
                            placeholder="Blood Group"
                            value={info?.bloodGroup || ""}
                            onChange={(e) => {
                                setInfoFun("bloodGroup", e.target.value);
                            }}
                        />
                    </div> */}
                    <div className="col-12 mb-1" style={{ height: '30px' }}>
                        <div className="mx-1 d-flex">
                            <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '29%' }}>
                                Gender<span className="text-danger">*</span>:
                            </label>
                            <div
                                role="group"
                                aria-labelledby="my-radio-group"
                                style={{ fontSize: "20px" }}
                                className="d-flex px-0 mx-0"
                            >
                                <label className=" d-flex mr-3">
                                    <input
                                        checked={
                                            info?.gender?.toLowerCase() === "male" ? true : false
                                        }
                                        type="radio"
                                        name="gender"
                                        value="Male"
                                        onClick={() => {
                                            setInfoFun("gender", "Male");
                                        }}
                                    />
                                    <span className="ml-2 mt-1 ">Male</span>
                                </label>
                                <label className="m-0 p-0 d-flex">
                                    <input
                                        checked={
                                            info?.gender?.toLowerCase() === "female"
                                                ? true
                                                : false
                                        }
                                        type="radio"
                                        name="gender"
                                        value="Female"
                                        onClick={() => {
                                            setInfoFun("gender", "Female");
                                        }}
                                    />
                                    <span className="ml-2 mt-1">Female</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Address :
                        </label>
                        <textarea
                            autoComplete="off"
                            type="text"
                            placeholder="Address"
                            value={info?.address || ""}
                            onChange={(e) => {
                                setInfoFun("address", e.target.value);
                            }}
                            rows={2}
                        ></textarea>
                    </div>
                    {/* <div className="col-12 mb-0 pb-0 mx-1 d-flex mb-2" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Marital Status:
                        </label>
                        <div className="mt-0 pt-0 w-100 " key={3}>
                            <SelectWithLocalSearch currentValue={info?.maritalStatus} name="maritalStatus" handleFormValues={(status) => {
                                setInfoFun("maritalStatus", status?.value);
                            }} isCreatable={false} options={maritalStatusOptions} placeHolder='Select marital status'></SelectWithLocalSearch>
                        </div>
                        <br />
                    </div> */}
                    {/* {<div className="col-12 mb-0 pb-0 d-flex mx-1" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                            Visit Location<span className="text-danger">*</span>:
                        </label>
                        <div className="mt-0 pt-0 w-100 " key={1}>
                            <SelectWithLocalSearch required={true} currentValue={""} name="location" handleFormValues={(location) => {
                                setInfoFun("officeLocationId", location?._id);
                                setSelectedLocation(location)
                            }} isCreatable={false} options={allLocations} placeHolder='Select location'></SelectWithLocalSearch>
                        </div>
                    </div>
                    } */}

                </div>
            </form>
        </div>
    )
}

export default UserInfo
