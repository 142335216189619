import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import SelectWithLocalSearch from '../common/updated-input/select-with-local-search/select-with-local-search';
import './user-profile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = ({ selectedOption, setInfoFun, setInfo, info, handleSubmit }) => {
    const { user } = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passError, setPassError] = useState({
        notMatch: false,
        length: false,
    })
    const [isShow, setIsShow] = useState(false);
    const maritalStatusOptions = [
        {
            id: 1,
            name: "Married",
            value: "married"
        },
        {
            id: 2,
            name: "Unmarried",
            value: "unmarried"
        },
    ]
    const onSubmit = (event) => {
        event?.preventDefault()
        if (info?.password != info?.conf_password)
            setPassError({
                notMatch: true,
                length: false,
            })
        else if (info?.password < 6)
            setPassError({
                notMatch: false,
                length: true,
            })
        else
            handleSubmit()
    }

    const showPassword = () => {
        var pass = document.getElementById("pass-field");
        if (pass.type === "password") {
            setIsShow(true);
            pass.type = "text";
        } else {
            setIsShow(false);
            pass.type = "password";
        }
    };

    return (
        <div className='px-2 mr-2 basic-info '>

            <form
                onSubmit={(event) => onSubmit(event)}
                action=""
                id=""
                className=""
            >
                <div className='row'>
                    <div className="d-flex w-100 ml-2">
                        <button
                            type="submit"
                            className="btn save-button submit-button-position  w-25"
                            onClick={() => { }}
                        >
                            Reset
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                    </div>
                    <p className={`mt-0 pt-0 ml-2 border-bottom ${selectedOption?.id == 3 ? "base-color" : ""}`} style={{ fontSize: "15px", fontWeight: "600" }}>Reset Password</p>
                    <div className="col-12 d-flex my-0 py-0 mx-1 mb-1 reset-input" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Password:
                        </label>

                        <input
                            autoComplete="off"
                            type="password"
                            className="form-control "
                            onChange={(e) => {
                                setPassError({
                                    notMatch: false,
                                    length: false,
                                })
                                setInfoFun("password", e.target.value);
                            }}
                            id="pass-field"
                            placeholder="Enter your password"
                        />
                        <span className="eye-icon p-0 m-0 mx-2" onClick={showPassword}>
                            <FontAwesomeIcon
                                icon={faEye}
                                fade={isShow}
                                className="text-secondary"
                            />
                        </span>
                        <br />
                        {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                    </div>
                    <div className="col-12 d-flex my-0 py-0 mx-1 mb-1 reset-input" style={{ height: '30px' }}>
                        <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                            Confirm Password:
                        </label>
                        <input
                            autoComplete="off"
                            type="password"
                            className="form-control"
                            onChange={(e) => {
                                // setPassword(e.target.value);
                                // setIsValidate(true);
                                setInfoFun("conf_password", e.target.value);
                                setPassError({
                                    notMatch: false,
                                    length: false,
                                })

                            }}
                            id="pass-field"
                            placeholder="Re-write password"
                        />
                        <span className="eye-icon p-0 mx-2 m-0" onClick={showPassword}>
                            <FontAwesomeIcon
                                icon={faEye}
                                fade={isShow}
                                className="text-secondary "
                            />
                        </span>
                        <br />

                    </div>
                    <di className="text-center  w-100">
                        {passError?.notMatch && <small className={'text-center text-danger'}>Password doesn't match</small>}
                        {passError?.length && <small className={'text-center text-danger'}>Password must be 6 characters login</small>}
                    </di>

                </div>
            </form>
        </div>
    )
}

export default ResetPassword
