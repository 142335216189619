import React, { useEffect, useMemo, useState } from 'react'
import "./sms-portal.scss"
import PatientSection from '../../components/sms-portal/name-section'
import { showErrorToast, showSuccessToast } from '../../utils/toaster-alert'
import { get, post, put, del } from '../../utils/fetchAPI'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthInfo } from '../../store/slices/authSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import CustomModal from '../../components/common/modal/modal'

export const SmsPortal = () => {
    const [phoneNumbers, setPhoneNumbers] = useState([])
    const [smsText, setSmsText] = useState('')
    const [smsCounter, setSmsCounter] = useState(0)
    const [smsType, setSmsType] = useState()
    const [selectAll, setSelectAll] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [templateList, setTemplateList] = useState([])
    const [showEdit, setShowEdit] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [smsTitle, setSmsTitle] = useState("")
    const [description, setDescription] = useState("")
    const [deleteModal, setDeleteModal] = useState(false)



    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch()


    useEffect(() => {
        if (user?.user?._id)
            getTemplate(user?.user?._id)
    }, [])

    const smsCounterFun = (text) => {
        const typeTextCharacters = /^[0-9a-zA-Z ,<.>/?'";:+=\-_)(*&%$#@!`]+$/;
        const isTextType = typeTextCharacters?.test(text);
        const smsType = isTextType ? 'text' : 'unicode';
        const segmentSize = (smsType === 'text') ? 160 : 70;
        const smsCount = Math.ceil(text?.length / segmentSize);
        setSmsType(smsType)
        setSmsCounter(smsCount || 0)
        // return { smsType, smsCount };
    }

    const sendMessage = async () => {
        let numbers = ""
        if (phoneNumbers?.length > 0) numbers = phoneNumbers.join(",")
        let payload = {
            contactNumbers: selectAll ? "all" : numbers,
            textBody: smsText,
            type: smsType,
            smsCount: smsCounter
        }

        setIsSending(true)
        await post(`patient/send-sms`, payload)
            .then((res) => {
                if (res.data?.status == 200) {
                    let userDetails = { ...user?.user?.details }
                    let userData = { ...user?.user }
                    if (userDetails?.smsBalance) userDetails.smsBalance = user?.user?.details?.smsBalance - 1
                    if (userData?.details) userData.details = userDetails
                    dispatch(setAuthInfo({ token: user?.token, user: userData }))
                    showSuccessToast("Sms sent successfully")
                    setPhoneNumbers([])
                    setSmsCounter(0)
                    setIsSending(false)
                    setSmsType('')
                    setSelectAll(false)
                }
                else if (res.data?.message) showErrorToast(res.data?.message)
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => { setIsSending(false) });
    }

    const createNewSmsTemplate = async (doctorId) => {
        await post(`doctor/sms`, {
            doctor: doctorId,
            title: smsTitle,
            description: description
        })
            .then((res) => {
                if (res.status == 200) {
                    getTemplate(doctorId)
                    setShowAdd(false)
                    setDescription("")
                    setSmsTitle("")
                    showSuccessToast("Template created successfully")
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => { setIsSending(false) });
    }

    const updateSmsTemplate = async (doctorId, templateId) => {
        await put(`doctor/sms/${templateId}`, {
            title: smsTitle,
            description: description
        })
            .then((res) => {
                if (res.status == 200) {
                    getTemplate(doctorId)
                    setShowEdit(false)
                    setDescription("")
                    setSmsTitle("")
                    showSuccessToast("Template updated successfully")
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => { setIsSending(false) });
    }

    const templateDelete = async (doctorId, templateId) => {
        await del(`doctor/sms/${templateId}`,)
            .then((res) => {
                if (res.status == 200) {
                    getTemplate(doctorId)
                    setDeleteModal(false)
                    showSuccessToast("Template deleted successfully")
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => { setIsSending(false) });
    }

    const getTemplate = async (doctorId) => {
        await get(`doctor/sms/${doctorId}`)
            .then((res) => {
                if (res.status == 200) {
                    setTemplateList(res?.data || [])
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => { setIsSending(false) });
    }

    useMemo(() => {
        if (smsText?.length == 0) setSmsCounter(0)
        else if ((2295 - (+smsText?.length || 0)) <= 0) {
            showErrorToast("Character limit reached")
        }
        else if (smsText) {
            smsCounterFun(smsText)
        }
    }, [smsText])


    return (
        <section className='sms-portal'>
            <div className='name-section'>
                <PatientSection selectAll={selectAll} setSelectAll={setSelectAll} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
            </div>
            <div className='form-section'>
                <div className="row ">
                    <div className="col-6 col-md-12  mb-0 pb-0 ">
                        <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                            Numbers
                        </label>
                        <div className="d-flex">
                            {" "}
                            <textarea
                                className="form-control  w-100"
                                autoComplete="off"
                                type="text"
                                rows={7}
                                disabled
                                value={selectAll ? "All patient" : phoneNumbers}
                                placeholder="Phone Numbers"
                                onChange={(e) => {

                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-12  mb-0 pb-0 ">
                        <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                            Message
                        </label>
                        <div className="d-flex">
                            {" "}
                            <textarea
                                style={{ color: "black" }}
                                className="form-control  w-100"
                                autoComplete="off"
                                type="text"
                                rows={6}
                                value={smsText}
                                placeholder="Sms"
                                onChange={(e) => {
                                    setSmsText(e.target.value)
                                }}
                            />
                        </div>
                        <div className=''>
                            <div className='mx-3 d-flex justify-content-between'>
                                {(user?.user?.details?.smsBalance - smsCounter) >= 0 ? <p> <small><strong>You have <span style={{ color: "#12AB81", fontSize: "15px" }}>{user?.user?.details?.smsBalance - smsCounter}</span> SMS left</strong></small></p> : <p> <small className='text-red'><strong>You don't have sufficient SMS</strong></small></p>}

                                <p> <small className=''><strong><span style={{ color: "#12AB81" }}>{smsText?.length}</span> Characters | <span style={{ color: "#12AB81" }}>{2295 - (smsText?.length || 0)}</span> Characters Left | <span style={{ color: "#12AB81" }}>{smsCounter}</span> SMS</strong></small></p>
                                {/* <small className='ml-4'>Sms count: <strong style={{ color: "#12AB81" }}>{smsCounter}</strong></small> */}
                            </div>
                        </div>
                        {(user?.user?.details?.smsBalance - smsCounter) >= 0 && <div onClick={() => sendMessage()} className='text-center'>
                            <button disabled={isSending} style={{ background: "#15aa80", color: "white" }} className='btn my-0 py-0 mt-4'>Sent message</button>
                        </div>}
                        {(user?.user?.details?.smsBalance - smsCounter) < 0 && <div className='text-center'>
                            <button disabled style={{ background: "#15aa80", color: "white" }} className='btn my-0 py-0 mt-4'>Sent message</button>
                        </div>}
                        {/* <div className='text-center'>
                           
                        </div> */}
                    </div>
                </div>
                <div className='content mt-5'>
                    <label>SMS Content</label>
                    <p><strong>* 160 Characters are counted as 1 SMS in case of English language & 70 in other language.</strong></p>
                    <p><strong>* One simple text message containing extended GSM character set (~^{ }[]|) is 70 characters long. Check your SMS count before pushing SMS.</strong></p>
                </div>
            </div>
            <div className='template-section'>
                <div className='d-flex mb-4'>
                    <p className='px-1 py-0 my-0' style={{ fontWeight: "600" }}>Template list</p>
                    <button onClick={() => {
                        setDescription('')
                        setSmsTitle('')
                        setShowAdd(true)
                    }
                    } className='btn btn-success py-0 my-0 mr-3'>Add</button>
                </div>
                <div className=''>
                    {templateList?.length > 0 && templateList?.map((data) => {
                        return (<div className='my-0 py-0 mr-4 mb-3 mx-3' style={{ border: ".1px solid black", borderRadius: "6px" }}>
                            <div className='d-flex my-0 py-0 justify-content-between' style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px", background: "#15aa80", color: "white", fontSize: "14px", fontWeight: "600" }}>
                                <div className='my-0 py-0 d-flex'>
                                    <p className='p-0 m-0 px-2' style={{ color: "white", fontSize: "14px", fontWeight: "600" }}>{data?.title}</p>
                                    <p onClick={() => {
                                        setDescription(data?.description)
                                        setSmsTitle(data?.title)
                                        setSelectedTemplate(data)
                                        setShowEdit(true)
                                    }} className='my-0 '> <FontAwesomeIcon style={{ fontSize: "10px", cursor: "pointer" }} onClick={() => {
                                    }} icon={faEdit}></FontAwesomeIcon></p>
                                    <p onClick={() => {
                                        setSelectedTemplate(data)
                                        setDeleteModal(true)
                                    }} className='my-0 ml-3'>  <FontAwesomeIcon style={{ fontSize: "10px", color: "red", cursor: "pointer" }} onClick={() => {

                                    }} icon={faTrash}></FontAwesomeIcon></p>

                                </div>
                                <button onClick={() => setSmsText(data?.description)} className='my-0 py-0 pr-3 btn btn-success' style={{ cursor: "pointer" }}>Use This</button>
                            </div>
                            <p className='p-0 m-0 px-2 py-2' style={{ color: "black", fontSize: "14px" }}>{data?.description}</p>
                        </div>)
                    })}
                </div>
            </div>

            <CustomModal
                size="md"
                show={showAdd}
                isHeaderHide={true}
            // setShow={setShow}
            >
                <div className='mt-0  create-template px-2 py-2'>
                    <label className='mt-0 pt-0 d-block'>template title</label>
                    <input value={smsTitle} onChange={(e) => {
                        setSmsTitle(e.target.value)
                    }} placeholder='Enter template name'></input>
                    <label className='mt-0 pt-0 d-block'>Description</label>
                    <textarea className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }} placeholder='Enter template name'></textarea>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            createNewSmsTemplate(user?.user?._id)
                        }} className='btn py-0 mr-1'>Add
                        </button>
                        <button onClick={() => setShowAdd(false)} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>


            <CustomModal
                size="md"
                show={showEdit}
                isHeaderHide={true}
            // setShow={setShow}
            >
                <div className='mt-0  create-template px-2 py-2'>
                    <label className='mt-0 pt-0 d-block'>template title</label>
                    <input value={smsTitle} onChange={(e) => {
                        setSmsTitle(e.target.value)
                    }} placeholder='Enter template name'></input>
                    <label className='mt-0 pt-0 d-block'>Description</label>
                    <textarea className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }} placeholder='Enter template name'></textarea>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            updateSmsTemplate(user?.user?._id, selectedTemplate?._id)
                        }} className='btn py-0 mr-1'>Update
                        </button>
                        <button onClick={() => setShowEdit(false)} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>

            <CustomModal
                size="sm"
                show={deleteModal}
                isHeaderHide={true}
            // setShow={setShow}
            >
                <div className='mt-0   px-2 py-2'>
                    <h4 className='mt-0 pt-0 d-block text-center'>Are you sure?</h4>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            templateDelete(user?.user?._id, selectedTemplate?._id)
                        }} className='btn btn-danger py-0 mr-1'>Delete
                        </button>
                        <button onClick={() => setDeleteModal(false)} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>
        </section>
    )
}
