import React, { useMemo, useState, useEffect } from "react";
import "./appointed-indivual-patient.scss";
import VerticalTab from "../../../components/common/tabs/vertical/vertical-tab";
import VitalSigns from "../../../components/prescription/vital-signs/vital-signs";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../components/waiting-room/header/header";
import OtherHealthProblem from "../../../components/prescription/other-health-problem/other-health-problem";
import SurgicalHistories from "../../../components/prescription/sergical-histories/surgical-histories";
import FamilyHistory from "../../../components/prescription/family-history/family-history";
import SocialHistory from "../../../components/prescription/social-history/social-history";
import VisitHistory from "../../../components/prescription/visit-history/visit-history";
import PatientHistory from "../../../components/prescription/patient-history/patient-history";
import Complaint from "../../../components/prescription/complaint/complaint";
import LabReportFile from "../../../components/prescription/lab-reports-file/lab-report-file";
import AllergyHistory from "../../../components/prescription/allergy-history/allergy-history";
import PastMedicalHistory from "../../../components/prescription/past-medical-history/past-medical-history";
import MainPrescription from "../../../components/prescription/main-prescription/main-prescription";
import { optionsForDoctor, optionsForStaff } from "../../../utils/static-data/data";
import { useSelector } from "react-redux";
import { get } from "../../../utils/fetchAPI";
import PatientDemography from "../../../components/prescription/patient-demogrphy/patient-demography";
import Vision from "../../../components/prescription/vision/vision";
import PhysicalExam from "../../../components/prescription/physical-exam/physical-exam";
import Ot from "../../../components/prescription/ot/ot";
import Diagnosis from "../../../components/prescription/diagonosis/diagnosis";
import CarePlan from "../../../components/prescription/care-plan/carePlan";

const AppointedIndividualPatient = () => {
    const params = useParams();
    const [visitId] = useState(params?.visitId);
    const [selectedOption, setSelectedOption] = useState({ id: 2, name: "Chief Complaint" });
    const [visitDetails, setVisitDetails] = useState({})
    const [patientDetails, setPatientDetails] = useState({});
    const location = useLocation()
    const [selectedSubOption, setSelectedSubOption] = useState({
        parentId: "",
        subId: "",
    });
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (visitId) {
            getVisitDetails(visitId)
        }
    }, [visitId])

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        if (params?.get('id'))
            setSelectedOption({ id: +params?.get('id'), name: "params" })
    }, [])


    const getVisitDetails = (visitId) => {
        get(`/visit/${visitId}`)
            .then((res) => {
                if (res.status === 200) {
                    setVisitDetails(res?.data)
                    getPatientDetails(res?.data.patient)
                }
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    function getPatientDetails(patientId) {
        get(`/patient/${patientId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientDetails(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const refresh = () => {
        if (visitId) {
            getVisitDetails(visitId)
        }
    }

    return (
        <div>
            <Header setSelectedOption={setSelectedOption} visitDetails={visitDetails} backUrl={`/appointment${location?.search}`} updateState={setVisitDetails}></Header>
            <section
                className="individual-patient-section mt-0 pt-0"
                style={{ marginLeft: "71px" }}
            >
                <div className="tab-option-section">
                    <VerticalTab
                        backUrl={`/appointment${location?.search}`}
                        setSelectedSubOption={setSelectedSubOption}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        selectedSubOption={selectedSubOption}
                        options={user?.user?.type == "staff" ? optionsForStaff : optionsForDoctor}
                    ></VerticalTab>
                </div>
                <div className="tab-main-section">
                    {selectedOption?.id === 1 && <PatientDemography visitDetails={visitDetails} patientDetails={patientDetails} updateState={setPatientDetails}></PatientDemography>}
                    {selectedOption?.id === 2 && <Complaint visitDetails={visitDetails} updateState={setVisitDetails}></Complaint>}
                    {selectedOption?.id === 16 && <CarePlan visitDetails={visitDetails} updateState={setVisitDetails}></CarePlan>}
                    {selectedOption?.id === 15 && <Diagnosis visitDetails={visitDetails} updateState={setVisitDetails}></Diagnosis>}
                    {selectedOption?.id === 3 && <VitalSigns visitDetails={visitDetails} setVisitDetails={setVisitDetails}></VitalSigns>}
                    {selectedOption?.id === 4 &&
                        <MainPrescription updateState={setVisitDetails} visitDetails={visitDetails} appointmentId={visitDetails.appointment} visitId={visitId}
                            patientId={visitDetails.patient}></MainPrescription>}
                    {selectedOption?.id === 5 && <Vision visitDetails={visitDetails} updateState={setVisitDetails}></Vision>}
                    {selectedOption?.id === 6 && <LabReportFile visitDetails={visitDetails}></LabReportFile>}
                    {selectedOption?.id === 14 && <Ot patientDetails={patientDetails} updateState={setPatientDetails} visitDetails={visitDetails}></Ot>}
                    {selectedOption?.id === 7 && <VisitHistory setSelectedOption={setSelectedOption} updateState={setVisitDetails} visitDetails={visitDetails}></VisitHistory>}
                    {selectedOption?.id === 8 && <PastMedicalHistory visitDetails={visitDetails} patientDetails={patientDetails} updateState={setPatientDetails}></PastMedicalHistory>}
                    {selectedOption?.id === 9 && <FamilyHistory updateState={setPatientDetails} patientDetails={patientDetails} visitDetails={visitDetails}></FamilyHistory>}
                    {selectedOption?.id === 10 && <SurgicalHistories updateState={setPatientDetails} patientDetails={patientDetails} visitDetails={visitDetails}></SurgicalHistories>}
                    {selectedOption?.id === 11 && <AllergyHistory updateState={setPatientDetails} patientDetails={patientDetails} visitDetails={visitDetails}></AllergyHistory>}
                    {selectedOption?.id === 12 && <SocialHistory updateState={setPatientDetails} patientDetails={patientDetails} visitDetails={visitDetails}></SocialHistory>}
                    {selectedOption?.id === 13 && <PhysicalExam updateState={setVisitDetails} patientDetails={patientDetails} visitDetails={visitDetails}></PhysicalExam>}

                    {/* {selectedOption?.id === 13 && <OtherHealthProblem visitDetails={visitDetails} visitId={visitId} ></OtherHealthProblem>} */}
                    {/* {selectedOption?.id === 3 && selectedOption?.id === 4 && (
            <div>
              <p className="text-center">{selectedOption?.name}</p>
              <p className="text-center">
                {
                  optionsForWaiting[selectedSubOption?.parentId - 1]?.sub[
                    selectedSubOption?.subId - 1
                  ].name
                }
              </p>
            </div>
          )} */}
                </div>
                <div className="individual-info-section mr-1" style={{ height: "95vh" }}>
                    <PatientHistory patientId={visitDetails?.patient}></PatientHistory>
                </div>
            </section>
        </div>
    );
};

export default AppointedIndividualPatient;