import { combineReducers } from "redux";
import authReducer from "./slices/authSlice";
import patientReducer from "./slices/patientSlice";
import appointmentReducer from "./slices/appointmentSlice";
import locationReducer from "./slices/locationSlice";
import visitReducer from "./slices/visitSlice";
import prescriptionReducer from "./slices/prescriptionSlice";
import templateSlice from "./slices/templateSlice";
import staffSlice from "./slices/staffSlice";
import dashboardSlice from './slices/dashboardSlice'

export default combineReducers({
  auth: authReducer,
  patient: patientReducer,
  appointment: appointmentReducer,
  location: locationReducer,
  visit: visitReducer,
  prescription: prescriptionReducer,
  template: templateSlice,
  staff: staffSlice,
  dashboard: dashboardSlice
});
