import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./main-prescription.scss"
import Medicine from './medicine/medicine'
import LabTest from './labtest/labtest'
import Advice from './advice/advice'
import { useDispatch } from 'react-redux'
import { put } from '../../../utils/fetchAPI'

const MainPrescription = ({ patientId, visitId, appointmentId, visitDetails, refresh, updateState }) => {
    const dispatch = useDispatch();
    const [nextVisit, setNextVisit] = useState("")
    const timeoutIdRef = useRef(null)


    useEffect(() => {
        if (visitDetails?.nextVisitInstruction) {
            setNextVisit(visitDetails?.nextVisitInstruction)
        }
    })

    const instructionSetTime = (event) => {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { giveNextVisitInstruction(event.target.value) }, 700);
    };

    const giveNextVisitInstruction = (value) => {
        put(`/visit/${visitDetails?._id}`, { "nextVisitInstruction": value })
            .then((res) => {
                if (res.status === 200) {
                    //updateState(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    }

    return (
        <div className='prescription-full-body row no-gutters'>
            <div className='medicine-section col-12'>
                <Medicine updateState={updateState} visitDetails={visitDetails} patientId={patientId} visitId={visitId}></Medicine>
            </div>
            <div className='labTest-advice-section-1 col-12 '>
                <div className='row  no-gutters'>
                    <div className='lab-test col-6' style={{ height: "33vh" }}>
                        <p>Investigation</p>
                        <LabTest updateState={updateState} visitDetails={visitDetails}></LabTest>
                    </div>
                    <div className='col-6' style={{ borderBottom: ".5px solid rgba(0, 0, 0, 0.463)", height: "33vh" }}>
                        <p>Advice</p>
                        <Advice updateState={updateState} visitDetails={visitDetails}></Advice>
                    </div>
                    {/* <div className='next-visit px-2 w-100 col-12 mb-1'>
                        <p className='px-0 mx-0'>Next visit Instruction</p>
                        <input onChange={(event) => {
                            updateState({ ...visitDetails, nextVisitInstruction: [event.target.value] });
                            setNextVisit(event.target.value)
                            instructionSetTime(event)
                        }} value={nextVisit || '__ days later (if needed)'} type='text'></input>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default MainPrescription
