import React from 'react'

export const PhysicalExamPrint = ({ patientPhysicalExams, isHeader }) => {
  console.log("patientPhysicalExams", patientPhysicalExams)
  return (
    <div className='physical-exam-print'>
      {isHeader && <div style={{ borderTop: '1px solid black' }}>
      </div>}
      <div className='exams'>
        {patientPhysicalExams?.length > 0 && patientPhysicalExams?.map((exam, key) => {
          return (
            <div className='exam'>
              <div className='d-flex'>
                <p className='py-0 my-0'>Physical exam name:</p>
                <p className='py-0 my-0' style={{fontWeight:"600",paddingLeft:"5px"}}>{exam?.displayName}</p>
              </div>
              <div className='d-flex'>
                <p className='py-0 my-0'>Result:</p>
                <p className='py-0 my-0' style={{fontWeight:"600",paddingLeft:"5px"}}>{exam?.type}</p>
              </div>
              <div className='d-flex'>
                <p className='py-0 my-0'>Description</p>
                <p className='py-0 my-0' style={{fontWeight:"600",paddingLeft:"5px"}}>{exam?.desc}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
