import { post, put } from "../../utils/fetchAPI";

export const addDoctorMedicine = async (medicineId, payload) => {
    const res = await post(`doctor/manage-medicine-details/${medicineId}`, payload);
    return res;
};
export const createDoctorMedicine = async (doctorId, payload) => {
    const res = await post(`doctor/create-new-medicine/${doctorId}`, payload);
    return res;
};

export const updateDoctorMedicine = async (doctorMedicineId, payload) => {
    const res = await put(`doctor/update-new-medicine/${doctorMedicineId}`, payload);
    return res;
};

