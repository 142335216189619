import React, { useEffect, useState } from 'react'
import "./ot-note.scss"
import ImageUpload from '../../../common/updated-input/image-upload/ImageUpload';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { patientFileUpload } from '../../../../service/api/patient-api';
import { useDispatch } from 'react-redux';
import { getAllFiles } from '../../../../store/slices/prescriptionSlice';
import html2pdf from 'html2pdf.js';

const OtNote = ({ setAddOtNoteModal, patientId, setPreview, handlePreviewInfo, visitDetails, selectedOt, patientDetails }) => {
    const currentDate = new Date().toJSON();
    const [info, setInfo] = useState({ name: visitDetails?.patientName || patientDetails?.name, dob: visitDetails?.patientDob?.slice(0, 10) || patientDetails?.dob?.slice(0, 10), gender: visitDetails?.patientGender || patientDetails?.gender })
    const [formData, setFormData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)


    useEffect(() => {
        if (selectedOt) {
            setInfo({ ...selectedOt, ...info })
        }
    }, [])

    const onStoreImage = (data) => {

        let newFormData = [...formData, ...data];
        setFormData(newFormData)
    }
    const handleRemoveExpenseImage = (index) => {
        let newFormData = [...formData];
        newFormData?.splice(index, 1)
        setFormData([...newFormData])
    }
    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (selectedOt) {
            handlePreviewInfo(info, true)
        } else handlePreviewInfo(info)

    }

    const generateAndUploadPdf = async () => {
        const element = document.createElement('div');
        element.innerHTML = `<p>Asfe</p>`;
        const options = {
            margin: 10,
            filename: 'output.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        const pdfBlob = await html2pdf(element, options).outputPdf();

        const pdfFile = new File([pdfBlob], 'output.pdf', {
            lastModified: new Date().getTime(),
        });

        // console.log("pdfBlob", URL.createObjectURL(pdfFile))
    };


    return (
        <div onSubmit={handleFormSubmit} className='ot-note' style={{ overflowY: "scroll" }}>
            <p style={{ backgroundColor: "#0FAC81", color: "white", fontSize: "14px", fontWeight: "600" }} className='py-0 my-0 px-1'>Ot note</p>
            <form
                action=""
                id=""
                className="custom-form input-section p-2"
                encType="multipart/form-data"
            >
                <div className="row">

                    {/* <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Type<span className="text-danger">*</span>
                        </label>
                        <select
                            className='d-block'
                            onChange={(e) => {
                                setInfo({ ...info, type: e.target.value })
                            }}
                        >
                            <option value={"Lab"}>Lab Report / Imaging </option>
                            <option value={"Prescription"}>Prescription </option>
                        </select>
                    </div> */}
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Name<span className="text-danger">*</span>
                        </label>
                        <input
                            disabled
                            className="form-control input"
                            autoComplete="off"
                            type="text"
                            id="name"
                            name="name"
                            value={info?.name || ""}
                            placeholder="Enter Name"
                            onChange={(e) => {
                                setInfo({ ...info, name: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Date of birth<span className="text-danger"></span>
                        </label>
                        <input
                            disabled
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="age"
                            name="age"
                            value={info?.dob?.slice(0, 10) || ""}
                            placeholder="Enter Age"
                            onChange={(e) => {
                                setInfo({ ...info, dob: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Gender<span className="text-danger"></span>
                        </label>
                        <input
                            disabled
                            className="form-control input"
                            autoComplete="off"
                            type="text"
                            id="name"
                            name="name"
                            value={info?.gender || ""}
                            placeholder="Enter Name"
                            onChange={(e) => {
                                setInfo({ ...info, gender: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Address<span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="name"
                            name="address"
                            value={info?.address || ""}
                            placeholder="Enter address"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, address: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Date of admission<span className="text-danger">*</span>
                        </label>
                        <input
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="dateOfAdmission"
                            name="dateOfAdmission"
                            value={info?.dateOfAdmission?.slice(0, 10) || ""}
                            placeholder="Enter date of admission"
                            onChange={(e) => {
                                setInfo({ ...info, dateOfAdmission: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Date of operation<span className="text-danger">*</span>
                        </label>
                        <input
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="dateOfOperation"
                            name="dateOfOperation"
                            value={info?.dateOfOperation?.slice(0, 10) || ""}
                            placeholder="Enter date of operation"
                            onChange={(e) => {
                                setInfo({ ...info, dateOfOperation: e.target.value })
                            }}
                        />
                    </div>

                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Date of discharge<span className="text-danger">*</span>
                        </label>
                        <input
                            required
                            className="form-control input"
                            autoComplete="off"
                            type="date"
                            id="dateOfDischarge"
                            name="dateOfDischarge"
                            value={info?.dateOfDischarge?.slice(0, 10) || ""}
                            placeholder="Enter date of discharge"
                            onChange={(e) => {
                                setInfo({ ...info, dateOfDischarge: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Diagnosis<span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="diagnosis"
                            name="diagnosis"
                            value={info?.diagnosis || ""}
                            placeholder="Enter diagnosis"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, diagnosis: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Operation notes<span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="operationsNotes"
                            name="operationsNotes"
                            value={info?.operationsNotes || ""}
                            placeholder="Enter operation notes"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, operationsNotes: e.target.value })
                            }}
                        />
                    </div>

                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Result at discharge<span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="resultAtDischarge"
                            name="resultAtDischarge"
                            value={info?.resultAtDischarge || ""}
                            placeholder="Enter result at discharge"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, resultAtDischarge: e.target.value })
                            }}
                        />
                    </div>

                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            IOL That Implanted<span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="IOLThatImplanted"
                            name="IOLThatImplanted"
                            value={info?.IOLThatImplanted || ""}
                            placeholder="Enter result at discharge"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, IOLThatImplanted: e.target.value })
                            }}
                        />
                    </div>


                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Surgeon<span className="text-danger">*</span>
                        </label>
                        <textarea
                            required
                            className="form-control input"
                            autoComplete="off"
                            id="surgeon"
                            name="surgeon"
                            value={info?.surgeon || ""}
                            placeholder="Enter surgeon"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, surgeon: e.target.value })
                            }}
                        />
                    </div>

                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Assistant Surgeon <span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="surgeon"
                            name="surgeon"
                            value={info?.assistantSurgeon || ""}
                            placeholder="Enter surgeon"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, assistantSurgeon: e.target.value })
                            }}
                        />
                    </div>
                    {/* <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Assistant Surgeon <span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="assistantSurgeon"
                            name="assistantSurgeon"
                            value={info?.assistantSurgeon || ""}
                            placeholder="Enter assistant surgeon"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, assistantSurgeon: e.target.value })
                            }}
                        />
                    </div> */}
                    <div className="col-6 mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Assistants <span className="text-danger"></span>
                        </label>
                        <textarea
                            className="form-control input"
                            autoComplete="off"
                            id="assistants"
                            name="assistants"
                            value={info?.assistants || ""}
                            placeholder="Enter assistants"
                            rows={2}
                            onChange={(e) => {
                                setInfo({ ...info, assistants: e.target.value })
                            }}
                        />
                    </div>

                    <div className="col-12  d-flex text-center mt-3 justify-content-center">
                        <button
                            type="submit"
                            className="btn submit-button  w-25"
                            onClick={() => { }}
                        >
                            {selectedOt ? "Update" : "Create"}
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                        <p onClick={() => setAddOtNoteModal(false)} className='cancel-button'>Cancel</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default OtNote
