import React from 'react'
import './prescription-print.scss'
const PrescriptionFooter = ({ drDetails, isFooter }) => {
    return (
        <div className='prescription-footer  mb-2 '>
            {((isFooter == true || isFooter == false) ? isFooter : true) && <div style={{ background: drDetails?.config?.footerBackgroundColor || "white", borderTop: "1px solid black" }} className='pt-2'>
                <div dangerouslySetInnerHTML={{ __html: drDetails?.config?.prescriptionFooter }} />
            </div>}
        </div>
    )
}

export default PrescriptionFooter
