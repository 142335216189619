import React, { useEffect, useMemo, useState } from 'react'
import "./customizeHeader.scss"

export const CustomizeHeader = ({ prescriptionSettingSetInfoFun, prescriptionSettingShowInfo }) => {
    const [data, setData] = useState([{
        name: "Name",
        value: "name",
        width: 30,
        position_left: 20,
        visible: true
    },
    {
        name: "Patient Id",
        value: "patientId",
        width: 30,
        position_left: 20,
        visible: true
    },
    {
        name: "Gender",
        value: "gender",
        width: 20,
        position_left: 20,
        visible: true
    },
    {
        name: "Age",
        value: "age",
        width: 20,
        position_left: 20,
        visible: true
    },
    {
        name: "DOB",
        value: "dob",
        width: 20,
        position_left: 20,
        visible: true
    },
    {
        name: "Date",
        value: "appointmentDate",
        width: 20,
        position_left: 20,
        visible: true
    }
    ])


    useEffect(() => {
        if (prescriptionSettingShowInfo?.customizeHeader?.length > 0)
            setData(prescriptionSettingShowInfo?.customizeHeader)
    }, [])

    const changeHorizontalPosition = (left, value, index) => {
        let oldData = data
        if (left) {
            setData(oldData.map((item, i) =>
                i === index ? { ...item, position_left: item?.position_left - value } : item
            ));
        }
        else {
            setData(oldData.map((item, i) =>
                i === index ? { ...item, position_left: item?.position_left + value } : item
            ));
        }
    }

    const swapPosition = (left, value, index) => {
        let oldData = data
        if (left) {
            const newArray = [...oldData];
            const temp = newArray[index];
            newArray[index] = newArray[index - value];
            newArray[index - value] = temp;
            setData(newArray)
        }
        else {
            const newArray = [...oldData];
            const temp = newArray[index];
            newArray[index] = newArray[index + value];
            newArray[index + value] = temp;
            setData(newArray)
        }
    }

    const widthChange = (value, index) => {
        let oldData = data
        setData(oldData.map((item, i) =>
            i === index ? { ...item, width: value } : item
        ));
    }

    const visibleShowHide = (value, index) => {
        let oldData = data
        if (value) {
            setData(oldData.map((item, i) =>
                i === index ? { ...item, visible: value } : item
            ));
        } else {
            setData(oldData.map((item, i) =>
                i === index ? { ...item, visible: value } : item
            ));
        }
    }


    const reset = () => {
        setData([
            {
                name: "Name",
                value: "name",
                width: 30,
                position_left: 20,
                visible: true
            },
            {
                name: "Patient Id",
                value: "patientId",
                width: 30,
                position_left: 20,
                visible: true
            },
            {
                name: "Gender",
                value: "gender",
                width: 20,
                position_left: 20,
                visible: true
            },
            {
                name: "Age",
                value: "age",
                width: 20,
                position_left: 20,
                visible: true
            },
            {
                name: "DOB",
                value: "dob",
                width: 20,
                position_left: 20,
                visible: true
            },
            {
                name: "Date",
                value: "appointmentDate",
                width: 20,
                position_left: 20,
                visible: true
            }
        ])
    }

    useMemo(() => {

        prescriptionSettingSetInfoFun("customizeHeader", data || [])
    }, [data])

    return (
        <section>
            <div className=''>
                <p style={{ cursor: "pointer" }} onClick={reset}>Reset</p>
                <div className='d-flex'>
                    {
                        data?.map((d, i) => {
                            return (<div key={i} style={{ width: "20%", }} className='border'>
                                <div className='px-2'>
                                    <input onChange={(e) => { visibleShowHide(e.target.checked, i) }} id={d.value} checked={d?.visible} type='checkBox'></input>
                                    <label className='px-1' htmlFor={d.value}>{d.name}</label>
                                </div>
                                <input className='mx-2' type='number' onChange={(e) => widthChange(e.target.value, i)} value={d?.width}></input>
                                <div className='d-flex justify-content-between px-2'>
                                    {i != 0 && <p onClick={() => { swapPosition(true, 1, i) }} style={{ cursor: "pointer" }}>Left swap</p>}
                                    {i != data?.length - 1 && <p onClick={() => { swapPosition(false, 1, i) }} style={{ cursor: "pointer" }}>Right Swap</p>}
                                </div>
                                <div className='d-flex justify-content-between px-2'>
                                    <p onClick={() => { changeHorizontalPosition(true, 1, i) }} style={{ cursor: "pointer" }}>Left</p>
                                    <p onClick={() => { changeHorizontalPosition(false, 1, i) }} style={{ cursor: "pointer" }}>Right</p>
                                </div>
                                {/* <div style={{ position: "relative" }} className='border py-3'>
                                    <p style={{ position: "absolute", left: d.position_left + "%", top: "0%" }} className='my-0'>{d.name}</p>
                                </div> */}
                            </div>)
                        })
                    }
                </div>
                <h3>Preview</h3>
                <div className='d-flex'>
                    {
                        data?.map((d, i) => {
                            if (d?.visible)
                                return (
                                    <div key={i} style={{ width: d.width + "%", position: "relative" }} className='border py-3'>
                                        <p style={{ position: "absolute", left: d.position_left + "%", top: "0%" }} className='my-0'>{d.name}</p>
                                    </div>
                                )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
