import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import './dashboard-component.scss'
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);



const DonutChart = ({ barChartData }) => {
    const data = {
        labels: barChartData?.status,
        datasets: [
            {
                labels: barChartData?.status,
                data: barChartData?.values,
                label: "Percent",
                backgroundColor: [
                    'rgba(33, 150, 83, 1)',
                    "red",
                    'rgba(237, 240, 72)',
                    'rgba(85, 193, 254, 1)',
                ],
                borderColor: [
                    'rgba(33, 150, 83, 1)',
                    "red",
                    'rgba(237, 240, 72)',
                    'rgba(229, 231, 235, 1)'
                ],
                borderWidth: 0,
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
        },
        cutout: '75%',
        // width: 200, 
        // height: 300,
    };
    return (
        <div className='order-status-chart'>
            <Doughnut data={data} options={chartOptions} />
        </div>
    );
};

export default DonutChart;
