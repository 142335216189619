import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWithParams } from "../../utils/fetchAPI";

const initialState = {
  allLocations: [],
  allOrganizations: [],
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noLocationFound: false,
  locationOptions:[],
  organizationOptions:[]
};
export const getLocations= createAsyncThunk(
  "location/getLocations",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`location/list`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getOrganizations= createAsyncThunk(
  "location/getOrganizations",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`organization/list`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    resetLocations: (state, action) => {
      state.allLocations = [];
      state.isLoading = false;
      state.isOperationLoading = false;
      state.error = null;
      state.noLocationFound= false;
      state.locationOptions=[]
    },
  },
  extraReducers: {
    [getLocations.pending]: (state, action) => {
      // if (action?.meta?.arg?.current_page===1) state.isLoading = true;
      state.isLoading = true;
      state.error = null;
      state.noLocationFound=false;
    },
    [getLocations.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allLocations = action.payload;
      if(action.payload?.length>0){
        let locationArray=[]
        action.payload?.map((location)=>{
          locationArray.push({label:location?.name,value:location?._id})
        })
        state.locationOptions=locationArray
      }
      if (action.payload === 0) {
        state.noLocationFound = true;
      }
      state.error = null;
    },
    [getLocations.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    },

    [getOrganizations.pending]: (state, action) => {
      // if (action?.meta?.arg?.current_page===1) state.isLoading = true;
      state.isLoading = true;
      state.error = null;
      state.noLocationFound=false;
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allOrganizations = action.payload;
      // console.log(state.allOrganizations)
      if(action.payload?.organization?.length>0){
        let locationArray=[]
        action.payload?.organization?.map((location)=>{
          locationArray.push({label:location?.name,value:location?._id})
        })
        state.organizationOptions=locationArray
      }
      // if (action.payload === 0) {
      //   state.noLocationFound = true;
      // }
      state.error = null;
    },
    [getOrganizations.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    },
  },
});

export default locationSlice.reducer;
export const { resetLocations } = locationSlice.actions;