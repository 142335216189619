import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllFiles } from '../../../store/slices/prescriptionSlice';
import "./lab-report-file.scss"
import CustomModal from '../../common/modal/modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import FileUpload from './file-upload/file-upload';
import { del } from '../../../utils/fetchAPI';

const LabReportFile = ({ visitDetails = {}, patientDetails = {} }) => {
    let { isLoading, allFiles } = useSelector(
        (state) => state.prescription
    );
    const preview_ref = useRef()
    const [url, setUrl] = useState('')
    const [show, setShow] = useState(false)
    const [preview, setPreview] = useState(false)
    const [uploadModalShow, setUploadModalShow] = useState(false)
    const [addOtNoteModal, setAddOtNoteModal] = useState(false)
    const [previewInfo, setPreviewInfo] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllFiles(visitDetails.patient || patientDetails?._id))
    }, [])

    const viewFile = async (url) => {
        let imageUrl = await getImageAsFile(process.env.REACT_APP_FILE_BASE_URL + '/' + encodeURIComponent(url))
        setUrl(imageUrl)
        setShow(true)
    }

    async function getImageAsFile(imageUrl) {
        try {
            let token = JSON.parse(localStorage.getItem("user"))?.token;
            const response = await fetch(imageUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const buffer = await response.arrayBuffer();
            // const type = response.headers.get('Content-Type');
            const type = 'application/pdf';
            const blob = new Blob([buffer], { type });
            return URL.createObjectURL(blob)
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }


    const removeFile = (id) => {
        del(`patient/delete-file/${id}`)
            .then((res) => {
                if (res?.status === 200) {
                    dispatch(getAllFiles(visitDetails.patient || patientDetails?._id))
                }
            }).finally(() => { })
    }

    // /patient/remove-lab-test/:reportId

    return (
        <div className='full-page'>
            <div>
                <p style={{ borderRadius: "2px" }} onClick={() => setUploadModalShow(true)} className='upload-btn '>Upload file / Document <FontAwesomeIcon className='ml-1' size='xs' icon={faUpload} /></p>

            </div>
            <div className='file-section'>
                {allFiles?.map((files, index) => {
                    return (
                        <div key={index} className='individual-dates'>
                            <p className='title mb-0'> {files?._id}</p>
                            <div className='d-flex'>
                                {files?.files?.map((file, i) => {
                                    return (
                                        <div className='d-flex' key={i}>
                                            {file?.fileUrls?.map((url, index) => {
                                                return (
                                                    <div className='border file pt-3' style={{ position: "relative" }}>
                                                        <FontAwesomeIcon onClick={() => removeFile(file?._id)} className='file-remove' icon={faTrash}></FontAwesomeIcon>
                                                        <div className='border mt-2' onClick={() => { viewFile(url) }}>
                                                            <p className='description'>{file?.description}</p>
                                                            <p className='type'>{file?.type}</p>
                                                        </div>
                                                        {/* <p>url</p> */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    )
                })}
            </div>
            <CustomModal
                size="xl"
                show={show}
                isHeaderHide={true}
                setShow={setShow}
                autoClose={true}
            >
                <div className='preview'>
                    <iframe frameborder="0" className='frame d-block mx-auto' src={url}></iframe>
                </div>

            </CustomModal>
            <CustomModal
                size="md"
                show={uploadModalShow}
                isHeaderHide={true}
                setShow={setUploadModalShow}
                autoClose={true}
            >
                <FileUpload setUploadModalShow={setUploadModalShow} patientId={visitDetails.patient || patientDetails?._id}></FileUpload>
            </CustomModal>

        </div>
    )
}

export default LabReportFile
