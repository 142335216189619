import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getWithParams } from "../../utils/fetchAPI";

const initialState = {
  allPatients: [],
  pagination: {
    current_page: 1,
  },
  patientDetails:{},
  isLoading: false,
  isOperationLoading: false,
  error: null,
  noPatientFound: false,
};
export const getPatients = createAsyncThunk(
  "patient/getPatients",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getWithParams(`/patient/search`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientDetails = createAsyncThunk(
  "patient/getPatientDetails",
  async (patientId, { rejectWithValue }) => {
    try {
      const response = await get(`/patient/${patientId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    resetAllPatients: (state, action) => {
      state.allPatients = [];
      state.pagination = {
        current_page: 1,
      };
      state.isLoading = false;
      state.isOperationLoading = false;
      state.error = null;
      state.noPatientFound = false;
    },
  },
  extraReducers: {
    [getPatients.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPatients.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allPatients = action.payload;
      if (action.payload.length === 0) {
        state.noPatientFound = true;
      }
      state.pagination = action.payload.rest;
      state.error = null;
    },
    [getPatients.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },


    [getPatientDetails.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.patientDetails={}
    },
    [getPatientDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.patientDetails = action.payload;
      state.error = null;
    },
    [getPatientDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    },   
  },
});

export default patientSlice.reducer;
export const { resetAllPatients } = patientSlice.actions;
