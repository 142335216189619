import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./ot.scss"
import CustomModal from '../../common/modal/modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPrint, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { del, get, getWithParams, post, put } from '../../../utils/fetchAPI';
import OtNote from './ot-note/ot-note';
import OtNotePreview from './ot-note/ot-note-preview';
import ReactToPrint from 'react-to-print';
import html2pdf from 'html2pdf.js';
import { patientAddSubDocument, patientRemoveSubDocument, patientUpdateSubDocument } from '../../../service/api/patient-api';
import OtNotePrint from './ot-note/ot-note-print';
import PrescriptionHeader from '../prescription-print/prescriptionHeader';
const Ot = ({ visitDetails, patientDetails, updateState }) => {
    let { isLoading, allFiles } = useSelector(
        (state) => state.prescription
    );
    const ref = useRef([]);
    const printRef = useRef()
    const [preview, setPreview] = useState(false)
    const [addOtNoteModal, setAddOtNoteModal] = useState(false)
    const [patientOtNotes, setPatientOtNotes] = useState()
    const [selectedOt, setSelectedOt] = useState("")
    const [doctorDetails, setDoctorDetails] = useState([])
    const { user, selectedRole } = useSelector((state) => state.auth);
    const [isHeader, setIsHeader] = useState(true)

    useEffect(() => {
        // getOtList()
        if (patientDetails) {
            setPatientOtNotes(patientDetails?.otHistories)
        }
    }, [])

    useEffect(() => {

        if (user?.user?.type?.toLowerCase() === 'doctor') {
            // setDoctorDetails(user?.user)
            // setIsHeader(user?.user.details?.config?.prescriptionHeaderInPrint)
        }
        else if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
    }, [])

    useMemo(() => {
        if (selectedRole){
            setDoctorDetails(selectedRole)
            setIsHeader(selectedRole.config?.prescriptionHeaderInPrint)
        }
    }, [selectedRole])

    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // const getOtList = async () => {
    //     await get(`/operation-theater/list/${visitDetails?.patient}`)
    //         .then((res) => {
    //             if (res.status == 200) {
    //                 setPatientOtNotes(res?.data)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    //         .finally()
    // }


    const handlePreviewInfo = async (info, edit = false) => {
        let addPayload = { ...info }

        const body = {
            payload: {
                "otHistories.$.dateOfAdmission": addPayload?.dateOfAdmission,
                "otHistories.$.dateOfOperation": addPayload?.dateOfOperation,
                "otHistories.$.dateOfDischarge": addPayload?.dateOfDischarge,
                "otHistories.$.diagnosis": addPayload?.diagnosis,
                "otHistories.$.operationsNotes": addPayload?.operationsNotes,
                "otHistories.$.resultAtDischarge": addPayload?.resultAtDischarge,
                "otHistories.$.IOLThatImplanted": addPayload?.IOLThatImplanted,
                "otHistories.$.surgeon": addPayload?.surgeon,
                "otHistories.$.assistantSurgeon": addPayload?.assistantSurgeon,
                "otHistories.$.assistants": addPayload?.assistants,
            },
            filterKey: "otHistories._id",
            filterKeyValue: addPayload?._id
        }

        if (edit) {

            await patientUpdateSubDocument(body, (patientDetails?._id || visitDetails?.patient))
                .then((res) => {
                    if (res.status == 200) {
                        // getOtList()
                        setPatientOtNotes(res?.data?.otHistories)
                        updateState(res?.data)
                        setAddOtNoteModal(false)
                        setSelectedOt("")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally()
        } else {
            addPayload.patientId = visitDetails?.patient
            await patientAddSubDocument("otHistories", addPayload, (patientDetails?._id || visitDetails?.patient))
                .then((res) => {
                    if (res.status == 200) {
                        // getOtList()
                        setPatientOtNotes(res?.data?.otHistories)
                        updateState(res?.data)
                        setAddOtNoteModal(false)
                        setSelectedOt("")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally()
        }

    }

    const deleteOtNote = async (id) => {
        await patientRemoveSubDocument("otHistories", { _id: id }, (patientDetails?._id || visitDetails?.patient))
            .then((res) => {
                if (res.status == 200) {
                    setPatientOtNotes(res?.data?.otHistories)
                    updateState(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally()
    }

    const editOtNote = (note) => {
        setAddOtNoteModal(true)
        setSelectedOt(note)
    }



    const generateAndUploadPdf = async (innerHtmlData, note) => {

        const options = {
            margin: 6,
            filename: `${note?.name}-${note?.createdAt?.slice(0, 10)}`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        await html2pdf(innerHtmlData, options);
        // const pdfFile = new File([pdfBlob], `${note?.name}-${note?.createdAt?.slice(0, 10)}`, {
        //     lastModified: new Date().getTime(),
        // });
        // console.log("pdfBlob", URL.createObjectURL(pdfFile))
    };


    // /patient/remove-lab-test/:reportId

    return (
        <div className='full-page'>
            <div>
                <p style={{ borderRadius: "2px" }} onClick={() => {
                    setSelectedOt("")
                    setAddOtNoteModal(true)
                }} className='upload-btn mx-1'>Add ot note <FontAwesomeIcon className='ml-1' size='xs' icon={faPlus} /></p>
            </div>
            <div className='ot-section'>
                {patientOtNotes?.map((note, index) => {
                    return (
                        <div key={index} className='border mx-2 single-ot'>
                            <div className='d-flex header justify-content-between'>
                                <p>Create date: {note?.createdAt?.slice(0, 10)}</p>
                                <div className='mx-1 d-flex'>
                                    <div className='mx-auto w-50 d-inline'>
                                        <ReactToPrint
                                            trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{
                                                cursor: "pointer",
                                                background: "#13AC81",
                                                color: "white"
                                            }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                                            content={() => ref.current[index]}
                                        />
                                    </div>
                                    {/* <p onClick={() => {
                                        generateAndUploadPdf(ref.current[index], note)
                                    }
                                    } className='edit'>Download</p> */}
                                    <p onClick={() => editOtNote(note)} className='edit mx-4'>Edit</p>
                                    <p onClick={() => deleteOtNote(note?._id)} className='edit border-danger text-danger'>Delete</p>
                                </div>
                            </div>
                            <OtNotePreview patientDetails={patientDetails} visitDetails={visitDetails} previewInfo={note} setPreview={setPreview}></OtNotePreview>
                            <div className='d-none'>
                                <div ref={(el) => (ref.current[index] = el)} className='full-prescription'>
                                    <PrescriptionHeader
                                        isHeader={isHeader}
                                        info={{}} drDetails={doctorDetails || []}></PrescriptionHeader>
                                    <OtNotePrint isHeader={isHeader} patientDetails={patientDetails} visitDetails={visitDetails} className={'ot-preview-print'} previewInfo={note} setPreview={setPreview}></OtNotePrint>
                                    {/* <h1>Hello</h1> */}
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>


            <CustomModal
                size="md"
                show={addOtNoteModal}
                isHeaderHide={true}
                setShow={setAddOtNoteModal}
                autoClose={false}
            >
                <div style={{ minHeight: "70vh" }}>
                    <OtNote patientDetails={patientDetails} selectedOt={selectedOt} setAddOtNoteModal={setAddOtNoteModal} visitDetails={visitDetails} handlePreviewInfo={handlePreviewInfo} setPreview={setPreview} patientId={visitDetails.patient}></OtNote>
                </div>
            </CustomModal>
            {/* <div className='d-none'>
                <span ref={preview_ref}>
                    <OtNotePreview previewInfo={previewInfo} setPreview={setPreview}></OtNotePreview>
                </span>
            </div> */}
        </div >
    )
}

export default Ot
