import React, { useEffect, useMemo, useState } from 'react'
import "./template-component.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateList } from '../../store/slices/templateSlice';
import CustomModal from "../../components/common/modal/modal";
import { createTemplate, deleteTemplate } from '../../service/api/template';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { put } from '../../utils/fetchAPI';
import { useMatch } from 'react-router-dom';

const NameSection = ({ doctorId, setSelectedTemplate, selectedTemplate }) => {
    const [show, setShow] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [templateName, setTemplateName] = useState("")
    const [template,setTemplate]=useState({})
    let { isLoading, templateList } = useSelector(
        (state) => state.template
    );
    const dispatch = useDispatch()

    useEffect(() => {
        let payload = {
            doctorId: doctorId,
            search: ""
        }
        dispatch(getTemplateList(payload))
    }, [])

    const searchName = (query) => {
        let payload = {
            doctorId: doctorId,
            search: query
        }
        dispatch(getTemplateList(payload))
    }
    const createNewTemplate = () => {
        createTemplate({ doctor: doctorId, name: templateName })
            .then((res) => {
                if (res.status === 200) {
                    let payload = {
                        doctorId: doctorId,
                        search: ""
                    }
                    setSelectedTemplate(res?.data)
                    dispatch(getTemplateList(payload))
                    setShow(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const templateNameUpdate =async (template,templateName) => {
        await put(`doctor/template/${template?._id}`, {name:templateName})
        .then((res) => {
            if (res?.status == 200) {
                let payload = {
                    doctorId: doctorId,
                    search: ""
                }
                dispatch(getTemplateList(payload))
                setShowEdit(false)
            }
        })
            .catch((error) => {
                console.log(error);
            });

        
    }

    useMemo(()=>{
         if(template)setTemplateName(template?.name)
    },[showEdit])

    const deleteTemplateFun = async (template) => {
        await deleteTemplate(template?._id, { doctorId: template?.doctor })
            .then((res) => {
                if (res.status === 200) {
                    let payload = {
                        doctorId: doctorId,
                        search: ""
                    }
                    dispatch(getTemplateList(payload))
                    setSelectedTemplate([])
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <div className='template-name'>
            <button onClick={() => {
                setShow(true)
            }} className='btn w-100 py-0' style={{ borderRadius: "4px" }}>Add new
            </button>
            <input onChange={(e) => {
                searchName(e.target.value)
            }} placeholder='search template'></input>
            <label>Templates names</label>
            <div className='names'>
                {templateList?.templates?.map((template, index) => {
                    return (
                        <div
                            className={`individual-name d-flex justify-content-between mt-0 pt-0 ${selectedTemplate?._id == template?._id ? "active-template" : ""}`}>
                            <p onClick={() => setSelectedTemplate(template)} key={index}
                                className='p-0 m-1  w-75 name'>{template?.name}</p>
                            <p className='p-0 m-1 pr-2 update'>
                                <FontAwesomeIcon style={{ fontSize: "10px" }} onClick={() => {
                                    setShowEdit(true)
                                    setTemplate(template)
                                }} icon={faEdit}></FontAwesomeIcon>
                            </p>
                            <p className='p-0 m-1 pr-2 delete'>
                                <FontAwesomeIcon style={{ fontSize: "10px" }} onClick={() => {
                                    deleteTemplateFun(template)
                                }} icon={faTrash}></FontAwesomeIcon>
                            </p>
                        </div>
                    )
                })}
                {!isLoading && templateList?.templates?.length == 0 && <p className='individual-name'>Not available</p>}
                {isLoading && <p className='individual-name'>loading...</p>}
            </div>
            <CustomModal
                size="sm"
                show={show}
                isHeaderHide={true}
            // setShow={setShow}
            >
                <div className='mt-0  create-template px-2 py-2'>
                    <label className='mt-0 pt-0 d-block'>Create new template</label>
                    <input onChange={(e) => {
                        setTemplateName(e.target.value)
                    }} placeholder='Enter template name'></input>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            createNewTemplate()
                        }} className='btn py-0 mr-1'>Create
                        </button>
                        <button onClick={() => {
                            setTemplateName("")
                            setShow(false)}} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>

            <CustomModal
                size="sm"
                show={showEdit}
                isHeaderHide={true}
            // setShow={setShow}
            >
                <div className='mt-0  create-template px-2 py-2'>
                    <label className='mt-0 pt-0 d-block'>Update template name</label>
                    <input value={templateName} onChange={(e) => {
                        setTemplateName(e.target.value)
                    }} placeholder='Enter template name'></input>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            templateNameUpdate(template,templateName)
                        }} className='btn py-0 mr-1'>Update
                        </button>
                        <button onClick={() => setShowEdit(false)} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default NameSection
