import React, { useState } from "react";
import "./create-appointment.scss";
import AppointmentCreateForm from "./appointment-create-form";
import PatientSearch from "../common/patient-search/patient-serach-appontment-create";

const CreateAppointment = ({ refresh, setShow }) => {
  const [isNewUser, setIsNewUser] = useState(true);
  const [singlePatientInfo, setSinglePatientInfo] = useState({});
  return (
    <div className="create-appointment-section">
      <div className="appointment-search-section">
        <PatientSearch
          setIsNewUser={setIsNewUser}
          setSinglePatientInfo={setSinglePatientInfo}
          singlePatientInfo={singlePatientInfo}
          height={75}
          isNewUser={isNewUser}
          buttonName="New Appointment"
        ></PatientSearch>
      </div>
      <div className="appointment-form-section">
        <AppointmentCreateForm
          setShow={setShow}
          refresh={refresh}
          isNewUser={isNewUser}
          setIsNewUser={setIsNewUser}
          selectedPatientInfo={singlePatientInfo}
          setSinglePatientInfo={setSinglePatientInfo}
        ></AppointmentCreateForm>
      </div>
    </div>
  );
};

export default CreateAppointment;
