import './App.css';
import AppRoutes from './routes/app-routes';
import "./assets/scss/common.scss"
import "./assets/scss/partials/typography.scss"

function App() {
 
  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
